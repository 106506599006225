import React, { useEffect } from 'react'
import { getProducts } from '../../../../redux'
import { connect } from 'react-redux'
import TouchCarousel, { clamp } from 'react-touch-carousel'
import touchWithMouseHOC from './touchWithMouseHOC'
import NonPassiveTouchTarget from './NonPassiveTouchTarget'
import cx from 'classnames'

const Slider = ({billingTagList, fetchProducts, onDataUpdate}) => {
    const enableLoop = false
    const enableAutoplay = false
    const cardSize = 100
    const cardPadCount = enableLoop ? 0 : 0
    const carouselWidth = clamp(window.innerWidth, 0, 960)


    function log (text) {
        console.log(text)
        //document.getElementById('console').innerText = text
    }

    function tagClick(e, tagId) {
        if(e.detail === 2) {
            onDataUpdate({tagId: tagId});
            fetchProducts('page=1&tagId='+tagId)
        }
    }


    function CarouselContainer (props) {
        const { cursor, carouselState: { active, dragging }, ...rest } = props
        
        let current = -Math.round(cursor) % billingTagList.length
        while (current < 0) {
          current += billingTagList.length
        }
        // Put current card at center
        let translateX = (cursor - cardPadCount) * cardSize + (carouselWidth - cardSize) / 2
        translateX = translateX - 430

        return (
            <NonPassiveTouchTarget
                className={cx(
                'carousel-container',
                {
                    'is-active': active,
                    'is-dragging': dragging
                }
                )}
            >
                <NonPassiveTouchTarget
                className='carousel-track'
                style={{ transform: `translate3d(${translateX}px, 0, 0)` }}
                {...rest}
                />
            </NonPassiveTouchTarget>
        )
    }
    const Container = touchWithMouseHOC(CarouselContainer)
    
    function renderCard (index, modIndex) {
        const item = billingTagList[modIndex]
        return (
            <div
                key={index}
                className='carousel-card me-3'
                onClick={(e) => tagClick(e, item.id)}
            >
                <div
                className='carousel-card-inner'
                style={{ backgroundColor: item.background }}
                >
                <div className='carousel-title'>{item.title}</div>
                <div className='carousel-text'>{item.text}</div>
                </div>
            </div>
        )
    }


    return (
        <>
            <div className="pos-slider">
                <TouchCarousel
                    component={Container}
                    cardSize={cardSize}
                    cardCount={billingTagList.length}
                    cardPadCount={cardPadCount}
                    loop={enableLoop}
                    autoplay={enableAutoplay ? 2e3 : false}
                    renderCard={renderCard}
                    onRest={index => log(`rest at index ${index}`)}
                    onDragStart={() => log('dragStart')}
                    onDragEnd={() => log('dragEnd')}
                    onDragCancel={() => log('dragCancel')}
                    />
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
      billingTagList: state.tag.billingTagList
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchProducts: (query) => {
            dispatch( getProducts(query, 1) )
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Slider)