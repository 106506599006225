import "react-datepicker/dist/react-datepicker.css";
import React, { useState } from 'react'
import DatePicker from "react-datepicker";
import { Modal, Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { closeReturnPopup, updateReturnData, updateReturnDiscountData , updateSaleChangesData, createReturnBill } from "../../../../redux";

const Return = ({
    refund,
    triggerCloseReturnPopup,
    updateReturn,
    updateReturnDiscount,
    updateSaleChanges,
    createReturn
}) => {

    const[details, setDetails] = useState({
        flash: 'none',
        message: null,
        returnDate: new Date()
    })

    const closeModal = () => {
        triggerCloseReturnPopup()
    }

    const returnChangePrice = async (e) => {
        const key = e.target.getAttribute('data-sortid');
        const price = e.target.value ? parseFloat(e.target.value) : 0.01
        const currentReturndata = refund;

        let products = currentReturndata.orderItems.map((item) => {
            if (parseInt(key) === parseInt(item.id)) {
                return {
                    ...item,
                    saleUnitPrice: price,
                    salePrice: item.saleQty * price
                }
            }
            return item
        })
        let calculatedData = calculateAmount(products, currentReturndata.discount);

        await updateReturn(products, calculatedData)
        updateChanges(products);
    }

    const returnChangeQty = async (e) => {
        const key = e.target.getAttribute('data-sortid');
        const qty = e.target.value ? parseInt(e.target.value) : 1
        const currentReturndata = refund;

        let products = currentReturndata.orderItems.map((item) => {
            let finalQty = (item.originalSaleQty < qty) ? item.originalSaleQty : qty;
            if (parseInt(key) === parseInt(item.id)) {
                return {
                    ...item,
                    saleQty: finalQty,
                    salePrice: finalQty * item.saleUnitPrice
                }
            }
            return item
        })
        let calculatedData = calculateAmount(products, currentReturndata.discount);

        await updateReturn(products, calculatedData)
        updateChanges(products);
    }

    const changeDiscount = async (e) => {
        const currentReturndata = refund;
        const discount = e.target.value

        let calculatedData = calculateAmount(currentReturndata.orderItems, discount);

        await updateReturnDiscount(discount, calculatedData)
        updateChanges(currentReturndata.orderItems);
    }

    const calculateAmount = (products, discount) => {
        let subTotal = (products.length > 0) ? products.reduce( (previousTabable, item) => {
            return previousTabable*1 + (item.saleUnitPrice * item.saleQty)
        }, 0).toFixed(2) : 0;

        let total = subTotal - discount;

        return {
            subTotal: subTotal, 
            total: total
        }
    }

    const updateChanges = async (products) => {

        let updatedRefundItems  = [];
        let updatedPriceItems   = [];

        if(products && products.length > 0) {
            products.forEach(item => {
                if(item.originalSaleQty*1 !== item.saleQty*1) {
                    updatedRefundItems.push({
                        id:                     item.id,
                        originalSalePrice:      item.originalSalePrice,
                        originalSaleQty:        item.originalSaleQty,
                        originalSaleUnitPrice:  item.originalSaleUnitPrice,
                        saleUnitPrice:          item.saleUnitPrice,
                        name:                   item.product.name,
                        productId:              item.productId,
                        updatedQty:             (item.originalSaleQty - item.saleQty),
                        stockId:                item.stockId
                    })
                }

                if((item.originalSaleUnitPrice*1 !== item.saleUnitPrice*1) && item.saleQty > 0) {
                    updatedPriceItems.push({
                        id:                     item.id,
                        originalSalePrice:      item.originalSalePrice,
                        originalSaleQty:        item.originalSaleQty,
                        originalSaleUnitPrice:  item.originalSaleUnitPrice,
                        saleUnitPrice:          item.saleUnitPrice,
                        name:                   item.product.name,
                        productId:              item.productId,
                        updatedQty:             item.saleQty,
                        stockId:                item.stockId
                    })
                }
            });
        }


        updateSaleChanges(updatedRefundItems, updatedPriceItems)
    }


    const createRefund = async (e) => {
        e.preventDefault()
        setDetails({
            ...details,
            flash: 'none',
            message: null
        })
        createReturn(refund.billNumber, refund).then(data => {
            setDetails({
                ...details,
                flash: 'success',
                message: data.message
            })

            window.location.reload(false);
        }).catch(error => {
            let errorMessage = '';
            if(error && error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
                errorMessage += error.response.data.errors+' '
            }
            if(error && error.response && error.response.data && error.response.data.message && error.response.data.message !== '') {
                errorMessage += error.response.data.message
            }
            setDetails({
                ...details,
                flash: 'error',
                message: errorMessage
            })
        })
    }

    return (
        <>
        <Modal dialogClassName="modal-fullscreen return-modal" aria-labelledby="fullScreenModalLabel" show={refund.isOpen} onHide={closeModal}>
            <form className="form-horizontal" onSubmit={createRefund}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Update Bill Items
                    </Modal.Title>
                    <div className="mx-4">
                        {
                            (details.flash === 'error') ? <div className="alert alert-primary alert-dismissible bg-transparent text-primary fade show mb-0" role="alert">
                                {details.message}
                            </div> : null
                        }
                                                {
                            (details.flash === 'success') ? <div className="alert alert-success alert-dismissible bg-transparent text-success fade show mb-0" role="alert">
                                {details.message}
                            </div> : null
                        }
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {
                        (refund.loading === true) ? <div className="cashier-loader">
                            <div className="spinner-grow avatar-lg text-secondary m-2"></div>
                        </div> : ''
                    }
                    <div className="row">
                        <div className="col-8">
                            <div className="refund-items-list">
                                <table className="table mb-0">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Unit.P</th>
                                            <th>Qty</th>
                                            <th>Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (refund && refund.orderItems && refund.orderItems.length > 0) ? refund.orderItems.map((item, key) => {
                                            return <tr key={key}>
                                                    <td>#{key+1}</td>
                                                    <td>{(item.product) ? item.product.name : ''}</td>
                                                    <td>
                                                        <input 
                                                            className="form-control product-price" 
                                                            onChange={returnChangePrice} 
                                                            data-sortid={item.id}
                                                            value={item.saleUnitPrice} 
                                                            type="any" 
                                                            min="0" />
                                                    </td>
                                                    <td>
                                                        <input 
                                                            className="form-control product-qty" 
                                                            onChange={returnChangeQty} 
                                                            data-sortid={item.id}
                                                            value={item.saleQty} 
                                                            type="any" 
                                                            min="0" 
                                                            max={item.originalSaleQty}/>
                                                    </td>
                                                    <td>{item.salePrice}</td>
                                                </tr>
                                            }) : null
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan="2"></td>
                                            <td colSpan="2">
                                                <div className="float-end">
                                                Sub Total
                                                </div>
                                            </td>
                                            <td>{refund.subTotal}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2"></td>
                                            <td colSpan="2">
                                                <div className="float-end">
                                                Discount
                                                </div>
                                            </td>
                                            <td>
                                                <input onChange={changeDiscount} className='form-control biller-input' type="any" min="0" name="discount" value={refund.discount}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2"></td>
                                            <td colSpan="2">
                                                <div className="float-end">
                                                    Total
                                                </div>
                                            </td>
                                            <td>{refund.total}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                        <div className="col-4">
                            {
                                (refund && refund.updatedRefundItems && refund.updatedRefundItems.length > 0) ? <>
                                    <h3>Return Items</h3>
                                    <table className="table mb-0">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Refund Qty</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                refund.updatedRefundItems.map((item, key) => {
                                                    return <>
                                                        <tr key={key}>
                                                            <td>#{key+1}</td>
                                                            <td>{item.name}</td>
                                                            <td>{item.updatedQty}</td>
                                                        </tr>
                                                    </>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </> : null
                            }

                            {
                                (refund && refund.updatedPriceItems && refund.updatedPriceItems.length > 0) ? <>
                                    <h3 className="mt-4">Price Updated Items</h3>
                                    <table className="table mb-0">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Updated Qty</th>
                                                <th>Updated Unit Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                refund.updatedPriceItems.map((item, key) => {
                                                    return <>
                                                        <tr key={key}>
                                                            <td>#{key+1}</td>
                                                            <td>{item.name}</td>
                                                            <td>{item.updatedQty}</td>
                                                            <td>{item.saleUnitPrice}</td>
                                                        </tr>
                                                    </>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </> : null
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button type="submit" className="btn btn-success" disabled={refund.returnLoader ? true : false}>
                    Update Bill
                </Button>
                <Button variant="secondary" onClick={closeModal}>
                    Close
                </Button>
                </Modal.Footer>
            </form>
        </Modal>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        refund: state.refund
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        triggerCloseReturnPopup: () => {
            dispatch(closeReturnPopup())
        },
        updateReturn: (data, calculatedData) => {
            dispatch(updateReturnData(data, calculatedData))
        },
        updateReturnDiscount: (data, calculatedData) => {
            dispatch(updateReturnDiscountData(data, calculatedData))
        },
        updateSaleChanges: (refundItems,  priceItems) => {
            dispatch(updateSaleChangesData(refundItems, priceItems))
        },
        createReturn: (billNumber, refund) => {
            return dispatch(createReturnBill(billNumber, refund))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Return)