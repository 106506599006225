import { SOLD_ITEM_LIST} from "./soldItemTypes"

const initialState = {
    list: null,
    error: "" 
}

const soldItemReducer = (state = initialState, action) => {
    switch(action.type) {

        case SOLD_ITEM_LIST:
            return {
                ...state,
                list: action.payload,
            }
            
        default: return state;
    }
}

export default soldItemReducer;
