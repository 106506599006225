import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import {
    getCustomerStatement
} from '../../redux'
import moment from 'moment';
import DatePicker from "react-datepicker";
import { startOfYear, endOfDay } from 'date-fns';

function CustomerStatementComponent({
    authData,
    transaction,
    fetchStatements
    }) {

    const navigate = useNavigate() 
    const [mount, setMount] = useState(false)
    const params = useParams();

    const[filter, setFilter] = useState({
        statementFromDate: startOfYear(new Date()),
        statementToDate: endOfDay(new Date()),
    })

    useEffect(() => {
        if(!mount) {
            setMount(true);

            fetchStatements(params.customerId, {filter: filter})
            if(!authData || !authData.auth || !authData.auth.id) {
                const goToLoginPage = () => navigate('/admin/login');
                goToLoginPage();
            }
        }

    }, [mount, navigate, authData, fetchStatements, filter, params]);

    const doFilter = () => {
        fetchStatements(params.customerId, {filter: filter})
    }

    return (
        <>
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mt-4">
                                <div className="card-header d-print-none">
                                    <ul className="nav nav-tabs align-items-end card-header-tabs w-100">
                                        <li className="nav-item">
                                            <a className="nav-link" href="/admin/customers"><i className="fas fa-th-list mx-2"></i>Customers</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link active" href="!#"><i className="fas fa-th-list mx-2"></i>Customer Statement</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="mb-2 col-md-3">
                                            <label className="form-label">Customer</label>
                                            <div className="fw-bold">
                                            {
                                                (transaction && transaction.statementList && transaction.statementList.customer) ? transaction.statementList.customer.name : '-'
                                            }
                                            </div>
                                        </div>
                                        <div className="mb-2 col-md-3">
                                            <label className="form-label">From</label>
                                            <DatePicker 
                                                className="form-control" 
                                                selected={filter.statementFromDate} 
                                                onChange={(date) => setFilter({...filter, statementFromDate: startOfYear(date)})} 
                                            />
                                        </div>
                                        <div className="mb-2 col-md-3">
                                            <label className="form-label">To</label>
                                            <DatePicker 
                                                className="form-control" 
                                                selected={filter.statementToDate} 
                                                onChange={(date) => setFilter({...filter, statementToDate: endOfDay(date)})} 
                                            />
                                        </div>
                                        <div className="mb-2 d-print-none">
                                            <label className="form-label"></label>
                                            <button onClick={doFilter} type="button" className="btn btn-info width-md waves-effect waves-light">Filter</button>
                                        </div>
                                    </div>
                                    <table className="table dataTable no-footer">
                                        <thead>
                                            <tr role="row">
                                                <th>#</th>
                                                <th>Date</th>
                                                <th>Total</th> 
                                                <th>Transaction</th>
                                                <th>Type</th> 
                                                <th>Description</th> 
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            ( transaction && transaction.statementList && transaction.statementList.rows && transaction.statementList.rows.length > 0) ? transaction.statementList.rows.map((data, id) => {
                                                return <tr key={id}>
                                                    <td>#{id+1}</td>
                                                    <td>{(data.statementDate) ? moment(data.statementDate).format('MMMM Do YYYY') : '-'}</td>
                                                    <td>{data.total}</td>
                                                    <td>
                                                    {
                                                        (data.transactionType === 'SALE') ? 
                                                        <><span className="badge bg-info">Sale</span></>
                                                        : (data.transactionType === 'CREDIT') ?
                                                        <><span className="badge bg-success">Cash In</span></>
                                                        : <><span className="badge bg-primary">Cash Out</span></>
                                                    }
                                                    </td>
                                                    <td>{data.type}</td>
                                                    <td>{data.description}</td>
                                                </tr>
                                            }) : <tr><td className="text-center" colSpan={5}>No Record</td></tr>
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        authData: state.account,
        transaction: state.transaction
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchStatements: (customerId, query) => {
            dispatch( getCustomerStatement(customerId, query) )
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerStatementComponent)