import { UPDATE_CURRENT_TAG, TAG_LIST, BILLING_TAGS } from "./tagTypes";

const initialState = {
    currentTag: {
        name: "",
        billing_display: false,
        sort_order: ""
    },
    list: null,
    billingTagList: [],
    error: "" 
}

const tagReducer = (state = initialState, action) => {
    switch(action.type) {

        case UPDATE_CURRENT_TAG:
            return {
                ...state,
                currentTag: action.payload,
            }

        case TAG_LIST:
            return {
                ...state,
                list: action.payload,
            }
            
        case BILLING_TAGS:
            return {
                ...state,
                billingTagList: action.payload,
            }


        default: return state;

    }
}

export default tagReducer;
