import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect } from 'react'
import LoginComponent from './LoginComponent'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    updateCurrentTag,
    createTag
} from '../../redux'

function CreateTagComponent({
        authData,
        currentTag,
        updateCurrentTagData,
        newTag
    }) {
    const navigate = useNavigate() 

    useEffect(() => {
        if(!authData || !authData.auth || !authData.auth.id) {
            const goToLoginPage = () => navigate('/admin/login');
            goToLoginPage();
        }
    }, [navigate, authData]);

    if(!authData || !authData.auth || !authData.auth.id) {
        return <LoginComponent></LoginComponent>
    }

    const changeBillingDisplay = (e) => {
        let updatedTag = {
            ...currentTag,
            billing_display: !currentTag.billing_display
        };
        updateCurrentTagData(updatedTag)
    }

    const onFormUpdate = (e) => {
        e.preventDefault()
        let updatedTag = {};
        switch(e.target.name){
            case 'name': {
                updatedTag = {
                    ...currentTag,
                    name: e.target.value
                };
                updateCurrentTagData(updatedTag)
                break;
            }
            case 'sort_order': {
                updatedTag = {
                    ...currentTag,
                    sort_order: e.target.value
                };
                updateCurrentTagData(updatedTag)
                break;
            }
            default: break;
        }
    };

    const submitHandler = (e) => {
        e.preventDefault();
        newTag(currentTag).then( data => {
            const goToBillDetails = () => navigate('/admin/tags');
            goToBillDetails();
        })
    }

    return (
        <>
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mt-4">
                                <div className="card-header">
                                    <ul className="nav nav-tabs align-items-end card-header-tabs w-100">
                                        <li className="nav-item">
                                            <a className="nav-link active" href="/admin/tags/create"><i className="fas fa-plus-circle mx-2"></i>New Tag</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="/admin/tags"><i className="fas fa-th-list mx-2"></i>Tags</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-body">
                                  
                                    <form className="form-horizontal" onSubmit={submitHandler}>
                                        <div className="row">
                                            <div className="col-12 col-lg-8">
                                                <div className="mb-2 row">
                                                    <label className="col-md-2 col-form-label">Name</label>
                                                    <div className="col-md-10">
                                                        <input type="text" id="name" name="name" className="form-control" onChange={onFormUpdate} value={currentTag.name}/>
                                                    </div>
                                                </div>

                                                <div className="mb-2 row">
                                                    <label className="col-md-2 col-form-label">Billing Display</label>
                                                    <div className="col-md-10">
                                                        <div className="onoffswitch">
                                                            <input type="checkbox" name="billing_display" className="onoffswitch-checkbox" id="myonoffswitch" value={currentTag.billing_display}/>
                                                            <label className="onoffswitch-label" htmlFor="myonoffswitch" onClick={changeBillingDisplay}>
                                                                <span className="onoffswitch-inner"></span>
                                                                <span className="onoffswitch-switch"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="mb-2 row">
                                                    <label className="col-md-2 col-form-label">Sort</label>
                                                    <div className="col-md-10">
                                                        <input type="text" id="sort_order" name="sort_order" className="form-control" onChange={onFormUpdate} value={currentTag.sort_order}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-4">

                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col-12'>
                                                <div className="btn-group float-end">
                                                    <button type="submit" className="btn btn-primary btn btn-primary">Save Bill</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        authData: state.account,
        currentTag: state.tag.currentTag
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        updateCurrentTagData: (tagData) => {
            return dispatch( updateCurrentTag(tagData) )
        },
        newTag: (tagData) => {
            return dispatch( createTag(tagData) )
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateTagComponent)