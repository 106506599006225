import { CUSTOMER_LIST, CUSTOMER_DETAIL, CHANGE_CUSTOMER_DETAIL} from "./customerTypes"
import { TABLE_LOADER } from "../common/commonTypes";
import { fetchCustomers } from "../../service/customerService";

export const getCustomer = (query) => {
    return (dispatch) => {
        dispatch({ type: CUSTOMER_LIST, payload: null });
        dispatch({ type: TABLE_LOADER, payload: false });

        fetchCustomers(query).then( (data) => {
            dispatch(fetchCustomerList(data))

            dispatch({ type: TABLE_LOADER, payload: true });
        }).catch( (error) => {
            console.log('Bills fetching error')
        })
    }
}


export const fetchCustomerList = (customerList) => {
    return {
        type: CUSTOMER_LIST,
        payload: customerList
    }
}

export const setCustomerDetail = (customer) => {
    return {
        type: CUSTOMER_DETAIL,
        payload: customer
    }
}

export const changeCustomerDetail = (customer) => {
    return {
        type: CHANGE_CUSTOMER_DETAIL,
        payload: customer
    }
}