import { 
    CASHIER_POPUP, 
    UPDATE_CASHIER_LOADER, 
    ADD_CASHIER_UNPAID_LIST, 
    ADD_CASHIER_CUSTOMER,
    UPDATE_CASHIER_CHECKBOX, 
    UPDATE_PAID_CHECKBOX,
    UPDATE_PAIDBY_CHECKBOX,
    UPDATE_PAIDBY,
    UPDATE_PAY_BILL,
    UPDATE_DESCRIPTION,
    UPDATE_CUSTOMER_PAY,
    UPDATE_TO_CUSTOMER_PAY,
    UPDATE_BALANCE_TO_CUSTOMER,
    UPDATE_CASHIER_CUSTOMER
} from "./cashierTypes";
import { fetchPendingBills, newTransaction } from "../../service/cashierService";

export const openCashierPopup = (customerId) => {
    return (dispatch) => {
        dispatch(updateOpenPopup(true))
        dispatch(updateCashierLoader(true))

        dispatch(updateCashierCustomer(customerId))

        return fetchPendingBills(customerId).then((responseData) => {
            if(
                responseData && 
                responseData.data) {

                if( responseData.data.order && 
                responseData.data.order.rows && 
                responseData.data.order.rows.length > 0) {
                
                    let newData = responseData.data.order.rows.map(b => {
                        return {
                            ...b,
                            checked: false,
                            paying: 0.00
                        }
                    })
                    dispatch(fetchPendingBillList(newData))
                }

                if(responseData.data.customer) {

                    let customer = responseData.data.customer;
                    
                    dispatch(fetchPendingBillCustomer(customer))
                }  
            }
            
            dispatch(updateCashierLoader(false))
            return Promise.resolve(true);
        }).catch( (error) => {
            dispatch(updateCashierLoader(false))
            return Promise.reject(error);
        })
    }
}


export const closeCashierPopup = () => {
    return (dispatch) => {
        dispatch(updateOpenPopup(false))
        dispatch(updateCashierLoader(false))
    }
}

export const updateCheckbox = (billId) => {
    return (dispatch) => {
        dispatch(changeCheckbox(billId))

        dispatch(updateBalanceToCustomer())
    }
}

export const updatePaidCheckbox = (checked) => {
    return (dispatch) => {
        dispatch(changePaidCheckbox(checked))
    }
}
export const updatePaidByCheckbox = (checked) => {
    return (dispatch) => {
        dispatch(changePaidByCheckbox(checked))
    }
}
export const updatePaidBy = (option) => {
    return (dispatch) => {
        dispatch(changePaidBy(option))
    }
}
export const updateDescriptionBox = (value) => {
    return (dispatch) => {
        dispatch(changeDescriptionBox(value))
    }
}

export const updatePaying = (billId, pay) => {
    return (dispatch) => {
        dispatch(updateBillPaying(billId, pay))

        dispatch(updateBalanceToCustomer())
    }
}

export const updateCustomerPay = (pay) => {
    return (dispatch) => {
        pay = (pay === '' || pay < 0) ? 0 : pay
        dispatch(updateCustomerBillPay(pay))
        dispatch(updateBalanceToCustomer())
    }
}

export const createTransaction = (data) => {
    return (dispatch) => {
        return newTransaction(data).then( (responseData) => {
            return Promise.resolve(responseData);
        }).catch( (error) => {
            return Promise.reject(error);
        })
    }
}



export const updateCashierCustomer = (customerId) => {
    return {
        type: UPDATE_CASHIER_CUSTOMER,
        payload: customerId
    }
}

export const updateOpenPopup = (data) => {
    return {
        type: CASHIER_POPUP,
        payload: data
    }
}

export const updateCashierLoader = (data) => {
    return {
        type: UPDATE_CASHIER_LOADER,
        payload: data
    }
}

export const fetchPendingBillList = (data) => {
    return {
        type: ADD_CASHIER_UNPAID_LIST,
        payload: data
    }
}

export const fetchPendingBillCustomer = (data) => {
    return {
        type: ADD_CASHIER_CUSTOMER,
        payload: data
    }
}

export const changeCheckbox = (billId) => {
    return {
        type: UPDATE_CASHIER_CHECKBOX,
        payload: billId
    }
}

export const changePaidCheckbox = (checked) => {
    return {
        type: UPDATE_PAID_CHECKBOX,
        payload: checked
    }
}

export const changePaidByCheckbox = (checked) => {
    return {
        type: UPDATE_PAIDBY_CHECKBOX,
        payload: checked
    }
}
export const changePaidBy = (option) => {
    return {
        type: UPDATE_PAIDBY,
        payload: option
    }
}
export const changeDescriptionBox = (value) => {
    return {
        type: UPDATE_DESCRIPTION,
        payload: value
    }
}
export const updateBillPaying = (billId, pay) => {
    return {
        type: UPDATE_PAY_BILL,
        payload: {bill: billId, pay: pay}
    }
}

export const updateCustomerBillPay = (pay) => {
    return {
        type: UPDATE_CUSTOMER_PAY,
        payload: pay
    }
}

export const updateCustomerBillToPay = (pay) => {
    return {
        type: UPDATE_TO_CUSTOMER_PAY,
        payload: pay
    }
}

export const updateBalanceToCustomer = () => {
    return {
        type: UPDATE_BALANCE_TO_CUSTOMER,
        payload: null
    }
}
