import React, { useEffect, useState } from 'react'
import LoginComponent from './LoginComponent'
import moment from 'moment';
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    getCustomerTransactions
} from '../../redux'

function AdminUserComponent({
        authData,
        fetchTransactions,
        transactionList
    }) {
    const navigate = useNavigate() 

    const [mount, setMount] = useState(false)
    const[page, setPage] = useState({
        currentPage: 1,
        perPage: 20
    })

    useEffect(() => {
        if(!mount) {
            setMount(true);

            fetchTransactions({page: 1, limit: page.perPage})
            if(!authData || !authData.auth || !authData.auth.id) {
                const goToLoginPage = () => navigate('/admin/login');
                goToLoginPage();
            }
        }

    }, [mount, navigate, authData, fetchTransactions, page]);

    if(!authData || !authData.auth || !authData.auth.id) {
        return <LoginComponent></LoginComponent>
    }

    const handleClick = (e) => {
        e.preventDefault();
        setPage({
            ...page,
            currentPage: Number(e.currentTarget.id)
        });

        fetchTransactions({page: e.currentTarget.id, limit: page.perPage})
    }

    const pageNumbers = [];
    if(transactionList && transactionList.list) {
        // Logic for displaying page numbers
        for (let i = 1; i <= Math.ceil(transactionList.list.count / page.perPage); i++) {
            pageNumbers.push(i);
        }
    }
    const renderPageNumbers = pageNumbers.map(number => {
        return (
          <li
            className={ `paginate_button page-item  ${page.currentPage === number ? "active" : ""}` }
            key={number}
            id={number}
            onClick={handleClick}
          >
            <a href="!#" aria-controls="products-datatable" className="page-link">
                {number}
            </a>
          </li>
        );
    });

    return (
        <>
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mt-4">
                                <div className="card-header">
                                    <ul className="nav nav-tabs align-items-end card-header-tabs w-100">
                                        <li className="nav-item">
                                            <a className="nav-link active" href="/admin/transactions"><i className="fas fa-th-list mx-2"></i>Customer Transactions</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-body">
                                    <table className="table dataTable no-footer">
                                        <thead>
                                            <tr role="row">
                                                <th>Customer</th> 
                                                <th>Transaction Type</th> 
                                                <th>Transaction Date</th> 
                                                <th>Total</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            ( transactionList && transactionList.list && transactionList.list.rows) ? transactionList.list.rows.map((data, id) => {
                                                return <tr key={id}>
                                                    <td>{(data.customer && data.customer.name) ? data.customer.name : '-'}</td>
                                                    <td>
                                                        {
                                                            (data.transactionType === 'CREDIT') ? 
                                                            <><span className="badge bg-success">Cash In</span></>
                                                            : <><span className="badge bg-primary">Cash Out</span></>
                                                        }
                                                    </td>
                                                    <td>{(data.transactionAt) ? moment(data.transactionAt).format('MMMM Do YYYY') : '-'}</td>
                                                    <td>{data.total}</td>
                                                    <td>
                                                        <a href={'/admin/transaction/'+data.id} className="btn btn-info waves-effect waves-light">Transaction Detail</a>
                                                    </td>
                                                </tr>
                                            }) : null
                                        }
                                        </tbody>
                                    </table>


                                    <div className="row">
                                        <div className="col-sm-12 col-md-5">
                                            <div className="dataTables_info" role="status" aria-live="polite">Showing customers {page.currentPage*page.perPage - (page.perPage - 1)} to { (transactionList && transactionList.list && transactionList.list.count && transactionList.list.count < page.currentPage*page.perPage) ? transactionList.list.count : page.currentPage*page.perPage } of {transactionList && transactionList.list && transactionList.list.count ? transactionList.list.count : 0}</div>
                                        </div>
                                        <div className="col-sm-12 col-md-7">
                                            <div className="dataTables_paginate paging_simple_numbers">
                                                <ul className="pagination pagination-rounded justify-content-end">
                                                    {renderPageNumbers}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        authData: state.account,
        transactionList: state.transaction
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchTransactions: (query) => {
            dispatch( getCustomerTransactions(query) )
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminUserComponent)