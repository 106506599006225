import { CUSTOMER_TRANSACTION_LIST, CUSTOMER_STATEMENT_LIST } from "./transactionTypes"
import { TABLE_LOADER } from "../common/commonTypes";
import { fetchCustomerTransactions, fetchCustomerStatement } from "../../service/transactionService";

export const getCustomerStatement = (customerId, query) => {
    return (dispatch) => {
        dispatch({ type: CUSTOMER_STATEMENT_LIST, payload: null });
        dispatch({ type: TABLE_LOADER, payload: false });

        fetchCustomerStatement(customerId, query).then( (data) => {
            dispatch(fetchCustomerStatementList(data))

            dispatch({ type: TABLE_LOADER, payload: true });
        }).catch( (error) => {
            console.log('Bills fetching error')
        })
    }
}

export const getCustomerTransactions = (query) => {
    return (dispatch) => {
        dispatch({ type: CUSTOMER_TRANSACTION_LIST, payload: null });
        dispatch({ type: TABLE_LOADER, payload: false });

        fetchCustomerTransactions(query).then( (data) => {
            dispatch(fetchCustomerTransactionList(data))

            dispatch({ type: TABLE_LOADER, payload: true });
        }).catch( (error) => {
            console.log('Bills fetching error')
        })
    }
}


export const fetchCustomerTransactionList = (billList) => {
    return {
        type: CUSTOMER_TRANSACTION_LIST,
        payload: billList
    }
}

export const fetchCustomerStatementList = (statementList) => {
    return {
        type: CUSTOMER_STATEMENT_LIST,
        payload: statementList
    }
}
