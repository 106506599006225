import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getDashboardData } from '../../redux'
import moment from 'moment-timezone';
import { useNavigate } from "react-router-dom";
import BarChart from './include/Charts/BarChart';

function DashboardComponent({authData, fetchDashboard, dashboardData}) {
    const navigate = useNavigate() 
    useEffect(() => {
        moment.tz.setDefault("Asia/Calcutta|Asia/Kolkata");

        let date_from   = moment()
        let date_to     = moment()
        fetchDashboard({date_from: date_from, date_to: date_to, diff: '1d'})

        if(!authData || !authData.auth || !authData.auth.id) {
            const goToLoginPage = () => navigate('/admin/login');
            goToLoginPage();
        }
    }, [navigate, authData, fetchDashboard]);

    const reloadDashboard = (e) => {
        let day = e.target.getAttribute('data-fetch-day');
        switch(day){

            case '1d': {
                let date_from   = moment()
                let date_to     = moment()
                fetchDashboard({date_from: date_from, date_to: date_to, diff: '1d'})
                break;
            }

            case '7d': {
                let date_from   = moment().subtract(7,'d')
                let date_to     = moment()
                fetchDashboard({date_from: date_from, date_to: date_to, diff: '7d'})
                break;
            }

            case '15d': {
                let date_from   = moment().subtract(15,'d')
                let date_to     = moment()
                fetchDashboard({date_from: date_from, date_to: date_to, diff: '15d'})
                break;
            }

            case '1m': {
                let date_from   = moment().subtract(30,'d')
                let date_to     = moment()
                fetchDashboard({date_from: date_from, date_to: date_to, diff: '1m'})
                break;
            }

            case '1y': {
                let date_from   = moment().subtract(365,'d')
                let date_to     = moment()
                fetchDashboard({date_from: date_from, date_to: date_to, diff: '1y'})
                break;
            }

            default: break;
        }
    }

    return (
        <>
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4">


                            <div className="card mt-4">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between">
                                        <div className="w-100">
                                            <ul className="nav float-end d-none d-lg-flex">
                                                <li className="nav-item">
                                                    <a className="nav-link active" href="#" data-fetch-day="1d" onClick={reloadDashboard}>Today</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link text-muted" href="#" data-fetch-day="7d" onClick={reloadDashboard}>7d</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link text-muted" href="#" data-fetch-day="15d" onClick={reloadDashboard}>15d</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link text-muted" href="#" data-fetch-day="1m" onClick={reloadDashboard}>1m</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link text-muted" href="#" data-fetch-day="1y" onClick={reloadDashboard}>1y</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between">
                                        <div className="w-100">
                                            <h5 className="text-muted fw-normal mt-0 text-truncate" title="Campaign Sent">Sale Total</h5>
                                            <h2 className="my-3 text-center">
                                                Rs.{
                                                    (dashboardData && dashboardData.mainData && dashboardData.mainData.saleData) ? (dashboardData.mainData.saleData.currentAmount).toFixed(2) : 0.00
                                                }
                                            </h2>
                                            <p className="mb-0 text-muted d-flex justify-content-evenly">
                                                {
                                                    (() => {
                                                        if(dashboardData && dashboardData.mainData && dashboardData.mainData.saleData) {
                                                            if(dashboardData.mainData.saleData.changePercentage > 0) {
                                                                return <span className="text-success me-2">
                                                                    <span className="mdi mdi-arrow-up-bold"></span>
                                                                    { (dashboardData.mainData.saleData.changePercentage).toFixed(2) }%
                                                                </span>
                                                            } else {
                                                                return <span className="text-primary me-2">
                                                                    <span className="mdi mdi-arrow-down-bold"></span>
                                                                    { (dashboardData.mainData.saleData.changePercentage).toFixed(2) }%
                                                                </span>
                                                            }
                                                        }
                                                    })()
                                                }
                                                <span className="text-nowrap">
                                                {
                                                    (() => {
                                                        if(dashboardData && dashboardData.mainData && dashboardData.mainData.diff) {
                                                            if(dashboardData.mainData.diff === '1d') {
                                                                return <>Since yesterday</>
                                                            }
                                                            if(dashboardData.mainData.diff === '7d') {
                                                                return <>From previous 7 days</>
                                                            }
                                                            if(dashboardData.mainData.diff === '15d') {
                                                                return <>From previous 15 days</>
                                                            }
                                                            if(dashboardData.mainData.diff === '1m') {
                                                                return <>From previous 30 days</>
                                                            }
                                                            if(dashboardData.mainData.diff === '1y') {
                                                                return <>From previous 365 days</>
                                                            }
                                                        }
                                                    })()
                                                }
                                                </span>  
                                                <span className="text-nowrap fw-medium ms-2">
                                                Rs.{
                                                    (dashboardData && dashboardData.mainData && dashboardData.mainData.saleData) ? (dashboardData.mainData.saleData.previousAmount).toFixed(2) : 0.00
                                                }
                                                </span>
                                            </p>
                                        </div>
                                        <div className="avatar-sm">
                                            <span className="avatar-title bg-soft-primary rounded">
                                                <i className="ri-stack-line font-20 text-primary"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between">
                                        <div className="w-100">
                                            <h5 className="text-muted fw-normal mt-0 text-truncate" title="Campaign Sent">Payment Received</h5>
                                            <h2 className="my-3 text-center">
                                            Rs.{
                                                (dashboardData && dashboardData.mainData && dashboardData.mainData.paymentData) ? (dashboardData.mainData.paymentData.currentAmount).toFixed(2) : 0.00
                                            }
                                            </h2>
                                            <p className="mb-0 text-muted d-flex justify-content-evenly">
                                                {
                                                    (() => {
                                                        if(dashboardData && dashboardData.mainData && dashboardData.mainData.paymentData) {
                                                            if(dashboardData.mainData.paymentData.changePercentage > 0) {
                                                                return <span className="text-success me-2">
                                                                    <span className="mdi mdi-arrow-up-bold"></span>
                                                                    { (dashboardData.mainData.paymentData.changePercentage).toFixed(2) }%
                                                                </span>
                                                            } else {
                                                                return <span className="text-primary me-2">
                                                                    <span className="mdi mdi-arrow-down-bold"></span>
                                                                    { (dashboardData.mainData.paymentData.changePercentage).toFixed(2) }%
                                                                </span>
                                                            }
                                                        }
                                                    })()
                                                }
                                                <span className="text-nowrap">
                                                {
                                                    (() => {
                                                        if(dashboardData && dashboardData.mainData && dashboardData.mainData.diff) {
                                                            if(dashboardData.mainData.diff === '1d') {
                                                                return <>Since yesterday</>
                                                            }
                                                            if(dashboardData.mainData.diff === '7d') {
                                                                return <>From previous 7 days</>
                                                            }
                                                            if(dashboardData.mainData.diff === '15d') {
                                                                return <>From previous 15 days</>
                                                            }
                                                            if(dashboardData.mainData.diff === '1m') {
                                                                return <>From previous 30 days</>
                                                            }
                                                            if(dashboardData.mainData.diff === '1y') {
                                                                return <>From previous 365 days</>
                                                            }
                                                        }
                                                    })()
                                                }
                                                </span>
                                                <span className="text-nowrap fw-medium ms-2">
                                                Rs.{
                                                    (dashboardData && dashboardData.mainData && dashboardData.mainData.paymentData) ? (dashboardData.mainData.paymentData.previousAmount).toFixed(2) : 0.00
                                                }
                                                </span>
                                            </p>
                                        </div>
                                        <div className="avatar-sm">
                                            <span className="avatar-title bg-soft-primary rounded">
                                                <i className="fe-dollar-sign font-20 text-primary"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-8">
                            <div className="card mt-4">
                                <div className="card-body">
                                    <ul className="nav float-end d-none d-lg-flex">
                                        <li className="nav-item">
                                            <a className="nav-link active" href="#" data-fetch-day="1d" onClick={reloadDashboard}>Today</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link text-muted" href="#" data-fetch-day="7d" onClick={reloadDashboard}>7d</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link text-muted" href="#" data-fetch-day="15d" onClick={reloadDashboard}>15d</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link text-muted" href="#" data-fetch-day="1m" onClick={reloadDashboard}>1m</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link text-muted" href="#" data-fetch-day="1y" onClick={reloadDashboard}>1y</a>
                                        </li>
                                    </ul>
                                    
                                    <h4 className="header-title mb-3">Sessions Overview</h4>
                                    <div className="row align-items-center">
                                        <div className="col-xl-8">
                                            <BarChart />
                                        </div>
                                        <div className="col-xl-4">
                                            <h5 className="mb-1 mt-0">1,12,540 <small className="text-muted ms-2">www.getbootstrap.com</small></h5>
                                            <div className="row align-items-center g-0 mb-2 pb-1">
                                                <div className="col">
                                                    <div className="progress progress-sm">
                                                        <div className="progress-bar bg-primary" role="progressbar" style={{width: "72%"}} ></div>
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <div className="fw-medium ms-2">72%</div>
                                                </div>
                                            </div>

                                            <h5 className="mb-1 mt-0">1,12,540 <small className="text-muted ms-2">www.getbootstrap.com</small></h5>
                                            <div className="row align-items-center g-0 mb-2 pb-1">
                                                <div className="col">
                                                    <div className="progress progress-sm">
                                                        <div className="progress-bar bg-primary" role="progressbar" style={{width: "72%"}} ></div>
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <div className="fw-medium ms-2">72%</div>
                                                </div>
                                            </div>

                                            <h5 className="mb-1 mt-0">1,12,540 <small className="text-muted ms-2">www.getbootstrap.com</small></h5>
                                            <div className="row align-items-center g-0 mb-2 pb-1">
                                                <div className="col">
                                                    <div className="progress progress-sm">
                                                        <div className="progress-bar bg-primary" role="progressbar" style={{width: "72%"}} ></div>
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <div className="fw-medium ms-2">72%</div>
                                                </div>
                                            </div>

                                            <h5 className="mb-1 mt-0">1,12,540 <small className="text-muted ms-2">www.getbootstrap.com</small></h5>
                                            <div className="row align-items-center g-0 mb-2 pb-1">
                                                <div className="col">
                                                    <div className="progress progress-sm">
                                                        <div className="progress-bar bg-primary" role="progressbar" style={{width: "72%"}} ></div>
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <div className="fw-medium ms-2">72%</div>
                                                </div>
                                            </div>

                                            <h5 className="mb-1 mt-0">1,12,540 <small className="text-muted ms-2">www.getbootstrap.com</small></h5>
                                            <div className="row align-items-center g-0 mb-2 pb-1">
                                                <div className="col">
                                                    <div className="progress progress-sm">
                                                        <div className="progress-bar bg-primary" role="progressbar" style={{width: "72%"}} ></div>
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <div className="fw-medium ms-2">72%</div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>



                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        authData: state.account,
        dashboardData: state.dashboard
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchDashboard: (query) => {
            dispatch( getDashboardData(query) )
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DashboardComponent)