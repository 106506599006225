import { CUSTOMER_TRANSACTION_LIST, CUSTOMER_STATEMENT_LIST } from "./transactionTypes"

const initialState = {
    detail: null,
    list: null,
    statementList: null,
    error: "" 
}

const transactionReducer = (state = initialState, action) => {
    switch(action.type) {

        case CUSTOMER_TRANSACTION_LIST:
            return {
                ...state,
                list: action.payload,
            }
            
        case CUSTOMER_STATEMENT_LIST:
            return {
                ...state,
                statementList: action.payload,
            }

        default: return state;
    }
}

export default transactionReducer;
