import React, { useEffect, useState } from 'react'
import LoginComponent from './LoginComponent'
import Pagination from './include/Pagination';
import Loader from './include/Loader';
import moment from 'moment';
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    getCustomerTransactions
} from '../../redux'
import Select from 'react-select';
import DatePicker from "react-datepicker";
import Tooltip from './include/Tooltip';
import { startOfDay, endOfDay } from 'date-fns';
import { findBillingCustomer } from '../../service/billingService';

function CustomerTransactionComponent({
        authData,
        fetchTransactions,
        transactionList
    }) {
    const navigate = useNavigate() 

    //Product Ajax Select
    const[filter, setFilter] = useState({
        selectedOption: {},
        resultCustomer: [],
        billFromDate: startOfDay(new Date()),
        billToDate: endOfDay(new Date()),
        paidAsOptions: [
            { value: 'CASH', label: 'Cash' },
            { value: 'BANK', label: 'Bank' },
            { value: 'ADJ', label: 'Adj' },
            { value: 'WALLET', label: 'Wallet' }
        ],
        paidAsSelected: {}
    })
    const[page, setPage]    = useState({
        currentPage: 1,
        perPage: 20
    })
    
    const onPaidAsChange = (data) => {
        console.log(data)
        if (data !== null) {
            setFilter({...filter, paidAsSelected: {value: data.value, label: data.value}})
        } else {
            setFilter({...filter, paidAsSelected: {}}) 
        }
    }

    const onSelectChange = (data) => {
        if (data !== null) {
            setFilter({...filter, selectedOption: {value: data.id, label: data.label}})
        } else {
            setFilter({...filter, selectedOption: {}}) 
        }
    }

    let typingTimer, doneTypingInterval = 800;
    const onSelectSearch = (input) => {
        if(input.length > 0) {
            clearTimeout(typingTimer);
            typingTimer = setTimeout(doneTyping, doneTypingInterval, input);
        }       
    }

    const doneTyping = (val) => {

        findBillingCustomer({q: val}).then(data => {
            let output = data.rows.map( (data) => {
                return {
                    ...data,
                    value: data.id,
                    label: data.name+' ('+data.phone+')',
                }
            });
            setFilter({...filter, resultCustomer: output})
        })

    }
    const doFilter = () => {
        const { resultCustomer, paidAsOptions, ...filterData } = filter;
        fetchTransactions({page: page.currentPage, limit: page.perPage, filter: filterData})
    }
    //End of Product Ajax Select


    // Pagination
    const handlePageChange = (pageNumber) => {
        const { resultCustomer, paidAsOptions, ...filterData } = filter;
        setPage({
            ...page,
            currentPage: pageNumber
        });
        fetchTransactions({page: pageNumber, limit: page.perPage, filter: filterData})
    }
    // End Pagination

    function handleTooltipClick(event) {
        event.preventDefault();
        // Perform your desired action here
    }
    
    const [mount, setMount] = useState(false)
    useEffect(() => {
        if(!mount) {
            setMount(true);

            fetchTransactions({page: 1, limit: page.perPage})
            if(!authData || !authData.auth || !authData.auth.id) {
                const goToLoginPage = () => navigate('/admin/login');
                goToLoginPage();
            }
        }

    }, [mount, navigate, authData, fetchTransactions, page]);

    if(!authData || !authData.auth || !authData.auth.id) {
        return <LoginComponent></LoginComponent>
    }

    return (
        <>
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mt-4">
                                <div className="card-header">
                                    <ul className="nav nav-tabs align-items-end card-header-tabs w-100">
                                    <li className="nav-item">
                                            <a className="nav-link" href="/admin/customers"><i className="fas fa-th-list mx-2"></i>Customers</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link active" href="/admin/transactions"><i className="fas fa-th-list mx-2"></i>Customer Transactions</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-body">

                                    <div className="row">
                                        <div className="mb-2 col-md-3">
                                            <label className="form-label">Customer</label>
                                            <Select
                                                isSearchable
                                                isClearable={true}
                                                onChange={onSelectChange}
                                                onInputChange={onSelectSearch}
                                                options={filter.resultCustomer}
                                                value={filter.selectedOption}
                                            />
                                        </div>
                                        <div className="mb-2 col-md-3">
                                            <label className="form-label">Bill From</label>
                                            <DatePicker 
                                                className="form-control" 
                                                selected={filter.billFromDate} 
                                                onChange={(date) => setFilter({...filter, billFromDate: startOfDay(date)})} 
                                            />
                                        </div>
                                        <div className="mb-2 col-md-3">
                                            <label className="form-label">Bill To</label>
                                            <DatePicker 
                                                className="form-control" 
                                                selected={filter.billToDate} 
                                                onChange={(date) => setFilter({...filter, billToDate: endOfDay(date)})} 
                                            />
                                        </div>
                                        <div className="mb-2 col-md-3">
                                            <label className="form-label">Paid As</label>
                                            <Select
                                                isClearable={true}
                                                value={filter.paidAsSelected}
                                                onChange={onPaidAsChange}
                                                options={filter.paidAsOptions}
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label"></label>
                                            <button onClick={doFilter} type="button" className="btn btn-info width-md waves-effect waves-light">Filter</button>
                                        </div>
                                    </div>

                                    <table className="table dataTable no-footer">
                                        <thead>
                                            <tr role="row">
                                                <th>#</th>
                                                <th>Customer</th> 
                                                <th>Transaction Type</th> 
                                                <th>Paid As</th> 
                                                <th>Transaction Date</th> 
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            ( transactionList && transactionList.list && transactionList.list.rows && transactionList.list.rows.length > 0) ? transactionList.list.rows.map((data, id) => {
                                                return <tr key={id}>
                                                    <td>#{id+1}</td>
                                                    <td>{(data.customer && data.customer.name) ? data.customer.name : '-'}</td>
                                                    <td>
                                                        {
                                                            (data.transactionType === 'CREDIT') ? 
                                                            <><span className="badge bg-success">Cash In</span></>
                                                            : <><span className="badge bg-primary">Cash Out</span></>
                                                        }
                                                        {
                                                            (data.description && data.description != '') ? <Tooltip text={data.description}>
                                                                <a href="#" onClick={handleTooltipClick} className="action-icon"> <i className=" fas fa-info-circle"></i></a>
                                                            </Tooltip> : <></>
                                                        }
                                                    </td>
                                                    <td>{data.paidAs}</td>
                                                    <td>{(data.transactionAt) ? moment(data.transactionAt).format('MMMM Do YYYY') : '-'}</td>
                                                    <td>{data.total}</td>
                                                </tr>
                                            }) : <tr><td className="text-center" colSpan={6}>No Record</td></tr>
                                        }
                                        </tbody>
                                        <Loader colSpan={6}/>
                                    </table>


                                    <div className="row">
                                        <div className="col-sm-12 col-md-5">
                                            <div className="dataTables_info" role="status" aria-live="polite">Showing customers {page.currentPage*page.perPage - (page.perPage - 1)} to { (transactionList && transactionList.list && transactionList.list.count && transactionList.list.count < page.currentPage*page.perPage) ? transactionList.list.count : page.currentPage*page.perPage } of {transactionList && transactionList.list && transactionList.list.count ? transactionList.list.count : 0}</div>
                                        </div>
                                        <div className="col-sm-12 col-md-7">
                                            <div className="dataTables_paginate paging_simple_numbers float-end">
                                                <Pagination
                                                    current={page.currentPage}
                                                    perPageLimit={page.perPage}
                                                    totalRecords={(transactionList && transactionList.list && transactionList.list.count) ? transactionList.list.count : 0}
                                                    onPageChange={handlePageChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        authData: state.account,
        transactionList: state.transaction
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchTransactions: (query) => {
            dispatch( getCustomerTransactions(query) )
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerTransactionComponent)