import { UPDATE_CURRENT_PURCHASE, PURCHASE_BILL_LIST, PURCHASE_BILL_DETAIL, UPDATE_PURCHASE_STOCK, UPDATE_LOCK } from "./purchaseTypes";
import { TABLE_LOADER } from "../common/commonTypes";
import { storePurchase, fetchPurchaseBills, fetchPurchaseBill, updatePurchaseItem, uploadLock } from "../../service/purchaseService";

export const updateCurrentPurchase = (purchaseData) => {
    return (dispatch) => {
        dispatch(modifyCurrentPurchase(purchaseData))
    }
}

export const createPurchase = (purchaseData) => {
    return (dispatch) => {
        return storePurchase(purchaseData).then( (data) => {
            return Promise.resolve(data);
        }).catch( (error) => {
            return Promise.reject(error);
        })
    }
}

export const getPurchaseBills = (query) => {
    return (dispatch) => {
        dispatch({ type: PURCHASE_BILL_LIST, payload: null });
        dispatch({ type: TABLE_LOADER, payload: false });
        
        fetchPurchaseBills(query).then( (data) => {
            dispatch(fetchPurchaseBillList(data))

            dispatch({ type: TABLE_LOADER, payload: true });
        }).catch( (error) => {
            console.log('Bills fetching error')
        })
    }
}

export const lockPurchase = (id) => {
    return (dispatch) => {
        return uploadLock(id).then( (data) => {
            dispatch(lockBill(id))
            return Promise.resolve(true);
        }).catch( (error) => {
            return Promise.reject(error);
        })
    }
}

export const getPurchaseBillDetail = (billId) => {
    return (dispatch) => {
        fetchPurchaseBill(billId).then( (data) => {
            dispatch(fetchPurchaseBillDetail(data))
        }).catch( (error) => {
            console.log('Bill fetching error')
        })
    }
}

export const updateItem = (billItem) => {
    return (dispatch) => {
        return updatePurchaseItem(billItem).then( (response) => {
            dispatch(updatePurchaseStockItem(response.data))
            return Promise.resolve(response.data);
        }).catch( (error) => {
            console.log('Purchase item update error')
            return Promise.reject(error);
        })
    }
}

export const modifyCurrentPurchase = (purchaseData) => {
    return {
        type: UPDATE_CURRENT_PURCHASE,
        payload: purchaseData
    }
}

export const fetchPurchaseBillList = (billList) => {
    return {
        type: PURCHASE_BILL_LIST,
        payload: billList
    }
}

export const fetchPurchaseBillDetail = (bill) => {
    return {
        type: PURCHASE_BILL_DETAIL,
        payload: bill
    }
}

export const updatePurchaseStockItem = (item) => {
    return {
        type: UPDATE_PURCHASE_STOCK,
        payload: item
    }
}

export const lockBill = (id) => {
    return {
        type: UPDATE_LOCK,
        payload: id
    }
}
