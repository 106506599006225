import { 
    UPDATE_CURRENT_STOCK,
    CLEAR_CURRENT_STOCK
} from "./stockTypes";

const initialState = {
    currentStock: {
        basePrice: 0.00,
        gstPercentage: 0.00,
        gstPrice: 0.00,
        unitPrice: 0.00,
        unitType: 'Pcs',
        stockQty: 0,
        totalPrice: 0.00,
        balanceQty: 0,
        purchase: {
            id: 0,
            label: ""
        }
    }, 
    error: "" 
}

const stockReducer = (state = initialState, action) => {
    switch(action.type) {
        
        case UPDATE_CURRENT_STOCK:
            return {
                ...state,
                currentStock: action.payload,
            }

        case CLEAR_CURRENT_STOCK:
            return {
                ...state,
                currentStock: {
                    basePrice: 0.00,
                    gstPercentage: 0.00,
                    gstPrice: 0.00,
                    unitPrice: 0.00,
                    unitType: 'Pcs',
                    stockQty: 0,
                    totalPrice: 0.00,
                    balanceQty: 0,
                    purchase: {
                        id: 0,
                        label: ""
                    }
                },
            }


        default: return state;
    }
}

export default stockReducer;