import api from "../network/api" 

export const storeStock = (stock) => {
    let payload = {
        productId : stock.productId,
        basePrice : parseFloat(stock.basePrice),
        gstPercentage : stock.gstPercentage,
        stockQty : stock.stockQty,
        unitType: stock.unitType,
        purchaseId: stock.purchase.id
    };

    return api.post("/stock", payload).then(response => {
        if (response) {
            return response.data
        }
    })
}