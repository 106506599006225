import React, { useRef, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select';
import socket from '../../../../network/socket';
import { 
    addCustomerToBill,
    createBillingCustomer,
    removeBillCustomer
} from '../../../../redux'
import {findBillingCustomer} from "../../../../service/billingService";
import { getPlayableIcon } from '../../../../config'

const BillCustomer = ({
    customer,
    customerToBill,
    createNewCustomer,
    removeCustomerFromBill,
    authData
}) => {
    useEffect(() => {
        socket.on('billing-customer', (customer) => {
            if(authData && authData.auth && authData.auth.id) {
                if(Number(authData.auth.billDesk) === Number(customer.billDesk) || Number(authData.auth.billDesk) === 0) {
                    // Handle the incoming message from the server
                    customerToBill(customer)
                }
            }
        });
    }, [authData, socket]);

    const selectRef = useRef();

    const[details, setDetails] = useState({
        resultCustomer: [],
        customerInput: "",
        newCustomerInput: "",
        newCustomer: false
    })

    const onCustomerChange = (data) => {
        const customer = data
        customerToBill(customer)
    }

    let typingTimer, doneTypingInterval = 800;
    const onCustomerSearch = (input) => {
        if(input.length > 0) {
            clearTimeout(typingTimer);
            typingTimer = setTimeout(doneTyping, doneTypingInterval, input);
        }       
    }
    const doneTyping = (val) => {

        findBillingCustomer({q: val}).then(data => {
            let output = data.rows.map( (data) => {
                return {
                    ...data,
                    app: 0,
                    value: data.id,
                    label: data.name+' ('+data.phone+')',
                }
            });
            setDetails({...details, newCustomerInput: val, resultCustomer: output})
        })
    }

    function removeBillingCustomer() {
        removeCustomerFromBill()
    }

    function newCustomer() {
        setDetails({
            ...details, 
            newCustomer: true
        })
    }
    
    function existingCustomer() {
        setDetails({
            ...details, 
            newCustomer: false
        })
    }

    function onCustomerInputChange(e) {
        e.preventDefault()
        setDetails({
            ...details, 
            newCustomerInput: e.target.value
        })
    }

    function createCustomer() {
        var nameArr = details.newCustomerInput.split(',');
        if(nameArr.length < 2 ) {
            alert('Customer should be name, mobile format')
            return;
        }

        if((nameArr[1]).trim() === '') {
            alert('Customer should be name, mobile format')
            return;
        }
        
        let name    = (nameArr[0]).trim()
        let phone   = (nameArr[1]).trim()
        let address  = (nameArr[2]) ? (nameArr[2]).trim() : ''
        createNewCustomer({name: name, phone: phone, address: address}).then((data) => {
            setDetails({
                ...details, 
                newCustomer: false,
                newCustomerInput: ""
            })
        }).catch(e => {
            console.log(e);
        });
    }
    



    return (
        <>
        <div className="pos-biller mt-4">
            <div className='pos-barcode-input p-1'>
                <div className='row'>
                    {(() => {
                        if(customer && customer.id) {
                            return (
                                <>
                                <div className='col-12'>
                                    <div className="d-flex align-items-start playable-avatar">
                                        <div className="avatar-md me-3">
                                            <div className="avatar-title bg-light rounded text-body font-20 fw-semibold">
                                                {((customer.name).trim()).charAt(0)}
                                            </div>
                                            {
                                                (customer.app && customer.app === 1) ? <>
                                                    <div className="playable-verified">
                                                        <img className="img-fluid" src={getPlayableIcon()} />
                                                    </div>
                                                </> : <></>
                                            }
                                        </div>
                                        <div className="flex-1">
                                            <h4 className="my-1"><span className="text-dark">{customer.name}</span></h4>
                                            <p className="text-muted text-truncate mb-0">
                                                <i className="ri-map-pin-line align-bottom me-1"></i>Points: {customer.point}
                                            </p>
                                        </div>
                                        <div className="text-body dropdown-toggle" onClick={removeBillingCustomer}>
                                            <i className="far fa-times-circle font-20"></i>
                                        </div>
                                    </div>
                                </div>
                              
                                </>
                            )
                        } else {
                            if (details.newCustomer) {
                                return (
                                    <>
                                    <div className='col-10'>
                                        <div className="input-group bootstrap-touchspin bootstrap-touchspin-injected">
                                            <input type="text" className="form-control new-customer-input" placeholder="Name, Phone" onChange={onCustomerInputChange} value={details.newCustomerInput}/>
                                            <span className="input-group-btn input-group-append">
                                                <button onClick={createCustomer} className="btn btn-primary bootstrap-touchspin-up new-customer-btn new-customer-btn" type="button">Create</button>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <div className="d-grid gap-2">
                                            <button type="button" onClick={existingCustomer} className="btn btn-secondary waves-effect waves-light px-2 new-customer-btn"><i className="fas fa-long-arrow-alt-right"></i></button>
                                        </div>
                                    </div>
                                    </>
                                )
                            } else {
                                return (
                                    <>
                                    <div className='col-6 col-xl-8'>
                                        <Select
                                            ref={selectRef}
                                            id="product-select"
                                            isSearchable
                                            onChange={onCustomerChange}
                                            onInputChange={onCustomerSearch}
                                            options={details.resultCustomer}
                                            value={details.customerInput}
                                        />
                                    </div>
                                    <div className='col-6 col-xl-4'>
                                        <div className="d-grid">
                                            <button type="button" onClick={newCustomer} className="btn btn-info width-md waves-effect waves-light new-customer-btn">New Customer</button>
                                        </div>
                                    </div>
                                    </>
                                )
                            }
                        }

                    })()}
                </div>
            </div>
        </div>
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        customer: state.billing.customer,
        authData: state.account
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        customerToBill: (customer) => {
            dispatch(addCustomerToBill(customer))
        },
        createNewCustomer: (data) => {
            return dispatch(createBillingCustomer(data))
        },
        removeCustomerFromBill: () => {
            dispatch(removeBillCustomer())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillCustomer)