import { 
    REFUND_POPUP, 
    UPDATE_REFUND_LOADER, 
    UPDATE_REFUND_BILL, 
    REFUND_ORDER_ITEMS, 
    UPDATE_REFUND_ORDER_ITEMS, 
    UPDATE_AMOUNT, 
    UPDATE_REFUND_DISCOUNT, 
    UPDATE_REFUND_CALCULATED ,
    UPDATE_REFUND_QTY_ITEMS,
    UPDATE_PRICE_QTY_ITEMS,
    RETURN_BILL_LOADER
} from "./refundTypes"

const initialState = {
    isOpen: false,
    loading: false,
    returnLoader: false,
    billNumber: null,
    orderItems: null,

    amountToPay: 0.00,
    discount: 0.00,
    subTotal: 0.00,
    total: 0.00,
    originalAmountToPay: 0.00,
    originalDiscount: 0.00,
    originalSubTotal: 0.00,
    originalTotal: 0.00,

    updatedRefundItems: [],
    updatedPriceItems:[]
}

const refundReducer = (state = initialState, action) => {

    switch(action.type) {
        case RETURN_BILL_LOADER:
            return {
                ...state,
                returnLoader: action.payload,
            }
        case REFUND_POPUP:
            return {
                ...state,
                isOpen: action.payload,
            }

        case UPDATE_REFUND_LOADER:
            return {
                ...state,
                loading: action.payload,
            }

        case UPDATE_REFUND_BILL:
            return {
                ...state,
                billNumber: action.payload
            }

        case REFUND_ORDER_ITEMS:
            return {
                ...state,
                orderItems: action.payload
            }
            

        case UPDATE_REFUND_ORDER_ITEMS:
            return {
                ...state,
                orderItems: action.payload
            }

        case UPDATE_AMOUNT:
            return {
                ...state,
                amountToPay: action.payload.amountToPay,
                discount: action.payload.discount,
                subTotal: action.payload.subTotal,
                total: action.payload.total,
                originalAmountToPay: action.payload.originalAmountToPay,
                originalDiscount: action.payload.originalDiscount,
                originalSubTotal: action.payload.originalSubTotal,
                originalTotal: action.payload.originalTotal
            }

        case UPDATE_REFUND_DISCOUNT:
            return {
                ...state,
                discount: action.payload
            }
            
        case UPDATE_REFUND_CALCULATED:
            return {
                ...state,
                subTotal: action.payload.subTotal,
                total: action.payload.total
            }

        case UPDATE_REFUND_QTY_ITEMS:
            return {
                ...state,
                updatedRefundItems: action.payload
            }

        case UPDATE_PRICE_QTY_ITEMS:
            return {
                ...state,
                updatedPriceItems: action.payload
            }

        default: return state;
    }
}

export default refundReducer;