import {
    ADD_PAGINATE_PRODUCTS, 
    ADD_CURRENT_PRODUCT, 
    UPDATE_CURRENT_PRODUCT, 
    ADD_CURRENT_PRODUCT_MEDIA,
    CLEAR_CURRENT_PRODUCT,
    FETCH_PRODUCT,
    ADD_TO_PRODUCTS
} from "./productTypes";
import { fetchBillTags } from "../tag/tagAction";
import { storeProduct, updateProduct, fetchProductsService, updateProductEcom } from "../../service/productService";


export const addStockToProduct = (stock, currentProduct, products) => {
    return (dispatch) => {
        let oldStocks = currentProduct.stocks;
        let activeProduct = {
            ...currentProduct,
            stocks: oldStocks.concat(stock)
        }

        let newProducts = products;
        newProducts = newProducts.map( (produt) => {
            if(parseInt(produt.id) === stock.productId) {
                return activeProduct;
            } else {
                return produt;
            }
        })
        
        dispatch(modifyCurrentProduct(activeProduct))
        dispatch(fetchProducts(newProducts))
    }
}

export const fetchProductsAndBillingTags = (query) => {
    return (dispatch) => {
        fetchProductsService(query).then( (data) => {
   
            dispatch(fetchProducts(data))

            dispatch(fetchBillTags(''))
        }).catch( (error) => {
            console.log('Products & tags fetching error')
        })
    }
}

export const getProducts = (query, page) => {
    return async (dispatch) => {
        try {
     
            const data = await fetchProductsService(query);

            if(page === 1) {
                dispatch(fetchProducts(data))
            } else {
                data.forEach(product => {
                    dispatch(addToPaginateProducts(product))
                });
            }

            return data;
        } catch (error) {
            console.error('Products fetching error:', error);
            return [];
        }
    }
}

export const addCurrentProduct = (product) => {
    return (dispatch) => {
        dispatch(createCurrentProduct(product))
    }
}

export const updateCurrentProduct = (product) => {
    return (dispatch) => {
        dispatch(modifyCurrentProduct(product))
    }
}
export const updateCurrentProductMedia = (media) => {
    return (dispatch) => {
        dispatch(addCurrentProductMedia(media))
    }
}

export const createNewProduct = (product) => {
    return (dispatch) => {
        //dispatch(addCurrentProductMedia(media))
        return storeProduct(product).then( (data) => {
            dispatch(addToProducts(data.data))

            return Promise.resolve(data);
        }).catch( (error) => {
            //console.log(error.response)
            return Promise.reject(error);
        })
    }
}

export const updateExistingProduct = (id, product) => {
    return (dispatch) => {
        return updateProduct(id, product).then( (data) => {
            dispatch(addToProducts(data.data))
            return Promise.resolve(data);
        }).catch( (error) => {
            //console.log(error.response)
            return Promise.reject(error);
        })
    }
}

export const updateExistingEcom = (id, product) => {
    return (dispatch) => {
        return updateProductEcom(id, product).then( (data) => {
            dispatch(addToProducts(data.data))
            return Promise.resolve(data);
        }).catch( (error) => {
            //console.log(error.response)
            return Promise.reject(error);
        })
    }
}

export const removeCurrentProduct = () => {
    return (dispatch) => {
        dispatch(clearCurrentProduct())
    }
}






export const fetchProducts = (products) => {
    return {
        type: FETCH_PRODUCT,
        payload: products
    }
}
export const addToPaginateProducts = (product) => {
    return {
        type: ADD_PAGINATE_PRODUCTS,
        payload: product
    }
}
export const addToProducts = (product) => {
    return {
        type: ADD_TO_PRODUCTS,
        payload: product
    }
}

export const createCurrentProduct = (product) => {
    return {
        type: ADD_CURRENT_PRODUCT,
        payload: product
    }
}

export const modifyCurrentProduct = (product) => {
    return {
        type: UPDATE_CURRENT_PRODUCT,
        payload: product
    }
}
export const addCurrentProductMedia = (media) => {
    return {
        type: ADD_CURRENT_PRODUCT_MEDIA,
        payload: media
    }
}
export const clearCurrentProduct = () => {
    return {
        type: CLEAR_CURRENT_PRODUCT,
    }
}


