import React from 'react';
import { connect } from 'react-redux'
import { Outlet, useNavigate } from "react-router-dom";

import { logoutUser } from './redux'

function App({authData, logout}) {

    const navigate = useNavigate() 
	function triggerLogout(e) {
		e.preventDefault()
		logout();
		navigate("/admin/login");
	}



	return (
		<div id="wrapper">
			<Outlet />
		</div>
	);
}

const mapStateToProps = (state) => {
    return {
        authData: state.auth,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        logout : () => {
			dispatch(logoutUser())
		} 
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(App);