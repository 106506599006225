import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar } from "react-chartjs-2";
import { connect } from 'react-redux'

const BarChart = ({chartData}) => {
	ChartJS.register(...registerables);
	
	return (
		<div>
			<Bar
				data={chartData}
				options={{
					plugins: {
						title: {
							display: true,
							text: "Cryptocurrency prices"
						},
						legend: {
							display: true,
							position: "bottom"
						}
					}
				}}
			/>
		</div>
	);
}
const mapStateToProps = (state) => {
    return {
		chartData: state.dashboard.saleChart
    }
}
const mapDispatchToProps = (dispatch) => {
    return {

	}
}

export default connect(mapStateToProps, mapDispatchToProps)(BarChart)