import io from 'socket.io-client';
let url;
switch(process.env.NODE_ENV) {
    case 'production':
        url = 'https://apibill.a2zstreet.in';
        break;
    case 'development':
        url = 'http://localhost:8000';
        break;
    default:
        url = 'http://localhost:8000';
}

const socket = io(url);
export default socket;