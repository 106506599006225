import React, { useEffect, useState } from 'react'
import LoginComponent from './LoginComponent'
import Pagination from './include/Pagination';
import Loader from './include/Loader';
import moment from 'moment';
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    getBills,
    updateDeleteBill
} from '../../redux'
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { startOfDay, endOfDay } from 'date-fns';
import { findBillingCustomer } from '../../service/billingService';

function ReportComponent({
        authData,
        billList,
        fetchBills,
        deleteBill
    }) {
    const navigate = useNavigate() 


    //Product Ajax Select
    const[filter, setFilter] = useState({
        selectedOption: {},
        resultCustomer: [],
        billFromDate: startOfDay(new Date()),
        billToDate: endOfDay(new Date()),
    })

    const onSelectChange = (data) => {
        if (data !== null) {
            setFilter({...filter, selectedOption: {value: data.id, label: data.label}})
        } else {
            setFilter({...filter, selectedOption: {}}) 
        }
    }

    let typingTimer, doneTypingInterval = 800;
    const onSelectSearch = (input) => {
        if(input.length > 0) {
            clearTimeout(typingTimer);
            typingTimer = setTimeout(doneTyping, doneTypingInterval, input);
        }       
    }

    const doneTyping = (val) => {

        findBillingCustomer({q: val}).then(data => {
            let output = data.rows.map( (data) => {
                return {
                    ...data,
                    value: data.id,
                    label: data.name+' ('+data.phone+')',
                }
            });
            setFilter({...filter, resultCustomer: output})
        })

    }
    const doFilter = () => {
        const { resultCustomer, ...filterData } = filter;
        fetchBills({page: 1, limit: page.perPage, filter: filterData})
    }
    //End of Product Ajax Select

    // Pagination
    const[page, setPage]    = useState({
        currentPage: 1,
        perPage: 20
    })
    const handlePageChange = (pageNumber) => {
        setPage({
            ...page,
            currentPage: pageNumber
        });

        fetchBills({page: pageNumber, limit: page.perPage})
    }
    // End Pagination

    const [mount, setMount] = useState(false)
    useEffect(() => {
        if(!mount) {
            setMount(true);

            fetchBills({page: 1, limit: page.perPage})
            if(!authData || !authData.auth || !authData.auth.id) {
                const goToLoginPage = () => navigate('/admin/login');
                goToLoginPage();
            }
        }

    }, [mount, navigate, authData, fetchBills, page]);

    if(!authData || !authData.auth || !authData.auth.id) {
        return <LoginComponent></LoginComponent>
    }

    const deleteCustomerBill = (e) => {
        const id = e.currentTarget.getAttribute('data-billnumber');
        deleteBill(id).then(response => {
            window.location.reload(false);
        }).catch(error => {
            let errorMessage = '';
            if(error && error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
                error.response.data.errors.forEach(error => {
                    errorMessage += error.msg+' '
                });
            }
            if(error && error.response && error.response.data && error.response.data.message && error.response.data.message !== '') {
                errorMessage += error.response.data.message
            }
            alert(errorMessage)
        })
    }

    return (
        <>
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mt-4">
                                <div className="card-header">
                                    <ul className="nav nav-tabs align-items-end card-header-tabs w-100">
                                        <li className="nav-item">
                                            <a className="nav-link" href="/admin/billing"><i className="fas fa-plus-circle mx-2"></i>New Bill</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link active" href="/admin/bills"><i className="fas fa-th-list mx-2"></i>Bills</a>
                                        </li>
                                        <li className="nav-item">
                                        <a className="nav-link" href="/admin/sold/items"><i className="fas fa-th-list mx-2"></i>Sold Items</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="mb-2 col-md-3">
                                            <label className="form-label">Customer</label>
                                            <Select
                                                isSearchable
                                                isClearable={true}
                                                onChange={onSelectChange}
                                                onInputChange={onSelectSearch}
                                                options={filter.resultCustomer}
                                                value={filter.selectedOption}
                                            />
                                        </div>
                                        <div className="mb-2 col-md-3">
                                            <label className="form-label">Bill From</label>
                                            <DatePicker 
                                                className="form-control" 
                                                selected={filter.billFromDate} 
                                                onChange={(date) => setFilter({...filter, billFromDate: startOfDay(date)})} 
                                            />
                                        </div>
                                        <div className="mb-2 col-md-3">
                                            <label className="form-label">Bill To</label>
                                            <DatePicker 
                                                className="form-control" 
                                                selected={filter.billToDate} 
                                                onChange={(date) => setFilter({...filter, billToDate: endOfDay(date)})} 
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label"></label>
                                            <button onClick={doFilter} type="button" className="btn btn-info width-md waves-effect waves-light">Filter</button>
                                        </div>
                                    </div>
                                    <table className="table dataTable no-footer">
                                        <thead>
                                            <tr role="row">
                                                <th>#</th> 
                                                <th>Customer</th> 
                                                <th>Bill Date</th> 
                                                <th>Bill Total</th> 
                                                <th>Amount To Pay</th> 
                                                <th>Action</th> 
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                ( billList && billList.list && billList.list.rows && billList.list.rows.length > 0) ? billList.list.rows.map((data, id) => {
                                                    return <tr key={id}>
                                                        <td>#{id+1}</td>
                                                        <td>{(data.customer && data.customer.name) ? data.customer.name : '-'}</td>
                                                        <td>{moment(data.orderedAt).format('MMMM Do YYYY')}</td>
                                                        <td>{data.total}</td>
                                                        <td>{data.amountToPay}</td>
                                                        <td>
                                                            <a href={'/admin/bill/'+data.billNumber} className="btn btn-info waves-effect waves-light">Bill Detail</a>
                                                            {
                                                                (authData && authData.auth && authData.auth.role === 1) ? 
                                                                    <button type="button" data-billnumber={data.billNumber} onClick={deleteCustomerBill} className="btn btn-primary waves-effect waves-light ms-2"><i className="fa fa-trash"></i></button>
                                                                : ''
                                                            }
                                                        </td>
                                                    </tr>
                                                }) : <tr><td className="text-center" colSpan={6}>No Record</td></tr>
                                            }
                                        </tbody>
                                        <Loader colSpan={6}/>
                                    </table>

                                    <div className="row">
                                        <div className="col-sm-12 col-md-5">
                                            <div className="dataTables_info" role="status" aria-live="polite">Showing customers {page.currentPage*page.perPage - (page.perPage - 1)} to { (billList && billList.list && billList.list.count && billList.list.count < page.currentPage*page.perPage) ? billList.list.count : page.currentPage*page.perPage } of {billList && billList.list && billList.list.count ? billList.list.count : 0}</div>
                                        </div>
                                        <div className="col-sm-12 col-md-7">
                                            <div className="dataTables_paginate paging_simple_numbers float-end">
                                                <Pagination
                                                    current={page.currentPage}
                                                    perPageLimit={page.perPage}
                                                    totalRecords={(billList && billList.list && billList.list.count) ? billList.list.count : 0}
                                                    onPageChange={handlePageChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        authData: state.account,
        billList: state.billing
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchBills: (query) => {
            dispatch( getBills(query) )
        },
        deleteBill: (id) => {
            return dispatch( updateDeleteBill(id) )
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportComponent)