import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { loginUser } from '../../redux'

import { useNavigate } from "react-router-dom";

function LoginComponent({authData, loginUser}) {

    const navigate = useNavigate() 
    useEffect(() => {
        const goToBillingPage = () => navigate('/admin/billing');
        if(authData && authData.auth && authData.auth.id) {
            goToBillingPage();
        }
    }, [authData, navigate]);

    const[details, setDetails] = useState({email: "", password:""})
    const submitHandler = (e) => {
        e.preventDefault();
        loginUser({email:details.email, password:details.password}).then(data => {
            navigate("/admin/billing");
        })
    }

    const changeHandler = (e) => {
        const field = e.target.name;
        switch(field) {
            case 'email':
                setDetails({...details, email:e.target.value})
                break;
            case 'password':
                setDetails({...details, password:e.target.value})
                break;
            default:  
        }
    }

    return (

        <div className="auth-fluid">
            <div className="auth-fluid-right">
                <div className="auth-user-testimonial">
                    <h3 className="mb-3 text-white">Very elegant &amp; impressive!</h3>
                    <p className="lead fw-normal"><i className="mdi mdi-format-quote-open"></i> I've been using this theme for a while and I must say that whenever I am looking for a design - I refer to this theme for specifics &amp; implementation. With wide arrays of components, designs, charts - I would highly recommend this theme for anyone using it for dashboard or project management usage.. <i className="mdi mdi-format-quote-close"></i>
                    </p>
                    <h5 className="text-white">
                    </h5>
                </div>
            </div>
            <div className="auth-fluid-form-box">
                <div className="align-items-center d-flex h-100">
                    <div className="card-body">
                        <div className="text-center text-lg-start">
                            <div className="auth-logo">
                                <a href="!#" className="logo logo-dark text-center">
                                    <span className="logo-lg">
                                        <img src="../assets/images/logo-dark.png" alt=""/>
                                    </span>
                                </a>
                                <a href="!#" className="logo logo-light text-center">
                                    <span className="logo-lg">
                                        <img src="../assets/images/logo-dark.png" alt="" />
                                    </span>
                                </a>
                            </div>
                        </div>
                        <h4 className="mt-0">Sign In</h4>
                        <p className="text-muted mb-4">Enter your email address and password to access admin panel.</p>
                        <form onSubmit={submitHandler}>
                            <div className="mb-2">
                                <label htmlFor="emailaddress" className="form-label">Email address</label>
                                <input className="form-control" onChange={changeHandler} type="text" name="email" id="email" value={details.email} placeholder="Enter your email" />
                            </div>
                            <div className="mb-2">
                                <label htmlFor="password" className="form-label">Password</label>
                                <div className="input-group input-group-merge">
                                    <input className="form-control" onChange={changeHandler} type="password" name="password" id="password" value={details.password} placeholder="Enter your password" />
                                    <div className="input-group-text" data-password="false">
                                        <span className="password-eye"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="d-grid mb-0 text-center">
                                <button className="btn btn-primary" type="submit"> Log In </button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        authData: state.account
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: (loginData) => {
            return dispatch( loginUser(loginData) ).then( () => {
                return true
            }).catch( (err) => {
                console.log(err)
            })
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent)