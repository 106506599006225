import api from "../network/api" 

export const fetchCustomerTransactions = (query) => {

    return api.get("/transaction", { params: query }).then(response => {
        if (response) {
            return response.data.data
        }
    })
}

export const fetchCustomerStatement = (customerId, query) => {
    console.log(query);
    return api.get("/customer/"+customerId+"/statement", { params: query }).then(response => {
        if (response) {
            return response.data.data
        }
    })
}