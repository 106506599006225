import React from 'react';

function Tooltip({ text, children }) {
  return (
    <div className="tooltipp">
      {children}
      <div className="tooltip-content">{text}</div>
    </div>
  );
}

export default Tooltip;