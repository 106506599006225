import React from 'react'
import { connect } from 'react-redux'
import { loginUser } from '../redux'


function HomeComponent({authData, loginUser}) {
    return (
        <div className="auth-fluid">
            
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        authData: state.auth
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: (loginData) => {
            return dispatch( loginUser(loginData) ).then( () => {
                return true
            }).catch( (err) => {
                console.log(err)
            })
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(HomeComponent)