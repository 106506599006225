class TokenService {

    getLocalAccessToken() {
        const user = JSON.parse(localStorage.getItem("user"));
        return user?.accessToken;
    }

    getLocalRefreshToken() {
        const user = JSON.parse(localStorage.getItem("user"));
        return user?.refreshToken;
    }

    setUser(user) {
		localStorage.setItem("user", JSON.stringify(user));
    }

    updateTokenService(tokens) {
        let user = JSON.parse(localStorage.getItem("user"));
        user.accessToken = tokens.accessToken;
        user.refreshToken = tokens.refreshToken;
        localStorage.setItem("user", JSON.stringify(user));
    }

    removeUser() {
        localStorage.removeItem("user");
    }
}

export default new TokenService();