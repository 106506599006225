import { 
    REFUND_POPUP, 
    UPDATE_REFUND_LOADER, 
    UPDATE_REFUND_BILL, 
    REFUND_ORDER_ITEMS, 
    UPDATE_REFUND_ORDER_ITEMS, 
    UPDATE_AMOUNT, 
    UPDATE_REFUND_DISCOUNT, 
    UPDATE_REFUND_CALCULATED,
    UPDATE_REFUND_QTY_ITEMS,
    UPDATE_PRICE_QTY_ITEMS,
    RETURN_BILL_LOADER
} from "./refundTypes"
import { fetchBill, updateBill } from "../../service/billingService"

export const createReturnBill = (billNumber, refund) => {
    return (dispatch) => {

        dispatch(returnBillLoader(true))
        return updateBill(billNumber, refund).then((responseData) => {
            dispatch(returnBillLoader(false))
            return Promise.resolve(responseData);
        }).catch( (error) => {
            dispatch(returnBillLoader(false))
            return Promise.reject(error);
        })
    }
}

export const openReturnPopup = (billNumber) => {
    return (dispatch) => {
        dispatch(updateReturnPopup(true))
        dispatch(updateReturnLoader(true))

        dispatch(updateReturnBill(billNumber))

        return fetchBill(billNumber).then((responseData) => {


            var amountData = {
                amountToPay: responseData.amountToPay,
                discount: responseData.discount,
                subTotal: responseData.subTotal,
                total: responseData.total,
                originalAmountToPay: responseData.amountToPay,
                originalDiscount: responseData.discount,
                originalSubTotal: responseData.subTotal,
                originalTotal: responseData.total,
            }
            dispatch(updateReturnAmountData(amountData))


            if(responseData && responseData.order_items && responseData.order_items.length > 0) {

                responseData.order_items = responseData.order_items.map(item => {
                    return {
                      ...item,
                      originalSaleUnitPrice: item.saleUnitPrice,
                      originalSalePrice: item.salePrice,
                      originalSaleQty: item.saleQty
                    };
                });

                dispatch(updateReturnOrderItems(responseData.order_items))
            }
            
            dispatch(updateReturnLoader(false))
            return Promise.resolve(true);
        }).catch( (error) => {
            dispatch(updateReturnLoader(false))
            return Promise.reject(error);
        })
    }
}

export const updateReturnData = (products, calculatedData) => {
    return async (dispatch) => {
        await dispatch(updateRefundItems(products))
        await dispatch(updateRefundCalculated(calculatedData))
    }
}

export const updateReturnDiscountData = (discount, calculatedData) => {
    return async (dispatch) => {
        await dispatch(updateRefundDiscount(discount))
        await dispatch(updateRefundCalculated(calculatedData))
    }
}

export const updateSaleChangesData = (refundItems, priceItems) => {
    return async (dispatch) => {
        await dispatch(updateRefundQtyItems(refundItems))
        await dispatch(updatepriceQtyItems(priceItems))
    }
}


export const closeReturnPopup = () => {
    return (dispatch) => {
        dispatch(updateReturnPopup(false))
        dispatch(updateReturnLoader(false))
    }
}


export const returnBillLoader = (flag) => {
    return {
        type: RETURN_BILL_LOADER,
        payload: flag
    }
}

export const updateReturnPopup = (data) => {
    return {
        type: REFUND_POPUP,
        payload: data
    }
}

export const updateReturnLoader = (data) => {
    return {
        type: UPDATE_REFUND_LOADER,
        payload: data
    }
}

export const updateReturnBill = (billNumber) => {
    return {
        type: UPDATE_REFUND_BILL,
        payload: billNumber
    }
}

export const updateReturnOrderItems = (items) => {
    return {
        type: REFUND_ORDER_ITEMS,
        payload: items
    }
}

export const updateRefundItems = (data) => {
    return {
        type: UPDATE_REFUND_ORDER_ITEMS,
        payload: data
    }
}

export const updateReturnAmountData = (data) => {
    return {
        type: UPDATE_AMOUNT,
        payload: data
    }
}

export const updateRefundDiscount = (data) => {
    return {
        type: UPDATE_REFUND_DISCOUNT,
        payload: data
    }
}

export const updateRefundCalculated = (data) => {
    return {
        type: UPDATE_REFUND_CALCULATED,
        payload: data
    }
}

export const updateRefundQtyItems = (data) => {
    return {
        type: UPDATE_REFUND_QTY_ITEMS,
        payload: data
    }
}

export const updatepriceQtyItems = (data) => {
    return {
        type: UPDATE_PRICE_QTY_ITEMS,
        payload: data
    }
}