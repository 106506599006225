import api from "../network/api"

export const fetchPendingBills = (customerId) => {
    return api.get("/order/unpaid?customer="+customerId).then(response => {
        if (response) {
            return response.data
        }
    })
}

export const newTransaction = (data) => {
    return api.post("/transaction", data).then(response => {
        if (response) {
            return response.data
        }
    })
}
