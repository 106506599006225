import { combineReducers } from "redux";
import commonReducer from "./common/commonReducer";
import authReducer from "./auth/authReducer";
import billingReducer from "./billing/billingReducer";
import productReducer from "./product/productReducer";
import stockReducer from "./stock/stockReducer";
import purchaseReducer from "./purchase/purchaseReducer";
import tagReducer from "./tag/tagReducer";
import cashierReducer from "./cashier/cashierReducer";
import refundReducer from "./refund/refundReducer";
import dashboardReducer from "./dashboard/dashboardReducer";
import transactionReducer from "./transaction/transactionReducer";
import customerReducer from "./customer/customerReducer";
import soldItemReducer from "./soldItem/soldItemReducer";

const rootReducer = combineReducers({
    common: commonReducer,
    account: authReducer,
    product: productReducer,
    billing: billingReducer,
    stock: stockReducer,
    purchase: purchaseReducer,
    tag: tagReducer,
    cashier: cashierReducer,
    refund: refundReducer,
    dashboard: dashboardReducer,
    transaction: transactionReducer,
    customer:customerReducer,
    soldItems: soldItemReducer
})

export default rootReducer;