import { 
    CASHIER_POPUP, 
    UPDATE_CASHIER_LOADER, 
    ADD_CASHIER_CUSTOMER,
    ADD_CASHIER_UNPAID_LIST, 
    UPDATE_CASHIER_CHECKBOX,
    UPDATE_PAID_CHECKBOX,
    UPDATE_PAIDBY_CHECKBOX,
    UPDATE_PAIDBY,
    UPDATE_PAY_BILL,
    UPDATE_DESCRIPTION,
    UPDATE_CUSTOMER_PAY,
    UPDATE_TO_CUSTOMER_PAY,
    UPDATE_BALANCE_TO_CUSTOMER,
    UPDATE_CASHIER_CUSTOMER
} from "./cashierTypes";

const initialState = {
    isOpen: false,
    loading: false,
    customerId: null,
    customer: null,
    pendingList: [],
    customerPay: 0.00,
    paidCheck: true,
    paidByCheck: false,
    balanceToPay: 0.00,
    paidBy: 'CASH',
    description: ''
}

const cashierReducer = (state = initialState, action) => {

    const balancetoPay = ((state.customerPay*1) -  state.pendingList.reduce((oldBalance, b)  => {
        return ((oldBalance*1) + (b.paying*1))
    }, 0));

    switch(action.type) {
        
        case CASHIER_POPUP:
            return {
                ...state,
                isOpen: action.payload,
                customerId: null,
                customer: null,
                customerPay: 0.00,
                balanceToPay: 0.00,
                pendingList: [],
                paidCheck: true,
                paidByCheck: false
            }

        case UPDATE_CASHIER_LOADER:
            return {
                ...state,
                loading: action.payload,
            }

        case ADD_CASHIER_CUSTOMER:
            return {
                ...state,
                customer: action.payload,
            }  

        case ADD_CASHIER_UNPAID_LIST:
            return {
                ...state,
                pendingList: action.payload,
            }

        case UPDATE_CASHIER_CHECKBOX:
            return {
                ...state,
                pendingList: state.pendingList.map(b => {

                    if(parseInt(b.id) === parseInt(action.payload)) {
                        return {
                            ...b,
                            checked: !b.checked,
                            paying: (b.checked === true) ? 0.00 : b.amountToPay 
                        }
                    } else {
                        return b
                    }
                }),
            }

        case UPDATE_PAID_CHECKBOX:
            return {
                ...state,
                paidCheck: action.payload,
            }
        case UPDATE_PAIDBY_CHECKBOX:
            return {
                ...state,
                paidByCheck: action.payload,
            }
        case UPDATE_PAIDBY:
            return {
                ...state,
                paidBy: action.payload,
            }
        case UPDATE_DESCRIPTION:
            return {
                ...state,
                description: action.payload,
            }
        case UPDATE_PAY_BILL:
            return {
                ...state,
                pendingList: state.pendingList.map(b => {

                    if(parseInt(b.id) === parseInt(action.payload.bill)) {
                        return {
                            ...b,
                            checked: (parseFloat(action.payload.pay) < parseFloat(b.amountToPay)) ? false : true,
                            paying: (parseFloat(action.payload.pay) <= parseFloat(b.amountToPay)) ? action.payload.pay : b.amountToPay
                        }
                    } else {
                        return b
                    }
                }),
            }
            
        case UPDATE_CUSTOMER_PAY:
            return {
                ...state,
                customerPay: action.payload,
            }
            
        case UPDATE_TO_CUSTOMER_PAY:
            return {
                ...state,
                customerToPay: action.payload,
            }

        case UPDATE_BALANCE_TO_CUSTOMER:
            return {
                ...state,
                balanceToPay: (balancetoPay < 0 || balancetoPay === '') ? 0 : balancetoPay
            }


        case UPDATE_CASHIER_CUSTOMER:
            return {
                ...state,
                customerId: action.payload
            }
            
        default: return state;
    }
}

export default cashierReducer;