import React, { useRef, useEffect, useState } from 'react'
import Select from 'react-select';
import { findTag } from '../../service/tagService'
import { findPurchase } from '../../service/purchaseService';
import LoginComponent from './LoginComponent'
import DropZone from './include/DropZone'
import BillCustomer from './include/BillCustomer'
import Biller from './include/Biller'
import Stocker from './include/Stocker'
import Slider from './include/Slider'
import { Modal, Button } from 'react-bootstrap'
import moment from 'moment';

import { 
    updateCurrentProductMedia, 
    removeCurrentProduct,
    removeCurrentStock, 
    updateCurrentProduct,
    updateExistingEcom,
    updateCurrentStock,
    createNewProduct,
    updateExistingProduct,
    createNewStock,
    addStockToProduct,
    fetchProductsAndBillingTags
} from '../../redux'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import '../../css/pos.css'
import { S3_BUCKET_URL } from '../../config.js'

function BillingComponent({
        authData, 
        products,
        currentStock,
        currentProduct, 
        addCurrentProductMedia, 
        createProduct,
        updateEcom,
        updateProduct,
        createStock,
        addStock,
        updateCurrentProductData,
        updateCurrentStockData,
        unsetCurrentProduct,
        unsetCurrentStock,
        fetchProductsBillingTags
    }) {

    const selectRef = useRef();
    const purchaseSelectRef = useRef();

    const[details, setDetails] = useState({
        productModal: {
            tagInput: "",
            resultTags: [],
            purchaseInput: "",
            resultPurchases: [],
            isOpen: false,
            activeTab: '#product_tab',
            error: null
        },
    })

    const [sliderData, setSliderData] = useState(null);
    const handleSliderData = (data) => {
        setSliderData(data);
    };

    const onTagChange = (data) => {
        addTag(data)
    }
    const addTag = (tag) => {
        let tags = currentProduct.tags.filter(t => t.id !== tag.id)
        tags.push(tag)

        let updatedProduct = {
            ...currentProduct,
            tags: tags
        };
        updateCurrentProductData(updatedProduct)
    }

    let typingTimer, doneTypingInterval = 800;
    const onTagSearch = (input) => {
        if(input.length > 0) {
            clearTimeout(typingTimer);
            typingTimer = setTimeout(doneTyping, doneTypingInterval, input);
        }       
    }
    const doneTyping = (val) => {
        findTag({q: val}).then(data => {
            let output = (data && data.rows && data.rows.length > 0) ? data.rows.map( (data) => {
                return {
                    ...data,
                    value: data.id,
                    label: data.name,
                }
            }) : [];
            setDetails({
                ...details, 
                productModal: { 
                    ...details.productModal,
                    resultTags: output 
                }
            })
        })
    }

    const removeTag = (e) => {
        const tagId = parseInt(e.target.getAttribute('data-tagid'));
        let updatedProduct = {
            ...currentProduct,
            tags: currentProduct.tags.filter(tag => tag.id !== tagId )
        };
        updateCurrentProductData(updatedProduct)
    }
    

    const onPurchaseChange = (data) => {
        let updatedStock = {
            ...currentStock,
            purchase: {
                id: data.id,
                label: ((data.shopName && data.shopName !== '') ? data.shopName : '') +' '+ ((data.brandName && data.brandName !== '') ? data.brandName : '') +' '+ ((data.invoiceNumber && data.invoiceNumber !== '') ? '( '+data.invoiceNumber+' )' : '')
            }
        };
        updateCurrentStockData(updatedStock)
    }

    let purchaseTypingTimer, purchaseDoneTypingInterval = 800;
    const onPurchaseSearch = (input) => {
        if(input.length > 0) {
            clearTimeout(purchaseTypingTimer);
            purchaseTypingTimer = setTimeout(purchaseDoneTyping, purchaseDoneTypingInterval, input);
        }       
    }
    const purchaseDoneTyping = (val) => {
        findPurchase({q: val}).then(data => {
            let output = (data && data.rows && data.rows.length > 0) ? data.rows.map( (data) => {
                return {
                    ...data,
                    value: data.id,
                    label: ((data.shopName && data.shopName !== '') ? data.shopName : '') +' '+ ((data.brandName && data.brandName !== '') ? data.brandName : '') +' '+ ((data.invoiceNumber && data.invoiceNumber !== '') ? '( '+data.invoiceNumber+' )' : ''),
                }
            }) : [];
            
            setDetails({
                ...details, 
                productModal: { 
                    ...details.productModal,
                    resultPurchases: output 
                }
            })
        })
    }
    const removeCurrentPurchase = () => {

        let updatedStock = {
            ...currentStock,
            purchase: {
                id: 0,
                label: ""
            }
        };
        updateCurrentStockData(updatedStock)
    }



    const openModal = (data) => {
        setDetails({
            ...details, 
            productModal: { 
                ...details.productModal,
                isOpen: true,
                activeTab: '#product_tab',
                tagInput: "",
                resultTags: [],
                purchaseInput: "",
                resultPurchases: []
            }
        })

        if(data && data.productId && parseInt(data.productId) !== 0) {
            let product = products.find((product) => {
                return parseInt(product.id) === parseInt(data.productId)
            })
            updateCurrentProductData(product)

            let updatedStock = {
                ...currentStock,
                productId: product.id
            }
            updateCurrentStockData(updatedStock)
        }
    }


    const closeModal = () => {
        setDetails({
            ...details, 
            productModal: { 
                ...details.productModal,
                isOpen: false
            }
        });
        unsetCurrentProduct()
        unsetCurrentStock()
    }

    const fileUploaded = (data) => {
        const updatedList = currentProduct.media.concat(data);
        addCurrentProductMedia(updatedList);
    };

    const onFormUpdate = (e) => {
        e.preventDefault()
        let updatedProduct = {};

        switch(e.target.name){

            case 'name': {
                updatedProduct = {
                    ...currentProduct,
                    name: e.target.value
                };
                updateCurrentProductData(updatedProduct)
                break;
            }
      
            case 'hsn': {
                updatedProduct = {
                    ...currentProduct,
                    hsn: e.target.value
                };
                updateCurrentProductData(updatedProduct)
                break;
            }

            case 'barcode': {
                updatedProduct = {
                    ...currentProduct,
                    barcode: e.target.value
                };
                updateCurrentProductData(updatedProduct)
                break;
            }

            case 'localBarcode': {
                updatedProduct = {
                    ...currentProduct,
                    localBarcode: e.target.value
                };
                updateCurrentProductData(updatedProduct)
                break;
            }

            case 'gstPercentage': {
                updatedProduct = {
                    ...currentProduct,
                    gstPercentage: e.target.value
                };
                updateCurrentProductData(updatedProduct)
                break;
            }

            case 'sellingPrice': {
                updatedProduct = {
                    ...currentProduct,
                    sellingPrice: e.target.value
                };
                updateCurrentProductData(updatedProduct)
                break;
            }
            
            case 'msp': {
                updatedProduct = {
                    ...currentProduct,
                    msp: e.target.value
                };
                updateCurrentProductData(updatedProduct)
                break;
            }
            
            case 'mrp': {
                updatedProduct = {
                    ...currentProduct,
                    mrp: e.target.value
                };
                updateCurrentProductData(updatedProduct)
                break;
            }

            default: break;
        }
    };
    

    const onStockFormUpdate = (e) => {
        e.preventDefault()
        let updatedStock = {};

        switch(e.target.name){
            case 'basePrice': {
                let gstPrice    = (e.target.value * (currentStock.gstPercentage/100)).toFixed(2);
                let unitPrice   = ((e.target.value*1) + (gstPrice*1)).toFixed(2)
                let totalPrice  = (unitPrice * currentStock.stockQty).toFixed(2)
                
                updatedStock = {
                    ...currentStock,
                    basePrice: e.target.value,
                    gstPrice: gstPrice,
                    unitPrice: unitPrice,
                    totalPrice: totalPrice
                };
                updateCurrentStockData(updatedStock)
                break;
            }
            case 'gstPercentage': {
                let gstPrice    = (currentStock.basePrice * (e.target.value/100)).toFixed(2);
                let unitPrice   = ((currentStock.basePrice*1) + (gstPrice*1) ).toFixed(2)
                let totalPrice  = (unitPrice * currentStock.stockQty).toFixed(2)

                updatedStock = {
                    ...currentStock,
                    gstPercentage: e.target.value,
                    gstPrice: gstPrice,
                    unitPrice: unitPrice,
                    totalPrice: totalPrice
                };
                updateCurrentStockData(updatedStock)
                break;
            }
            case 'stockQty': {
                let gstPrice    = (currentStock.basePrice * (currentStock.gstPercentage/100)).toFixed(2);
                let unitPrice   = ((currentStock.basePrice*1) + (gstPrice*1)).toFixed(2)
                let totalPrice  = (unitPrice * e.target.value).toFixed(2)

                updatedStock = {
                    ...currentStock,
                    stockQty: e.target.value,
                    gstPrice: gstPrice,
                    unitPrice: unitPrice,
                    totalPrice: totalPrice
                };
                updateCurrentStockData(updatedStock)
                break;
            }
            case 'unitType': {
                updatedStock = {
                    ...currentStock,
                    unitType: e.target.value
                };
                updateCurrentStockData(updatedStock)
                break;
            }
            
            default: break;
        }
    }
    
    const onEcomFormUpdate = (e) => {
        e.preventDefault()
        let updatedEcom = {};

        switch(e.target.name){
            case 'amazon_asin': {
                updatedEcom = {
                    ...currentProduct,
                    ecom1_low: {
                        ...currentProduct.ecom1_low,
                        value: e.target.value
                    }
                };
                updateCurrentProductData(updatedEcom)
                break;
            }
        }
    }


    const tabChange = (e) => {
        if( (e.currentTarget.getAttribute("href")).indexOf('#product_tab') !== -1 ) {
            setDetails({
                ...details, 
                productModal: { 
                    ...details.productModal,
                    activeTab: '#product_tab' 
                }
            })
        }

        if( (e.currentTarget.getAttribute("href")).indexOf('#stock_tab') !== -1 ) {
            setDetails({
                ...details, 
                productModal: { 
                    ...details.productModal,
                    activeTab: '#stock_tab' 
                }
            })
        }

        if( (e.currentTarget.getAttribute("href")).indexOf('#ecom_tab') !== -1 ) {
            setDetails({
                ...details, 
                productModal: { 
                    ...details.productModal,
                    activeTab: '#ecom_tab' 
                }
            })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if(details.productModal.activeTab === '#ecom_tab') {
            updateEcom(currentProduct.id, currentProduct).then(data => {
                unsetCurrentProduct()
                setDetails({
                    ...details, 
                    productModal: { 
                        ...details.productModal,
                        isOpen: false 
                    }
                })
            }).catch(error => {
                if(error && error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
                    alert(error.response.data.errors[0].msg)
                } else {
                    alert('Something went wrong!')
                }
            })
        }

        if(details.productModal.activeTab === '#stock_tab') {
            if(currentStock && parseFloat(currentStock.basePrice) > 0 && parseInt(currentStock.stockQty) > 0 && currentStock.purchase && currentStock.purchase.id !== 0) {
                createStock(currentStock).then(response => {
                    addStock(response.data, currentProduct, products)
                    unsetCurrentStock()
                }).catch((err) => {

                })
            } else {
                alert('Fill all required stock fields!')
            }
        }

        if(details.productModal.activeTab === '#product_tab') {

            if(currentProduct.id === 0) {
                createProduct(currentProduct).then(productData => {
                    unsetCurrentProduct()
                    setDetails({
                        ...details, 
                        productModal: { 
                            ...details.productModal,
                            isOpen: false
                        }
                    })
                }).catch(error => {
                    if(error && error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
                        alert(error.response.data.errors[0].msg)
                    } else {
                        alert('Something went wrong!')
                    }
                })
            } else {
                updateProduct(currentProduct.id, currentProduct).then(data => {
                    setDetails({
                        ...details, 
                        productModal: { 
                            ...details.productModal,
                            isOpen: false 
                        }
                    })
                }).catch(error => {
                    if(error && error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
                        alert(error.response.data.errors[0].msg)
                    } else {
                        alert('Something went wrong!')
                    }
                })
            }
        }
  
    }
    


    const navigate = useNavigate() 
    useEffect(() => {
        if(!authData || !authData.auth || !authData.auth.id) {
            const goToLoginPage = () => navigate('/admin/login');
            goToLoginPage();
        }
        fetchProductsBillingTags('')
    }, [navigate, authData, fetchProductsBillingTags]);

    if(!authData || !authData.auth || !authData.auth.id) {
        return <LoginComponent></LoginComponent>
    }

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-md-7 mt-4">
                            <Stocker sliderData={sliderData} onOpenProductModal={openModal}/>
                            <Slider onDataUpdate={handleSliderData} />
                        </div>
                        <div className="col-12 col-md-5">
                            <div className="row">
                                <div className="col-12">
                                    <BillCustomer />
                                </div>
                                <div className="col-12">
                                    <Biller/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal dialogClassName="modal-xl" show={details.productModal.isOpen} onHide={closeModal} aria-labelledby="fullScreenModalLabel">
                <form className="form-horizontal" onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                    <Modal.Title>
                        {
                            (currentProduct && currentProduct.id) ? 
                                (details.productModal.activeTab === '#stock_tab') ? 'Update Stock' :
                                (details.productModal.activeTab === '#product_tab') ? 'Update Product' : 'Update Ecom' : 'Create Product'

                        }
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ul className="nav nav-tabs nav-bordered nav-justified">
                            <li className="nav-item">
                                <a href="#product_tab" onClick={tabChange} data-bs-toggle="tab" aria-expanded="false" className="nav-link active">
                                    <span className="d-inline-block d-sm-none"><i className="mdi mdi-home-variant"></i></span>
                                    <span className="d-none d-sm-inline-block">Product & Detail</span>
                                </a>
                            </li>
                            {
                                (currentProduct && currentProduct.id) ? <li className="nav-item">
                                    <a href="#stock_tab" onClick={tabChange} data-bs-toggle="tab" aria-expanded="true" className="nav-link">
                                        <span className="d-inline-block d-sm-none"><i className="mdi mdi-account"></i></span>
                                        <span className="d-none d-sm-inline-block">Stocks</span>
                                    </a>
                                </li> : ''
                            }
                            {
                                (currentProduct && currentProduct.id) ? <li className="nav-item">
                                    <a href="#ecom_tab" onClick={tabChange} data-bs-toggle="tab" aria-expanded="true" className="nav-link">
                                        <span className="d-inline-block d-sm-none"><i className="mdi mdi-account"></i></span>
                                        <span className="d-none d-sm-inline-block">Ecom Update</span>
                                    </a>
                                </li> : ''
                            }
                        </ul>

                        <div className="tab-content">
                            <div className="tab-pane active" id="product_tab">
                                <div className='row'>
                                    <div className='col-12 col-lg-8'>
                                        <div className='row'>
                                            <div className='col-12 col-md-6'>
                                                <div className="mb-2">
                                                    <label className="form-label">Product Name</label>
                                                    <input type="text" name="name" onChange={onFormUpdate} className="form-control" value={currentProduct.name} />
                                                </div>
                                            </div>
                                            <div className='col-12 col-md-6'>
                                                <div className="mb-2">
                                                    <label className="form-label">Barcode</label>
                                                    <input type="text" name="barcode" onChange={onFormUpdate} className="form-control" value={currentProduct.barcode} />
                                                </div>
                                            </div>
                                            <div className='col-12 col-md-6'>
                                                <div className="mb-2">
                                                    <label className="form-label">Local Barcode</label>
                                                    <input type="text" name="localBarcode" onChange={onFormUpdate} className="form-control" value={currentProduct.localBarcode} />
                                                </div>
                                            </div>
                                            <div className='col-12 col-md-6'>
                                                <div className="mb-2">
                                                    <label className="form-label">HSN</label>
                                                    <input type="text" name="hsn" onChange={onFormUpdate} className="form-control" value={currentProduct.hsn} />
                                                </div>
                                            </div>
                                            <div className='col-12 col-md-6'>
                                                <div className="mb-2">
                                                    <label className="form-label">GST Percentage</label>
                                                    <input type="number" name="gstPercentage" onChange={onFormUpdate} className="form-control" value={currentProduct.gstPercentage} />
                                                </div>
                                            </div>
                                            <div className='col-12 col-md-6'>
                                                <div className="mb-2">
                                                    <label className="form-label">Selling Price</label>
                                                    <input type="number" name="sellingPrice" onChange={onFormUpdate} className="form-control" value={currentProduct.sellingPrice} />
                                                </div>
                                            </div>
                                            <div className='col-12 col-md-6'>
                                                <div className="mb-2">
                                                    <label className="form-label">MSP</label>
                                                    <input type="number" name="msp" onChange={onFormUpdate} className="form-control" value={currentProduct.msp} />
                                                </div>
                                            </div>
                                            <div className='col-12 col-md-6'>
                                                <div className="mb-2">
                                                    <label className="form-label">MRP</label>
                                                    <input type="number" name="mrp" onChange={onFormUpdate} className="form-control" value={currentProduct.mrp} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12 col-md-6'>
                                                <div className="mb-2">
                                                    <label className="form-label">Select Tag</label>
                                                    <Select
                                                        ref={selectRef}
                                                        id="product-select"
                                                        isSearchable
                                                        onChange={onTagChange}
                                                        onInputChange={onTagSearch}
                                                        options={details.productModal.resultTags}
                                                        value={details.productModal.tagInput}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-12 col-md-6'>
                                                <div className="mb-2">
                                                    <label className="form-label">Selected Tags</label>
                                                    <div className='tag-data'>
                                                    {
                                                        (currentProduct && currentProduct.tags && currentProduct.tags.length > 0) ? currentProduct.tags.map(tag => {
                                                            return <span key={tag.id} className="badge bg-primary text-light tag-list">{tag.name}<span className="tag-close" data-tagid={tag.id} onClick={removeTag}>X</span></span>
                                                        }) : null
                                                    }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-2 row">
                                        {
                                            currentProduct.media.map((data, id) => {
                                                return <div className='col-4' key={id}>
                                                    <input type="hidden" name="upload_id[]" value={data.uploadId} />
                                                    <img className="img-fluid" alt="" src={S3_BUCKET_URL+data.key} />
                                                </div>
                                            })
                                        }
                                        </div>
                                    </div>
                                    <div className='col-12 col-lg-4'>
                                        <div>
                                            <div className="dropzone-content">
                                                <DropZone onUpload={fileUploaded} module="product"/>
                                            </div>
                                        </div>
                                    </div>
                            
                                </div>
                            </div>
                            {
                                (currentProduct && currentProduct.id) ? <div className="tab-pane" id="stock_tab">
                                    <div className="row">
                                        <div className="col-12 col-md-5">
                                            <div className="row">
                                                <div className="col-12 col-md-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">Base Price (Rs)</label>
                                                        <input type="text" className="form-control" name="basePrice" value={currentStock.basePrice} onChange={onStockFormUpdate}/>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">GST (%)</label>
                                                        <input type="text" className="form-control" name="gstPercentage" value={currentStock.gstPercentage} onChange={onStockFormUpdate}/>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Total Qty</label>
                                                        <input type="text" className="form-control" name="stockQty" value={currentStock.stockQty} onChange={onStockFormUpdate}/>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Unit Type</label>
                                                        <select className="form-control" name="unitType" value={currentStock.unitType} onChange={onStockFormUpdate}>
                                                            <option value="Pcs">Pcs</option>
                                                            <option value="Pair">Pair</option>
                                                            <option value="Tubes">Tubes</option>
                                                            <option value="Can">Can</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">GST (Rs)</label>
                                                        <input type="text" readOnly className="form-control" name="gstPrice" value={currentStock.gstPrice} />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Per Unit (GST Inclusive Rs)</label>
                                                        <input type="text" readOnly className="form-control" name="unitPrice" value={currentStock.unitPrice} />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Buying Total (Rs)</label>
                                                        <input type="text" readOnly className="form-control" name="totalPrice" value={currentStock.totalPrice} />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-3">
                                                </div>
                                                <div className="col-12">
                                                    <div className="mb-3">
                                                        <label className="form-label">Purchase Bill</label>
                                                        {
                                                            (() => {
                                                                if(currentStock && currentStock.purchase && currentStock.purchase.id && currentStock.purchase.id !== 0) {
                                                                    return <div className="input-group">
                                                                        <input type="text" value={currentStock.purchase.label} readOnly className='form-control'/>
                                                                        <button className="btn btn-primary waves-effect waves-light" type="button" onClick={removeCurrentPurchase}>
                                                                            <i className='fa fa-times'></i>
                                                                        </button>
                                                                    </div>
                                                                } else {
                                                                    return <Select
                                                                        ref={purchaseSelectRef}
                                                                        id="purchase-select"
                                                                        isSearchable
                                                                        onChange={onPurchaseChange}
                                                                        onInputChange={onPurchaseSearch}
                                                                        options={details.productModal.resultPurchases}
                                                                        value={details.productModal.purchaseInput}
                                                                    />
                                                                }
                                                            })()
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-7">

                                            <table className="table mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th className="item-trash">#</th>
                                                        <th>Invoice</th>
                                                        <th>Total Quantity</th>
                                                        <th>Balance Quantity</th>
                                                        <th>Stock Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    currentProduct.stocks.map((data, id) => {
                                                        return <tr key={id}>
                                                            <td>{id+1}</td>
                                                            <td>{ ((data.purchase && data.purchase.shopName && data.purchase.shopName !== '') ? data.purchase.shopName : '') +' '+ ((data.purchase && data.purchase.brandName && data.purchase.brandName !== '') ? data.purchase.brandName : '') +' '+ ((data.purchase && data.purchase.invoiceNumber && data.purchase.invoiceNumber !== '') ? '( '+data.purchase.invoiceNumber+' )' : '') }</td>
                                                            <td>{data.stockQty}</td>
                                                            <td>{data.balanceQty}</td>
                                                            <td>{moment(data.createdAt).format('MMMM Do YYYY')}</td>
                                                        </tr>
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div> : ''
                            }

                            {
                                (currentProduct && currentProduct.id) ? <div className="tab-pane" id="ecom_tab">
                                    <div className="row">
                                        <div className="col-12 col-md-5">
                                            <div className="row">
                                                <div className="col-12 col-md-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">Amazon ASIN</label>
                                                        <input type="text" className="form-control" name="amazon_asin" onChange={onEcomFormUpdate} value={(currentProduct.ecom1_low ? currentProduct.ecom1_low.value : '')}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-7">

                                            <table className="table mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th className="item-trash">#</th>
                                                        <th>Invoice</th>
                                                        <th>Total Quantity</th>
                                                        <th>Balance Quantity</th>
                                                        <th>Stock Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div> : ''
                            }

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button type="submit" className="btn btn-primary">
                    {
                        (currentProduct && currentProduct.id) ? 
                            (details.productModal.activeTab === '#stock_tab' ? 'Update Stock' : 
                            details.productModal.activeTab === '#ecom_tab' ? 'Update Ecom' : 'Update Product') :
                            'Create Product'
                    }
                    </Button>
                    <Button variant="secondary" onClick={closeModal}>
                        Close
                    </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </div>
    )
  
}

const mapStateToProps = (state) => {
    return {
        authData: state.account,
        products: state.product.products,
        stock: state.stock,
        currentStock: state.stock.currentStock,
        currentProduct: state.product.currentProduct
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        updateCurrentProductData: (product) => {
            return dispatch( updateCurrentProduct(product) )
        },
        updateCurrentStockData: (stock) => {
            return dispatch( updateCurrentStock(stock) )
        },
        addCurrentProductMedia: (media) => {
            return dispatch( updateCurrentProductMedia(media) )
        },
        createProduct: (product) => {
            return dispatch( createNewProduct(product) )
        },
        updateProduct: (id, product) => {
            return dispatch( updateExistingProduct(id, product) )
        },
        updateEcom: (id, product) => {
            return dispatch( updateExistingEcom(id, product) )
        },
        createStock: (stock) => {
            return dispatch( createNewStock(stock) )
        },
        addStock: (stock, currentProduct, products) => {
            return dispatch(addStockToProduct(stock, currentProduct, products))
        },
        unsetCurrentProduct: () => {
            return dispatch( removeCurrentProduct() )
        },
        unsetCurrentStock: () => {
            return dispatch( removeCurrentStock() )
        }, 
        fetchProductsBillingTags: (query) => {
            dispatch( fetchProductsAndBillingTags(query) )
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingComponent)