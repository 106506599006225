export const CASHIER_POPUP =  "CASHIER_POPUP";
export const UPDATE_CASHIER_LOADER =  "UPDATE_CASHIER_LOADER";
export const ADD_CASHIER_CUSTOMER =  "ADD_CASHIER_CUSTOMER";
export const ADD_CASHIER_UNPAID_LIST =  "ADD_CASHIER_UNPAID_LIST";
export const UPDATE_CASHIER_CHECKBOX =  "UPDATE_CASHIER_CHECKBOX";
export const UPDATE_PAID_CHECKBOX =  "UPDATE_PAID_CHECKBOX";
export const UPDATE_PAIDBY_CHECKBOX =  "UPDATE_PAIDBY_CHECKBOX";
export const UPDATE_PAIDBY =  "UPDATE_PAIDBY";
export const UPDATE_PAY_BILL =  "UPDATE_PAY_BILL";
export const UPDATE_DESCRIPTION =  "UPDATE_DESCRIPTION";
export const UPDATE_CUSTOMER_PAY =  "UPDATE_CUSTOMER_PAY";
export const UPDATE_TO_CUSTOMER_PAY =  "UPDATE_TO_CUSTOMER_PAY";
export const UPDATE_BALANCE_TO_CUSTOMER =  "UPDATE_BALANCE_TO_CUSTOMER";
export const UPDATE_CASHIER_CUSTOMER =  "UPDATE_CASHIER_CUSTOMER";