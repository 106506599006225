import { BILLING_PRODUCT, UPDATE_QTY, UPDATE_DISCOUNT, REMOVE_BILLING_PRODUCT, UPDATE_BILLING_STOCK, BILLING_CUSTOMER, BILL_LIST, BILL_DETAIL, CLEAR_BILLER } from "./billingTypes";
import { TABLE_LOADER } from "../common/commonTypes";

import { createCustomer, createBill, fetchBills, fetchBill, deleteCustomerBill } from "../../service/billingService";
export const updatedBillingStock = (billingProducts, billingData, key) => {

    let updatedRow = billingProducts.find(row => {
        return parseInt(row.idKey) === parseInt(key)
    })

    let neededQty = billingProducts.reduce((totalQty, qtyProduct) => {
        return (parseInt(qtyProduct.id) === parseInt(updatedRow.id)) ? ( (totalQty*1) + (qtyProduct.qty*1) ) : totalQty
    }, 0)

    const oldBillingStocks = billingData.billingStock
    let currentStock = oldBillingStocks.find((loopProduct) => {
        return parseInt(loopProduct.id) === parseInt(updatedRow.id)
    })

    let availableQty = currentStock.stocks.reduce((maxQty, stock) => {
        return ((stock.balanceQty*1) + (maxQty*1))
    }, 0)

    if(availableQty < neededQty) {
        return false;
    }

    currentStock.stocks.forEach((stock) => {
        stock.usedQty = 0;

        if(neededQty > 0 && stock.balanceQty >= neededQty) {
            stock.usedQty = neededQty;
            neededQty = 0;
        }

        if(neededQty > 0 && stock.balanceQty < neededQty) {
            stock.usedQty = stock.balanceQty;
            neededQty = (neededQty*1) - (stock.balanceQty*1);
        }
    })
    
    let newBillingStocks = oldBillingStocks.map((product) => {
        if(parseInt(product.id) === parseInt(updatedRow.id)) {
            return {
                ...product,
                stocks: currentStock.stocks
            }
        } else {
            return product
        }
    })

    return newBillingStocks
}

export const removeBillingStock = (billingData, key) => {

    const product = billingData.products.find(product => {
        return parseInt(product.idKey) === parseInt(key)
    })

    let neededQty = (billingData.products.reduce( (totalQty, qtyProduct) => {
        return (parseInt(qtyProduct.id) === parseInt(product.id)) ? ((totalQty*1) + (qtyProduct.qty*1)) : totalQty
    }, 0)*1) - (product.qty*1)


    const oldBillingStocks = billingData.billingStock

    if(neededQty <= 0) {
        return oldBillingStocks.filter((filteredProduct) => {
            if(parseInt(filteredProduct.id) !== parseInt(product.id)) {
                return true
            }
            //update here
            return false;
        })
    }

    let currentStock = oldBillingStocks.find((loopProduct) => {
        return parseInt(loopProduct.id) === parseInt(product.id)
    })

    currentStock.stocks.forEach((stock) => {
        stock.usedQty = 0;

        if(neededQty > 0 && stock.balanceQty >= neededQty) {
            stock.usedQty = neededQty;
            neededQty = 0;
        }

        if(neededQty > 0 && stock.balanceQty < neededQty) {
            stock.usedQty = stock.balanceQty;
            neededQty = (neededQty*1) - (stock.balanceQty*1);
        }
    })

    let newBillingStocks = oldBillingStocks.map((mappedProduct) => {
        if(parseInt(mappedProduct.id) === parseInt(product.id)) {
            return {
                ...mappedProduct,
                stocks: currentStock.stocks
            }
        } else {
            return mappedProduct
        }
    })

    return newBillingStocks
}

export const addProductToBill = (product, billingData) => {
    return (dispatch) => {

        //No Stock available 
        if(!product || product.stocks.length <= 0) {
            return false;
        }

        let neededQty = ((billingData.products.reduce((totalQty, qtyProduct) => {
            return (parseInt(product.id) === parseInt(qtyProduct.id)) ? ( (totalQty*1) + (qtyProduct.qty*1) ) : totalQty;
        }, 0) )*1) + (1*1)

        let oldBillingStocks = billingData.billingStock
        let newStock = oldBillingStocks.find((loopProduct) => {
            return parseInt(loopProduct.id) === parseInt(product.id)
        })

        let currentStock = (typeof newStock === 'undefined') ? {id: product.id, stocks: product.stocks} : newStock
        //let newBillingStocks = (typeof newStock === 'undefined') ? oldBillingStocks.concat({id: product.id, stocks: product.stocks}) : oldBillingStocks;

        let availableQty = currentStock.stocks.reduce((maxQty, stock) => {
            return ((stock.balanceQty*1) + (maxQty*1))
        }, 0)
        
        if(availableQty < neededQty) {
            return false;
        }

        currentStock.stocks.forEach((stock) => {
            stock.usedQty = 0;

            if(neededQty > 0 && stock.balanceQty >= neededQty) {
                stock.usedQty = neededQty;
                neededQty = 0;
            }

            if(neededQty > 0 && stock.balanceQty < neededQty) {
                stock.usedQty = stock.balanceQty;
                neededQty = (neededQty*1) - (stock.balanceQty*1);
            }
        })

        let newBillingStocks = (typeof newStock === 'undefined') ? oldBillingStocks.concat(currentStock) : oldBillingStocks.map(stock => {
            return parseInt(currentStock.id) === parseInt(stock.id) ? newStock : stock;
        });


        dispatch(addProduct(product))
        dispatch(updateBillingStock(newBillingStocks))
    }
}

export const updateProductQty = (products, billingData, key) => {
    return (dispatch) => {

        let updatedStock = updatedBillingStock(products, billingData, key)
        if(updatedStock) {
            dispatch(updateProduct(products))
            dispatch(updateBillingStock(updatedStock))
        }
    }
}

export const removeBillingProduct = (sortId, billingData) => {
    return (dispatch) => {
        let updatedStock = removeBillingStock(billingData, sortId)
        if(updatedStock) {
            dispatch(removeProduct(sortId))
            dispatch(updateBillingStock(updatedStock))
        }
    }
}

export const updateDiscountToBill = (discount) => {
    return (dispatch) => {
        dispatch(updateDiscount(discount ? discount : 0))
    }
}


//Customer Related billing things
export const addCustomerToBill = (customer) => {
    return (dispatch) => {
        dispatch(addCustomer(customer))
    }
}

export const createBillingCustomer = (customerData) => {
    return (dispatch) => {
        return createCustomer(customerData).then( (data) => {
            dispatch(addCustomer(data))
            return Promise.resolve(data);
        }).catch( (error) => {
            console.log('customer create error')
            return Promise.reject(error);
        })
    }
}

export const removeBillCustomer = () => {
    return (dispatch) => {
        dispatch(removeBillingCustomer())
    }
}

export const createCustomerBill = (bill) => {
    return (dispatch) => {
        return createBill(bill).then( (data) => {
            dispatch({ type: CLEAR_BILLER, payload: true})
            
            return Promise.resolve(data);
        }).catch( (error) => {
            return Promise.reject(error);
        })
    }
}


export const updateDeleteBill = (billNumber) => {
    return (dispatch) => {
        return deleteCustomerBill(billNumber).then( (data) => {
            //dispatch(lockBill(id))
            return Promise.resolve(true);
        }).catch( (error) => {
            return Promise.reject(error);
        })
    }
}

export const getBills = (query) => {
    return (dispatch) => {
        dispatch({ type: BILL_LIST, payload: null });
        dispatch({ type: TABLE_LOADER, payload: false });

        fetchBills(query).then( (data) => {
            dispatch(fetchBillList(data))

            dispatch({ type: TABLE_LOADER, payload: true });
        }).catch( (error) => {
            console.log('Bills fetching error')
        })
    }
}


export const getBillDetail = (billNumber) => {
    return (dispatch) => {
        fetchBill(billNumber).then( (data) => {
            dispatch(fetchBillDetail(data))
        }).catch( (error) => {
            console.log('Bill fetching error')
        })
    }
}




export const updateBillingStock = (stocks) => {
    return {
        type: UPDATE_BILLING_STOCK,
        payload: stocks
    }
}


export const addProduct = (product) => {
    return {
        type: BILLING_PRODUCT,
        payload: product
    }
}

export const updateProduct = (data) => {
    return {
        type: UPDATE_QTY,
        payload: data
    }
}

export const updateDiscount = (discount) => {
    return {
        type: UPDATE_DISCOUNT,
        payload: discount
    }
}

export const removeProduct = (sortId) => {
    return {
        type: REMOVE_BILLING_PRODUCT,
        payload: sortId
    }
}

export const addCustomer = (customer) => {
    return {
        type: BILLING_CUSTOMER,
        payload: customer
    }
}

export const removeBillingCustomer = () => {
    return {
        type: BILLING_CUSTOMER,
        payload: null
    }
}

export const fetchBillList = (billList) => {
    return {
        type: BILL_LIST,
        payload: billList
    }
}

export const fetchBillDetail = (bill) => {
    return {
        type: BILL_DETAIL,
        payload: bill
    }
}