import React, { useEffect } from 'react'
import LoginComponent from './LoginComponent'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
    getTags
} from '../../redux'



function TagListComponent({
        authData,
        fetchTags,
        tagList
    }) {
    const navigate = useNavigate() 

    useEffect(() => {
        fetchTags()
        if(!authData || !authData.auth || !authData.auth.id) {
            const goToLoginPage = () => navigate('/admin/login');
            goToLoginPage();
        }
    }, [navigate, authData, fetchTags]);

    if(!authData || !authData.auth || !authData.auth.id) {
        return <LoginComponent></LoginComponent>
    }

    return (
        <>
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mt-4">
                                <div className="card-header">
                                    <ul className="nav nav-tabs align-items-end card-header-tabs w-100">
                                        <li className="nav-item">
                                            <a className="nav-link" href="/admin/tags/create"><i className="fas fa-plus-circle mx-2"></i>New Tag</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link active" href="/admin/tags"><i className="fas fa-th-list mx-2"></i>Tags</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-body">
                                    <table className="table dataTable no-footer">
                                        <thead>
                                            <tr role="row">
                                                <th>Tag Name</th> 
                                                <th>Display on bill</th> 
                                                <th>Sort Order</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            ( tagList && tagList.list && tagList.list.rows) ? tagList.list.rows.map((data, id) => {
                                                return <tr key={id}>
                                                    <td>{(data.name && data.name !== '') ? data.name : '-'}</td>
                                                    <td>{(data.displayBill) ? 'On' : 'Off'}</td>
                                                    <td>{data.sortOrder}</td>
                                                </tr>
                                            }) : null
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        authData: state.account,
        tagList: state.tag
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchTags: (query) => {
            dispatch( getTags(query) )
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TagListComponent)