import api from "../network/api" 

export const findProduct = (searchData) => {
    return api.get("/product?active=1&name="+searchData.q).then(response => {
        if (response) {
            return response.data.data
        }
    })
}
export const findProductByBarcode = (searchData) => {
    return api.get("/product/barcode?code="+searchData.barcode).then(response => {
        if (response) {
            return response.data.data
        }
    })
}

export const findBillingCustomer = (searchData) => {
    return api.get("/customer?name="+searchData.q).then(response => {
        if (response) {
            return response.data.data
        }
    })
}

export const createCustomer = (customerData) => {
    return api.post("/customer", customerData).then(response => {
        if (response) {
            return response.data.data
        }
    })
}

export const createBill = (billData) => {
    return api.post("/order", billData).then(response => {
        if (response) {
            return response.data.data
        }
    })
}

export const fetchBills = (query) => {
    return api.get("/order", { params: query }).then(response => {
        if (response) {
            return response.data.data
        }
    })
}

export const fetchBill = (billNumber) => {
    return api.get("/order/"+billNumber).then(response => {
        if (response) {
            return response.data.data
        }
    })
}

export const deleteCustomerBill = (billNumber) => {
    return api.delete("/order/"+billNumber).then(response => {
        if (response) {
            return response.data.data
        }
    })
}

export const updateBill = (billNumber, billData) => {
    return api.put("/order/"+billNumber, billData).then(response => {
        if (response) {
            return response.data
        }
    })
}