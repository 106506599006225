export const REFUND_POPUP =  "REFUND_POPUP";
export const UPDATE_REFUND_LOADER =  "UPDATE_REFUND_LOADER";
export const UPDATE_REFUND_BILL =  "UPDATE_REFUND_BILL";
export const REFUND_ORDER_ITEMS =  "REFUND_ORDER_ITEMS";
export const UPDATE_REFUND_ORDER_ITEMS =  "UPDATE_REFUND_ORDER_ITEMS";
export const UPDATE_AMOUNT =  "UPDATE_AMOUNT";
export const UPDATE_REFUND_DISCOUNT =  "UPDATE_REFUND_DISCOUNT";
export const UPDATE_REFUND_CALCULATED =  "UPDATE_REFUND_CALCULATED";
export const UPDATE_REFUND_QTY_ITEMS =  "UPDATE_REFUND_QTY_ITEMS";
export const UPDATE_PRICE_QTY_ITEMS =  "UPDATE_PRICE_QTY_ITEMS";
export const RETURN_BILL_LOADER =  "RETURN_BILL_LOADER";