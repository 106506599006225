import React, { useEffect, useState } from 'react'
import LoginComponent from './LoginComponent'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getPurchaseBillDetail, updateItem } from '../../redux';
//import moment from 'moment';

function PurchaseDetailComponent({
    authData,
    bill,
    fetchPurchaseBill,
    updateExistingItem
}) {

    const params = useParams();
    const navigate = useNavigate() 

    const[details, setDetails] = useState({
        billItemId: null,
        billItem: null
    })
    
    useEffect(() => {
        fetchPurchaseBill(params.billId)
        if(!authData || !authData.auth || !authData.auth.id) {
            const goToLoginPage = () => navigate('/admin/login');
            goToLoginPage();
        }
    }, [navigate, params, authData, fetchPurchaseBill]);

    if(!authData || !authData.auth || !authData.auth.id) {
        return <LoginComponent></LoginComponent>
    }

    const editBillItem = (e) => {
       e.preventDefault()

        const itemId = parseInt(e.target.getAttribute('data-itemid'));
        let editedBill = bill.detail.stocks.find(b => {
            return parseInt(itemId) === b.id
        })

        if(editedBill) {
            setDetails({
                ...details,
                billItemId: parseInt(itemId),
                billItem: {
                    stockQty: editedBill.stockQty,
                    basePrice: editedBill.basePrice,
                    gstPercentage: editedBill.gstPercentage
                }
            })
        }

        console.log(details.billItemId)
    }


    const changeBillItem = (e) => {
        e.preventDefault()

        switch(e.target.name){
            case 'stockQty': {
                setDetails({
                    ...details,
                    billItem: {
                        ...details.billItem,
                        stockQty: e.target.value
                    }
                })
                break;
            }
            case 'basePrice': {
                setDetails({
                    ...details,
                    billItem: {
                        ...details.billItem,
                        basePrice: e.target.value
                    }
                })
                break;
            }
            case 'gstPercentage': {
                setDetails({
                    ...details,
                    billItem: {
                        ...details.billItem,
                        gstPercentage: e.target.value
                    }
                })
                break;
            }
            default: break;
        }
    }
    
    const updateBillItem = (e) => {
        e.preventDefault()
        updateExistingItem(details).then( data => {
            setDetails({
                ...details,
                billItemId: null,
                billItem: null
            })
        })
    }
    



    return (
        <>
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row mt-3">
                                        <div className="col-sm-4">
                                            <h5>Billing Address</h5>
                                            <div>
                                                {(bill && bill.detail) ? bill.detail.brandName : '-'}<br />
                                                {(bill && bill.detail) ? bill.detail.shopName : '-'}<br />
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="auth-logo">
                                                <a href="!#" className="logo logo-dark">
                                                    <span className="logo-lg">
                                                        <img src="/assets/images/logo-dark.png" alt="" />
                                                    </span>
                                                </a>
                        
                                                <a href="!#" className="logo logo-light">
                                                    <span className="logo-lg">
                                                        <img src="/assets/images/logo-light.png" alt="" />
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div>
                                                <div className="float-end">
                                                    <p className="m-b-10"><strong>Invoice Id : {(bill && bill.detail) ? bill.detail.invoiceNumber: '-'}</strong></p>
                                                    <p className="m-b-10"><strong>Invoice Date : {(bill && bill.detail) ? bill.detail.invoiceDate : '-'}</strong></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12">
                                            <div className="table-responsive">
                                                <table className="table mt-4 table-centered">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Item</th>
                                                            <th>Qty</th>
                                                            <th>Type</th>
                                                            <th>Unit Price</th>
                                                            <th>Taxable Amount</th>
                                                            <th>GST %</th>
                                                            <th className="text-end">Total Amount</th>
                                                            {
                                                                (bill && bill.detail && bill.detail.billOpen) ? <th className="d-print-none">Action</th> : null
                                                            }
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        (bill.detail && bill.detail.stocks && bill.detail.stocks.length > 0) ? bill.detail.stocks.map( (item, id) => {
                                                            return (() => {
                                                                if(details.billItemId && details.billItemId === item.id) {
                                                                    return <tr key={id}>
                                                                        <td>#{id+1}</td>
                                                                        <td>
                                                                            <b>{item.product.name}</b>
                                                                        </td>
                                                                        <td>
                                                                            <input type="text" className="form-control editing-input" value={details.billItem.stockQty} name="stockQty" onChange={changeBillItem}/>
                                                                        </td>
                                                                        <td>{item.unitType}</td>
                                                                        <td>
                                                                            <input type="text" className="form-control editing-input" value={details.billItem.basePrice} name="basePrice" onChange={changeBillItem}/>
                                                                        </td>
                                                                        <td>Rs. {details.billItem.basePrice * details.billItem.stockQty}</td>
                                                                        <td>
                                                                            <input type="text" className="form-control editing-input" value={details.billItem.gstPercentage} name="gstPercentage" onChange={changeBillItem}/>
                                                                        </td>
                                                                        <td className="text-end">Rs. {
                                                                            ((((details.billItem.basePrice * (details.billItem.gstPercentage/100)).toFixed(2) *1) + (details.billItem.basePrice*1)) * details.billItem.stockQty).toFixed(2)
                                                                        }</td>
                                                                        {
                                                                            (bill && bill.detail && bill.detail.billOpen) ? <td className="d-print-none">
                                                                            <button className="btn btn-success waves-effect waves-light" onClick={updateBillItem}>
                                                                                    Update
                                                                                </button>
                                                                            </td> : null
                                                                        }
                                                                    </tr>
                                                                } else {
                                                                    return <tr key={id}>
                                                                        <td>#{id+1}</td>
                                                                        <td>
                                                                            <b>{item.product.name}</b>
                                                                        </td>
                                                                        <td>
                                                                            {item.stockQty}
                                                                        </td>
                                                                        <td>{item.unitType}</td>
                                                                        <td>Rs. {item.basePrice}</td>
                                                                        <td>Rs. {item.basePrice * item.stockQty}</td>
                                                                        <td>{item.gstPercentage}</td>
                                                                        <td className="text-end">Rs. {item.totalPrice}</td>
                                                                        {
                                                                            (bill && bill.detail && bill.detail.billOpen) ? <td className="d-print-none">
                                                                                <button className="btn btn-primary waves-effect waves-light" data-itemid={item.id} onClick={editBillItem}>
                                                                                    Edit
                                                                                </button>
                                                                            </td> : null
                                                                        }
                                                                    </tr>
                                                                }
                                                            })()
                                                        }) : null
                                                    }
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>
                                                            Rs. {
                                                                    (bill.detail && bill.detail.stocks && bill.detail.stocks.length > 0) ? bill.detail.stocks.reduce( (previousTabable, item) => {
                                                                        return previousTabable*1 + (item.basePrice * item.stockQty)
                                                                    }, 0).toFixed(2) : 0
                                                                }
                                                            </td>
                                                            <td></td>
                                                            <td className="text-end">
                                                            Rs. {
                                                                    (bill.detail && bill.detail.stocks && bill.detail.stocks.length > 0) ? bill.detail.stocks.reduce( (previousTotal, item) => {
                                                                        return previousTotal*1 + item.totalPrice*1
                                                                    }, 0).toFixed(2) : 0
                                                                }
                                                            </td>
                                                            <td className="d-print-none">
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="clearfix pt-5">

                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="float-end px-2">
                                                <p>Sub-total:</p>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        authData: state.account,
        bill: state.purchase
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchPurchaseBill: (billNumber) => {
            dispatch(getPurchaseBillDetail(billNumber))
        },
        updateExistingItem: (billItem) => {
            return dispatch(updateItem(billItem))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseDetailComponent)