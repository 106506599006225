import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect } from 'react'
import DatePicker from "react-datepicker";
import LoginComponent from './LoginComponent'
//import moment from 'moment';
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    updateCurrentPurchase,
    createPurchase
} from '../../redux'

function CreatePurchaseComponent({
        authData,
        currentPurchase,
        updateCurrentPurchaseData,
        newPurchase
    }) {
    const navigate = useNavigate() 

    // const[details, setDetails] = useState({
    //     invoiceDate: new Date()
    // })

    useEffect(() => {
        if(!authData || !authData.auth || !authData.auth.id) {
            const goToLoginPage = () => navigate('/admin/login');
            goToLoginPage();
        }
    }, [navigate, authData]);

    if(!authData || !authData.auth || !authData.auth.id) {
        return <LoginComponent></LoginComponent>
    }

    const onInvoiceDateUpdate = (date) => {
        let updatedPurchase = {
            ...currentPurchase,
            invoice_date: date
        };
        updateCurrentPurchaseData(updatedPurchase)
    }

    const onFormUpdate = (e) => {
        e.preventDefault()
        let updatedPurchase = {};

        switch(e.target.name){

            case 'invoice_number': {
                updatedPurchase = {
                    ...currentPurchase,
                    invoice_number: e.target.value
                };
                updateCurrentPurchaseData(updatedPurchase)
                break;
            }
            
            case 'shop_name': {
                updatedPurchase = {
                    ...currentPurchase,
                    shop_name: e.target.value
                };
                updateCurrentPurchaseData(updatedPurchase)
                break;
            }

            case 'brand_name': {
                updatedPurchase = {
                    ...currentPurchase,
                    brand_name: e.target.value
                };
                updateCurrentPurchaseData(updatedPurchase)
                break;
            }

            case 'sub_total': {
                updatedPurchase = {
                    ...currentPurchase,
                    sub_total: e.target.value
                };
                updateCurrentPurchaseData(updatedPurchase)
                break;
            }

            case 'total': {
                updatedPurchase = {
                    ...currentPurchase,
                    total: e.target.value
                };
                updateCurrentPurchaseData(updatedPurchase)
                break;
            }
            default: break;
        }
    };
    

    const submitHandler = (e) => {
        e.preventDefault();
        newPurchase(currentPurchase).then( data => {
            const goToBillDetails = () => navigate('/admin/purchase');
            goToBillDetails();
        })
    }



    return (
        <>
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mt-4">
                                <div className="card-header">
                                    <ul className="nav nav-tabs align-items-end card-header-tabs w-100">
                                        <li className="nav-item">
                                            <a className="nav-link active" href="/admin/purchase/create"><i className="fas fa-plus-circle mx-2"></i>New Purchase Bill</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="/admin/purchase"><i className="fas fa-th-list mx-2"></i>Bills</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-body">
                                  
                                    <form className="form-horizontal" onSubmit={submitHandler}>
                                        <div className="row">
                                            <div className="col-12 col-lg-8">
                                                <div className="mb-2 row">
                                                    <label className="col-md-2 col-form-label">Invoice Number</label>
                                                    <div className="col-md-10">
                                                        <input type="text" id="invoice_number" name="invoice_number" className="form-control" onChange={onFormUpdate} value={currentPurchase.invoice_number}/>
                                                    </div>
                                                </div>

                                                <div className="mb-2 row">
                                                    <label className="col-md-2 col-form-label">Invoice Date</label>
                                                    <div className="col-md-10">
                                                        <DatePicker className="form-control" selected={currentPurchase.invoice_date} onChange={(date) => onInvoiceDateUpdate(date)} />
                                                    </div>
                                                </div>

                                                <div className="mb-2 row">
                                                    <label className="col-md-2 col-form-label">Shop Name</label>
                                                    <div className="col-md-10">
                                                        <input type="text" id="shop_name" name="shop_name" className="form-control" onChange={onFormUpdate} value={currentPurchase.shop_name}/>
                                                    </div>
                                                </div>

                                                <div className="mb-2 row">
                                                    <label className="col-md-2 col-form-label">Brand Name</label>
                                                    <div className="col-md-10">
                                                        <input type="text" id="brand_name" name="brand_name" className="form-control" onChange={onFormUpdate} value={currentPurchase.brand_name}/>
                                                    </div>
                                                </div>

                                                <div className="mb-2 row">
                                                    <label className="col-md-2 col-form-label">Sub Total</label>
                                                    <div className="col-md-10">
                                                        <input type="number" step="any" id="sub_total" name="sub_total" className="form-control" onChange={onFormUpdate} value={currentPurchase.sub_total}/>
                                                    </div>
                                                </div>

                                                <div className="mb-2 row">
                                                    <label className="col-md-2 col-form-label">Total</label>
                                                    <div className="col-md-10">
                                                        <input type="number" step="any" id="total" name="total" className="form-control" onChange={onFormUpdate} value={currentPurchase.total}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-4">

                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col-12'>
                                                <div className="btn-group float-end">
                                                    <button type="submit" className="btn btn-primary btn btn-primary">Save Bill</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        authData: state.account,
        currentPurchase: state.purchase.currentPurchase
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        updateCurrentPurchaseData: (purchaseData) => {
            return dispatch( updateCurrentPurchase(purchaseData) )
        },
        newPurchase: (purchaseData) => {
            return dispatch( createPurchase(purchaseData) )
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePurchaseComponent)