import React from 'react';
import { connect } from 'react-redux';

const Loader = ({tableLoaded, colSpan}) => (
    <>
    {tableLoaded ? null : (
        <tfoot>
            <tr>
                <td data-attr={tableLoaded} colSpan={colSpan} key="loader">
                <div className="loader-container">
                    <img
                    className="loader"
                    src="/assets/images/loader.svg"
                    alt="Loading..."
                    />
                </div>
                </td>
            </tr>
        </tfoot>
    )}
    </>
);


const mapStateToProps = state => ({
    tableLoaded: state.common.tableLoader
});
  
export default connect(mapStateToProps)(Loader);