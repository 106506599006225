import { AUTH_REQUEST, LOGIN_SUCCESS, LOGIN_FAIL, AUTH_LOGOUT } from "./authTypes";

const user = JSON.parse(localStorage.getItem("user"));
const initialState = user
  ? { loading: false, auth: { id: user.id, name: user.name, role: user.role, billDesk: user.billDesk }, error: "" }
  : { loading: false, auth: null, error: ""};

const authReducer = (state = initialState, action) => {

    switch(action.type) {
        case AUTH_REQUEST:
            return {
                ...state,
                loading: true
            }

        case LOGIN_SUCCESS:
            return {
                ...state,
                auth: action.payload,
                error: '',
                loading: false
            }
        case LOGIN_FAIL:
            return {
                ...state,
                auth: null,
                error: action.payload,
                loading: false
            }
        case AUTH_LOGOUT:
            return {
                ...state,
                auth: null,
                error: "",
                loading: false
            }
            
        default: return state;
    }
}

export default authReducer;