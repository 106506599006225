import api from "../network/api"

export const storeTag = (tagData) => {
    return api.post("/tag", tagData).then(response => {
        if (response) {
            return response.data.data
        }
    })
}

export const findTag = (searchData) => {
    return api.get("/tag?name="+searchData.q).then(response => {
        if (response) {
            return response.data.data
        }
    })
}

export const fetchTags = () => {
    return api.get("/tag").then(response => {
        if (response) {
            return response.data.data
        }
    })
}

export const fetchBillingTagService = () => {
    return api.get("/tag?displayBill=1").then(response => {
        if (response) {
            return response.data.data
        }
    })
}
