import { 
    FETCH_DASHBOARD
} from "./dashboardTypes";

const initialState = {
    mainData: null,
    saleChart: {
        labels: ['Bitcoin', 'Eth', 'a', 'b', 'c', 'd'],
        datasets: [
            {
                label: "Price in USD",
                data: [30000, 1500, 1000, 2000, 3000, 7450],
                backgroundColor: [
                    "#ffbb11",
                    "#ecf0f1",
                    "#50AF95",
                    "#f3ba2f",
                    "#2a71d0"
                ]
            }
        ]
    }
}

const dashboardReducer = (state = initialState, action) => {
    switch(action.type) {
  
        case FETCH_DASHBOARD:
            return {
                ...state,
                mainData: action.payload
            }

        default: return state;
    }
}
export default dashboardReducer;