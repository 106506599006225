import api from "../network/api" 

export const fetchCustomers = (query) => {

    return api.get("/customer", { params: query }).then(response => {
        if (response) {
            return response.data.data
        }
    })
}

export const fetchCustomer = (customerId) => {

    return api.get("/customer/"+customerId ).then(response => {
        if (response) {
            return response.data.data
        }
    })
}