import { 
    UPDATE_CURRENT_STOCK,
    CLEAR_CURRENT_STOCK
} from "./stockTypes";
import { storeStock } from "../../service/stockService";

export const createNewStock = (stock) => {
    return (dispatch) => {

        return storeStock(stock).then( (response) => {
            return Promise.resolve(response);
        }).catch( (error) => {
            return Promise.reject();
        })
    }
}
export const updateCurrentStock = (stock) => {
    return (dispatch) => {
        dispatch(modifyCurrentStock(stock))
    }
}
export const removeCurrentStock = () => {
    return (dispatch) => {
        dispatch(clearCurrentStock())
    }
}




export const modifyCurrentStock = (stock) => {
    return {
        type: UPDATE_CURRENT_STOCK,
        payload: stock
    }
}
export const clearCurrentStock = () => {
    return {
        type: CLEAR_CURRENT_STOCK,
    }
}