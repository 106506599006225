import React from 'react';
import { connect } from 'react-redux'
import { Outlet, useNavigate } from "react-router-dom";

import HeaderComponent from './component/admin/include/HeaderComponent'
import SidebarComponent from './component/admin/include/SidebarComponent'

function Admin({authData}) {
    const navigate = useNavigate() 
    let isLoggedin = (authData && authData.auth && authData.auth.id) ? true : false;
    
	return (
		<div id="wrapper">
			<HeaderComponent isLoggedin={isLoggedin}/>
			<SidebarComponent isLoggedin={isLoggedin}/>
			<Outlet />
		</div>
	);
}

const mapStateToProps = (state) => {
    return {
        authData: state.account,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Admin);