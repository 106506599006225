import api from "../network/api" 

export const storePurchase = (purchaseData) => {
    return api.post("/purchase", purchaseData).then(response => {
        if (response) {
            return response.data.data
        }
    })
}

export const findPurchase = (searchData) => {
    return api.get("/purchase?name="+searchData.q).then(response => {
        if (response) {
            return response.data.data
        }
    })
}

export const fetchPurchaseBills = (query) => {
    return api.get("/purchase", { params: query }).then(response => {
        if (response) {
            return response.data.data
        }
    })
}

export const fetchPurchaseBill = (billId) => {
    return api.get("/purchase/"+billId).then(response => {
        if (response) {
            return response.data.data
        }
    })
}

export const updatePurchaseItem = (itemData) => {

    return api.post("/stock/"+itemData.billItemId, itemData.billItem).then(response => {
        if (response) {
            return response.data
        }
    })
}

export const uploadLock = (id) => {

    return api.post("/purchase/lock/", {billId: id}).then(response => {
        if (response) {
            return response.data
        }
    })
}
