import axios from "axios";
import TokenService from "../service/tokenService";
import { updateTokens, removeUser } from '../redux/auth/authAction'
import store from '../redux/store'

let url;
switch(process.env.NODE_ENV) {
    case 'production':
        url = 'https://apibill.a2zstreet.in/api';
        break;
    case 'development':
        url = 'http://localhost:8000/api';
        break;
    default:
        url = 'http://localhost:8000/api';
}


const instance = axios.create({
    baseURL: url,
    headers: {
        "Content-Type": "application/json",
    },
});

instance.interceptors.request.use(
    (config) => {
        const token = TokenService.getLocalAccessToken();
        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
            //config.headers["x-access-token"] = token; // for Node.js Express back-end
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

const { dispatch } = store;
instance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;
        if (originalConfig.url !== "/auth/login" && err.response) {

            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                try {
                    const rs = await instance.post("/auth/refresh", {
                        refreshToken: TokenService.getLocalRefreshToken(),
                    });
                    const { accessToken, refreshToken } = rs.data;
                    dispatch(updateTokens({ accessToken, refreshToken }));

                    return instance(originalConfig);
                } catch (_error) {
                    dispatch(removeUser());

                    return Promise.reject(_error);
                }
            }
        }
        return Promise.reject(err);
    }
);

export default instance;
