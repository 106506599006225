import { SOLD_ITEM_LIST} from "./soldItemTypes"
import { TABLE_LOADER } from "../common/commonTypes";
import { fetchSoldItems } from "../../service/soldItemService";

export const getSold = (query) => {
    return (dispatch) => {
        dispatch({ type: SOLD_ITEM_LIST, payload: null });
        dispatch({ type: TABLE_LOADER, payload: false });

        fetchSoldItems(query).then( (data) => {
            dispatch(fetchSoldItemsList(data))

            dispatch({ type: TABLE_LOADER, payload: true });
        }).catch( (error) => {
            console.log('Bills fetching error')
        })
    }
}


export const fetchSoldItemsList = (customerList) => {
    return {
        type: SOLD_ITEM_LIST,
        payload: customerList
    }
}