import "react-datepicker/dist/react-datepicker.css";
import 'react-tooltip/dist/react-tooltip.css'
import React, { useRef, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DatePicker from "react-datepicker";
import { 
    addProductToBill, 
    updateProductQty, 
    updateDiscountToBill,
    removeBillingProduct,
    createCustomerBill
} from '../../../../redux'
import {findProduct, findProductByBarcode} from "../../../../service/billingService";

import { connect } from 'react-redux'
import Select from 'react-select';
import { 
    getAmazonIcon, 
    timeAgo, 
    getPlayableIcon,
    getPlayableUnTickIcon,
    getPlayableTickIcon
} from '../../../../config'
import { Tooltip } from 'react-tooltip';
const Biller = ({
    billingData, 
    addToBill,
    updateQty,
    updateDiscount, 
    removeProduct,
    createBill
}) => {
    const selectRef = useRef();
    const inputRef = useRef();
    const navigate = useNavigate() 

    useEffect(() => {

    }, []);

    const[details, setDetails] = useState({
        selectorValue: { value: '1', label: 'Search' },
        barcodeInput: "",
        resultProduct: [],
        billDate: new Date()
    })

    var billerOptions = [
        { value: '1', label: 'Search' },
        { value: '2', label: 'Barcode' }
    ];

    function logChange(data) {
        setDetails({...details, selectorValue: {value: data.value, label: data.label}})
        
        // setDetails({...details, selectorValue:data.value})

        if(data.value === '1') {
            selectRef.current.select.focus()
            setTimeout(() => selectRef.current.focus(), 0)
        }
        if(data.value === '2') {
            inputRef.current.focus()
            setTimeout(() => inputRef.current.focus(), 0)
        }
    }

    const onSelectChange = (data) => {
        const product = data
        addToBill(product, billingData)
    }


    let typingTimer, doneTypingInterval = 800;
    const onSelectSearch = (input) => {
        if(input.length > 0) {
            clearTimeout(typingTimer);
            typingTimer = setTimeout(doneTyping, doneTypingInterval, input);
        }       
    }
    const doneTyping = (val) => {
        findProduct({q: val}).then(data => {
            let output = data.map( (data) => {
                return {
                    ...data,
                    value: data.id,
                    label: data.name
                }
            });

            setDetails({...details, resultProduct: output})
        })
    }

    const changePrice = async (e) => {
        const key = e.target.getAttribute('data-sortid');
        const price = e.target.value ? parseFloat(e.target.value) : 0.01
        const currentBillingdata = billingData

        let products = currentBillingdata.products.map((product) => {
            if (parseInt(key) === parseInt(product.idKey)) {
                return {
                    ...product,
                    sellingPrice: price
                }
            }
            return product
        })
        await updateQty(products, currentBillingdata, key)
    }

    const changeQty = async (e) => {
        const key = e.target.getAttribute('data-sortid');
        const qty = e.target.value ? parseInt(e.target.value) : 1
        const currentBillingdata = billingData
        let products = currentBillingdata.products.map((product) => {
            if (parseInt(key) === parseInt(product.idKey)) {
                return {
                    ...product,
                    qty: qty
                }
            }
            return product
        })

        await updateQty(products, currentBillingdata, key)
    }

    const removeBillProduct = (e) => {
        if(e.key === 'Enter' || typeof e.key === 'undefined' ) {
            const key = e.target.getAttribute('data-sortid');
            const currentBillingdata = billingData

            removeProduct(key, currentBillingdata)
        }
    }

    const changeDiscount = async (e) => {
        const discount = e.target.value
        await updateDiscount(discount)
    }

    const lastTabIndex = (e) => {
        if(e.key === 'Tab') {
            selectRef.current.select.focus()
            setTimeout(() => selectRef.current.focus(), 0)
        }
    }
    
    function submitBill() {
        if(!billingData.customer) {
            alert('Please select billing customer!')
            return;
        }

        if(billingData.products.length <= 0) {
            alert('Bill atleast 1 product minimum!')
            return;
        }

        var bill = {};
        bill.products = billingData.products.map((product) => {
            return {
                id: product.id,
                qty: product.qty,
                sellingPrice: product.sellingPrice
            }
        })
        bill.discount   = billingData.discount
        bill.customer   = billingData.customer.id
        bill.playable   = billingData.customer.app
        bill.billDate   = details.billDate

        createBill(bill).then(bill => {
            if(bill && bill.id) {
                const goToBillDetails = () => navigate('/admin/bill/'+bill.billNumber);
                goToBillDetails();
            }
        }).catch(error => {
            alert('Something went wrong!')
        })
    }

    function getBarcodeProduct(e) {
        if (e.key === "Enter") {
            findProductByBarcode({barcode: e.currentTarget.value}).then(response => {
                if(response !== null) {
                    onSelectChange(response)
                    inputRef.current.value = ""
                } else {
                    inputRef.current.value = ""
                    alert('Barcord not found!')
                }
            }).catch(error => {
                alert('Something went wrong!')
            })
        }
    }
    
    
    return (
        <>
        <div className="pos-biller">
            <div className="row">
                <div className="col-4 pe-0">
                    <div className="p-1">
                        <Select
                            name="form-field-name"
                            value={details.selectorValue}
                            options={billerOptions}
                            onChange={logChange}
                        />
                    </div>
                </div>
                <div className="col-8 ps-0">
                    <div className={"pos-barcode-input p-1 " + (details.selectorValue.value === '1' ? "d-block" : "d-none") } >
                        <Select
                            ref={selectRef}
                            id="product-select"
                            isSearchable
                            onChange={onSelectChange}
                            onInputChange={onSelectSearch}
                            options={details.resultProduct}
                            value={details.barcodeInput}
                        />
                    </div> 
                    <div className={"pos-barcode-input p-1 " + (details.selectorValue.value === '2' ? "d-block" : "d-none") }>
                        <input type="text" ref={inputRef}  className="form-control" onKeyPress={getBarcodeProduct}/>
                    </div>
                </div>
            </div>
            <div className="pos-item-list">
                <table className="table mb-0">
                    <thead className="table-light">
                        <tr>
                            <th className="item-trash">#</th>
                            <th>Name</th>
                            <th className="item-qty">Unit P.</th>
                            <th className="item-qty">Qty</th>
                            <th className="item-price">Price</th>
                            <th className="item-trash p-0"></th>
                        </tr>
                    </thead>
                    <tbody>
                    <>
                    {
                        (billingData.products.length > 0) ? billingData.products.map((data, id)=>{
                            return <>
                                <tr key={id}>
                                    <td className="playable-avatar">
                                        <div className="d-flex info-block">
                                            {id+1}
                                            <i id={`my-anchor-element-${id}`} data-tooltip-html={`<div><ul><li>MSP: Rs.${data.msp}</li><li>${data.isPlayProduct ? `Pointable From: Rs.${data.sellingPriceThreshold}` : 'No points available'}</li></ul></div>`} className="fa fa-info-circle ms-1" aria-hidden="true"></i>
                                            <Tooltip
                                                anchorSelect={`#my-anchor-element-${id}`}
                                            />
                                        </div>
                                        {
                                            (billingData.customer?.app === 1 && data.isPlayProduct === true) ? <>
                                            {
                                                (data.sellingPrice < data.sellingPriceThreshold) ? <>
                                                <div className="">
                                                    <img className="img-fluid" src={getPlayableUnTickIcon()} />
                                                </div>
                                                </> : <>
                                                <div className="">
                                                    <img className="img-fluid" src={getPlayableTickIcon()} />
                                                </div>
                                                </>
                                            }
                                            </> : <></>
                                        }
                                    </td>
                                    <td>
                                        
                                        {data.name}
                                        <div className="fw-bold">
                                            <img className="ecom-icon img-fluid" alt="amazon" src={getAmazonIcon()} /> { (data && data.ecom1_low && data.ecom1_low.price) ? 'Rs.'+parseFloat(data.ecom1_low.price) : 'Rs.NA'} { (data && data.ecom1_low && data.ecom1_low.date) ? '('+timeAgo(data.ecom1_low.date)+')' : ''}
                                        </div>
                                    </td>
                                    <td>
                                        <input 
                                            className="form-control product-price" 
                                            onChange={changePrice} 
                                            data-sortid={data.idKey}
                                            value={data.sellingPrice} 
                                            type="number" 
                                            min="0" />
                                    </td>
                                    <td>
                                        <input 
                                            className="form-control product-qty" 
                                            onChange={changeQty} 
                                            data-sortid={data.idKey}
                                            value={data.qty} 
                                            type="number" 
                                            min="1" />
                                    </td>
                                    <td>{(data.sellingPrice * data.qty).toFixed(2)}</td>
                                    <td className='px-1 py-2'>
                                        <i
                                            tabIndex="0"
                                            className='trash-icon fa fa-trash' 
                                            data-sortid={data.idKey} 
                                            onKeyPress={removeBillProduct}
                                            onClick={removeBillProduct}></i>
                                    </td>
                                </tr>
                            </>
                        }) : <></>
                    }
                    </>
                    </tbody>
                </table>
            </div>
            <div className="pos-calculation">
                <div className="row">
                    <div className="col-8 col-md-9 px-4 p-2">
                        Sub Total
                    </div>
                    <div className="col-4 col-md-3 px-4 p-2">
                    Rs.
                    {
                        billingData.products.reduce(function (previousvalue, currentBillProduct, currentIndex, array) {
                            return ((previousvalue*1) + (currentBillProduct.sellingPrice*currentBillProduct.qty)).toFixed(2);
                        }, 0)
                    }
                    </div>
                </div>
                <div className="row">
                    <div className="col-8 col-md-9 px-4 p-2">
                        Discount
                    </div>
                    <div className="col-4 col-md-3 px-4 p-2">
                        <input onKeyDown={lastTabIndex} onChange={changeDiscount} className='form-control biller-input' type="number" min="0" name="discount" value={billingData.discount}/>
                    </div>
                </div>
            </div>
            <div className="pos-calculation-total">
                <div className="row">
                    <div className="col-8 col-md-9 px-4 p-2">
                        Total
                    </div>
                    <div className="col-4 col-md-3 px-4 p-2">
                    Rs.
                    {
                        ( billingData.products.reduce(function (previousvalue, currentBillProduct, currentIndex, array) {
                            return ((previousvalue*1) + (currentBillProduct.sellingPrice*currentBillProduct.qty));
                        }, 0) - billingData.discount ).toFixed(2)
                    }
                    </div>
                </div>
            </div>
            <div className="pos-calculation-submit">
                <div className="row">
                    <div className="col-6 px-4 p-2">
                        <DatePicker className="form-control" selected={details.billDate} onChange={(date) => setDetails({...details, billDate: date})} />
                    </div>
                    <div className="col-6 px-4 p-2 d-flex justify-content-lg-end">
                        <button type="button" onClick={submitBill} className="btn btn-info width-md waves-effect waves-light">Create</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        products: state.product.products,
        billingData: state.billing
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        addToBill: (product, billingData) => {
            dispatch(addProductToBill(product, billingData))
        },
        updateQty: (data, billingData, key) => {
            dispatch(updateProductQty(data, billingData, key))
        },
        updateDiscount: (discount) => {
            dispatch(updateDiscountToBill(discount))
        },
        removeProduct: (sortId, billingData) => {
            dispatch(removeBillingProduct(sortId, billingData))
        },
        createBill: (bill) => {
            return dispatch(createCustomerBill(bill))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Biller)