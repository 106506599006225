const moment = require('moment');

export const S3_BUCKET_URL =  "https://a2zstreet-shop.s3.amazonaws.com/";

export const getProductImage = (media) => {
    if(media && media.key) {
        return S3_BUCKET_URL+`${media.key}`
    }
    return '../assets/images/no-image.jpg';
}

export const getAmazonIcon = () => {
    return '../assets/images/amazon.jpg';
}

export const getPlayableIcon = () => {
    return '../assets/images/playable.png';
}

export const getPlayableTickIcon = () => {
    return '../assets/images/playable_tick.png';
}

export const getPlayableUnTickIcon = () => {
    return '../assets/images/playable_untick.png';
}

export const timeAgo = (date) => {
    const timeAgo = moment(date).fromNow();
    return timeAgo;
}