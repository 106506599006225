
import React, { useEffect, useRef, useState } from 'react'
import { 
    addProductToBill, 
    getProducts 
} from '../../../../redux'
import { connect } from 'react-redux'

import { getProductImage } from '../../../../config'

const Stocker = ({
    fetchProducts, 
    products, 
    billingData, 
    onOpenProductModal,
    sliderData,
    addToBill
}) => {
    
    const[details, setDetails] = useState({
        searchKey: '',
        productPage: 1,
        tagId: '',
        paginationLoading: false,
        stopLoading: false
    })

    const onAddToBill = (e) => {
        e.stopPropagation();
        if(e.key === 'Enter' || typeof e.key === 'undefined' ) {
            const product = products.find((product) => {
                return parseInt(product.id) === parseInt(e.currentTarget.getAttribute("data-productid"))
            })
            addToBill(product, billingData)
        }
    }

    const openCreateProductModal = (e) => {
        e.stopPropagation();
        e.preventDefault()
        onOpenProductModal({
            productId: e.currentTarget.getAttribute("data-productid")
        });
    }

    
    const onSearchChanage = (e) => {
        setDetails({
            ...details, 
            searchKey: e.target.value
        })
    }

    const onSearchSubmit = (e) => {
        e.preventDefault()
        setDetails({
            ...details, 
            productPage: 1,
            tagId: ''
        })

        fetchProducts('name='+details.searchKey+'&page=1')
    }

    const resetSearch = (e) => {
        setDetails({
            ...details, 
            searchKey: '',
            productPage: 1,
            tagId: ''
        })
        fetchProducts('name=&page=1')
    }


    const scrollContainerRef = useRef(null);
    const handleScroll = () => {
        const scrollContainer = scrollContainerRef.current;
        if (
            scrollContainer.scrollTop + scrollContainer.clientHeight >=
            scrollContainer.scrollHeight
        ) {

            if(!details.stopLoading) {
                setDetails(prevDetails => ({
                    ...prevDetails, 
                    productPage: details.paginationLoading ? prevDetails.productPage : prevDetails.productPage + 1
                }));
            }
        }
    };


    const handleScrollEnd = async (page) => {
        const scrollContainer = scrollContainerRef.current;
        if(page !== 1) {
            setDetails(prevDetails => ({
                ...prevDetails, 
                paginationLoading: true
            }));
    
            let products = await fetchProducts('name=' + details.searchKey + '&tagId='+details.tagId+'&page=' + page, page);



            if(products.length <= 0) {
                setDetails(prevDetails => ({
                    ...prevDetails, 
                    paginationLoading: false,
                    stopLoading: true
                }));
            } else {
                setDetails(prevDetails => ({
                    ...prevDetails, 
                    paginationLoading: false,
                    stopLoading: false
                }));
            }

        }
    };

    useEffect(() => {
        
        if(sliderData && sliderData.tagId) {
            setDetails({
                ...details, 
                productPage: 1,
                tagId: sliderData.tagId
            })
        }
    }, [sliderData]);

    useEffect(() => {
        if(!details.paginationLoading)
            handleScrollEnd(details.productPage);
    }, [details.productPage]);

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        scrollContainer.addEventListener('scroll', handleScroll);
    
        // Clean up the event listener when the component unmounts
        return () => {
            scrollContainer.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
        <div className="pos-products">
            <div className='pos-product-title'>
                <div className="navbar p-0">
                    <div className="container-fluid p-0">
                        <ul className="list-unstyled topnav-menu topnav-menu-left m-0">
                            <li className="dropdown">
                                <button className="btn btn-primary waves-effect waves-light mx-2" data-productid={0} onClick={openCreateProductModal}>
                                    <i className="far fa-plus-square"></i> Add New Product
                                </button>
                            </li>
                        </ul>
                        <ul className="list-unstyled topnav-menu custom float-end mb-0">
                            <li>
                                <button className="btn btn-success waves-effect waves-light py-0 mx-2" onClick={resetSearch}>
                                    <i className="wi wi-refresh"></i>
                                </button>
                            </li>
                            <li className="">
                                <form className="app-search" onSubmit={onSearchSubmit}>
                                    <div className="app-search-box dropdown">
                                        <div className="input-group">
                                            <input type="search" className="form-control" placeholder="Search..." id="top-search" onChange={onSearchChanage} value={details.searchKey}/>
                                            <button className="btn btn-light ms-0 mx-2" type="submit">
                                                <i className="fe-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </li> 
                        </ul>
                    </div>
                </div>
            </div>
            <div className="pos-product-list p-2" ref={scrollContainerRef}>
                <div className="row">
                    {
                        products.map((product, key) => {
                            var stock =                                                     product && product.stocks && product.stocks.length > 0 ? product.stocks.reduce( (previousValue, p) => {
                                return (previousValue*1) + (p.balanceQty*1);
                            }, 0) : 0;
                            var stockClass = (stock === 0) ? 'primary' : 'success';
                            return (
                                <div tabIndex="0" onKeyDown={onAddToBill} className="col-6 col-sm-4 col-md-4 col-xl-3 col-xxl-3 mb-2" onClick={onAddToBill} data-productid={product.id} key={key}>
                                    <div className="product-parent">
                                        <div className="add-to-biller">
                                            <button tabIndex="-1" type="button" className="btn btn-info waves-effect waves-light" data-productid={product.id} onClick={openCreateProductModal}>
                                                Edit<span className="btn-label-right"><i className="fe-edit"></i></span>
                                            </button>
                                        </div>
                                        <div className="stock-total">
                                            <button tabIndex="-1" type="button" className={`btn btn-${stockClass} waves-effect waves-light`}>
                                                {stock}
                                            </button>
                                        </div>

                                        <div className="product-image">
                                            <img alt="Product" src={getProductImage(product.firstMedia)} className="img-fluid" />
                                        </div>
                                        <div className="product-name">
                                            <div className="title">
                                                {product.name}
                                            </div>
                                            <div className="price">
                                                Rs. {product.sellingPrice}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    {
                        (details.paginationLoading === true) ? <><div className="col-12 font-2 text-center display-6">Loading More Products ...</div></> : <></>
                    }
                </div>
            </div>
        </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        products: state.product.products,
        billingData: state.billing
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        addToBill: (product, billingData) => {
            dispatch(addProductToBill(product, billingData))
        },
        fetchProducts: (query, page = 1) => dispatch(getProducts(query, page)) 
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Stocker)