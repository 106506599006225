import { UPDATE_CURRENT_PURCHASE, PURCHASE_BILL_LIST, PURCHASE_BILL_DETAIL, UPDATE_PURCHASE_STOCK, UPDATE_LOCK } from "./purchaseTypes"

const initialState = {
    currentPurchase: {
        invoice_number: "",
        invoice_date: "",
        shop_name: "",
        brand_name: "",
        sub_total: 0.00,
        total: 0.00
    },
    detail: null,
    list: null,
    error: "" 
}

const purchaseReducer = (state = initialState, action) => {
    switch(action.type) {

        case UPDATE_CURRENT_PURCHASE:
            return {
                ...state,
                currentPurchase: action.payload,
            }

        case PURCHASE_BILL_LIST:
            return {
                ...state,
                list: action.payload,
            }
            
        case PURCHASE_BILL_DETAIL: 
            return {
                ...state,
                detail: action.payload
            }

        case UPDATE_PURCHASE_STOCK: 
            return {
                ...state,
                detail: {
                    ...state.detail,
                    stocks: state.detail.stocks.map(item => {
                        return (parseInt(item.id) === parseInt(action.payload.id)) ? action.payload : item
                    })
                },
            }
        
        case UPDATE_LOCK: 
            return {
                ...state,
                list: {
                    ...state.list,
                    rows: state.list.rows.map(item => {
                        return (parseInt(item.id) === parseInt(action.payload)) ? {...item, billOpen: false} : item
                    })
                },
            }

        default: return state;
    }
}

export default purchaseReducer;
