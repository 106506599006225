export const AUTH_REQUEST =  "AUTH_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const UPDATE_ACCESS_TOKEN = "UPDATE_ACCESS_TOKEN";
export const UPDATE_REFRESH_TOKEN = "UPDATE_REFRESH_TOKEN";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
// export {
//     AUTH_REQUEST,
//     LOGIN_SUCCESS,
//     LOGIN_FAIL
// }