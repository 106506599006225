import React from 'react';

const Pagination = ({ current, perPageLimit, totalRecords, onPageChange }) => {
  const totalPages = Math.ceil(totalRecords / perPageLimit);
  const maxPageNumbersToShow = 3; // number of page numbers to show on either side of the current page

  const getPageNumbers = () => {
    const pageNumbers = [];
    let startPage = Math.max(1, current - maxPageNumbersToShow);
    let endPage = Math.min(totalPages, current + maxPageNumbersToShow);

    // handle edge cases where there are not enough pages to show maxPageNumbersToShow on either side
    if (startPage === 1) {
      endPage = Math.min(totalPages, startPage + 2 * maxPageNumbersToShow);
    } else if (endPage === totalPages) {
      startPage = Math.max(1, endPage - 2 * maxPageNumbersToShow);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  }

  const handlePrev = () => {
    onPageChange(current - 1);
  }

  const handleNext = () => {
    onPageChange(current + 1);
  }

  const handleFirst = () => {
    onPageChange(1);
  }

  const handleLast = () => {
    onPageChange(totalPages);
  }

  return (
    <ul className="pagination">
      <li className={`page-item ${current === 1 ? 'disabled' : ''}`}>
        <button className="page-link" onClick={handleFirst}>
          First
        </button>
      </li>
      <li className={`page-item ${current === 1 ? 'disabled' : ''}`}>
        <button className="page-link" onClick={handlePrev}>
          Prev
        </button>
      </li>
      {getPageNumbers().map(pageNumber => (
        <li className={`page-item ${current === pageNumber ? 'active' : ''}`} key={pageNumber}>
          <button className="page-link" onClick={() => onPageChange(pageNumber)}>
            {pageNumber}
          </button>
        </li>
      ))}
      <li className={`page-item ${current === totalPages ? 'disabled' : ''}`}>
        <button className="page-link" onClick={handleNext}>
          Next
        </button>
      </li>
      <li className={`page-item ${current === totalPages ? 'disabled' : ''}`}>
        <button className="page-link" onClick={handleLast}>
          Last
        </button>
      </li>
    </ul>
  );
}

export default Pagination;