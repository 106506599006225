import React from "react";
import { connect } from 'react-redux'
import { NavLink } from "react-router-dom";

function SidebarComponent({isLoggedin, authData}) {

    function preventClick(e) {
        e.preventDefault()
    }

    let componentContent = ''
    if(isLoggedin) {
        componentContent = <div className="left-side-menu">
            <div  className="logo-box">
                <a href="!#" className="logo logo-dark text-center">
                    <span className="logo-sm">
                        <img src="/assets/images/logo-sm-dark.png" alt="" height="24" />
                    </span>
                    <span className="logo-lg">
                        <img src="/assets/images/logo-dark.png" alt="" height="20" />
                    </span>
                </a>
                <a href="!#" className="logo logo-light text-center">
                    <span className="logo-sm">
                        <img src="/assets/images/logo-sm.png" alt="" height="24" />
                    </span>
                    <span className="logo-lg">
                        <img src="/assets/images/logo-light.png" alt="" height="20" />
                    </span>
                </a>
            </div>

            <div className="h-100" data-simplebar>
                <div className="user-box text-center">
                    <img src="/assets/images/users/avatar-1.jpg" alt="" className="rounded-circle avatar-md" />
                    <div className="dropdown">
                        <a href="!#" className="text-reset dropdown-toggle h5 mt-2 mb-1 d-block"
                            data-bs-toggle="dropdown">Nik Patel</a>
                        <div className="dropdown-menu user-pro-dropdown">
                            <a href="!#" onClick={preventClick} className="dropdown-item notify-item">
                                <i className="fe-user me-1"></i>
                                <span>My Account</span>
                            </a>
                            <a href="!#" onClick={preventClick} className="dropdown-item notify-item">
                                <i className="fe-settings me-1"></i>
                                <span>Settings</span>
                            </a>
                            <a href="!#" onClick={preventClick} className="dropdown-item notify-item">
                                <i className="fe-lock me-1"></i>
                                <span>Lock Screen</span>
                            </a>
                            <a href="!#" onClick={preventClick} className="dropdown-item notify-item">
                                <i className="fe-log-out me-1"></i>
                                <span>Logout</span>
                            </a>
                        </div>
                    </div>
                    <p className="text-reset">Admin Head</p>
                </div>

                <div id="sidebar-menu">
                    <ul id="side-menu">
                        <li>
                            <NavLink exact="true" to="/admin/dashboard">
                                <i className="ri-dashboard-line"></i>
                                <span>Dashboard</span>
                            </NavLink>
                        </li>
                        <li className="menu-title">System</li>
                        <li>
                            <NavLink exact="true" to="/admin/billing">
                                <i className="mdi mdi-desktop-mac-dashboard"></i>
                                <span>POS Billing</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact="true" to="/admin/bills">
                                <i className="mdi mdi-clipboard-list-outline"></i>
                                <span>Bills</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact="true" to="/admin/sold/items">
                                <i className="fe-dollar-sign"></i>
                                <span>Sold Items</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact="true" to="/admin/customers">
                                <i className="bx bx-user-pin"></i>
                                <span>Customers</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact="true" to="/admin/transactions">
                                <i className="bx bx-money"></i>
                                <span>Transactions</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact="true" to="/admin/purchase">
                                <i className="bx bx-list-check"></i>
                                <span>Purchase Bills</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact="true" to="/admin/tags">
                                <i className="bx bx-purchase-tag"></i>
                                <span>Tags</span>
                            </NavLink>
                        </li>
                        {
                            (authData && authData.auth && authData.auth.role && authData.auth.role === 1) ? 
                            <>
                                <li>
                                    <NavLink exact="true" to="/admin/sold/report">
                                        <i className="bx bx-purchase-tag"></i>
                                        <span>Report</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink exact="true" to="/admin/user">
                                        <i className="bx bx-user-circle"></i>
                                        <span>Admin Users</span>
                                    </NavLink>
                                </li>
                            </> 
                            : <></>
                        }
                    </ul>
                </div>
                <div className="clearfix"></div>
            </div>
        </div>
    }

    return componentContent;
}


const mapStateToProps = (state) => {
    return {
        authData: state.account
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarComponent)