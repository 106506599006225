import { UPDATE_CURRENT_TAG, TAG_LIST, BILLING_TAGS } from "./tagTypes";
import { storeTag, fetchTags, fetchBillingTagService } from "../../service/tagService";


export const fetchBillTags = (query) => {
    return (dispatch) => {
        fetchBillingTagService(query).then( (data) => {
            let tagList = [];
            if(data && data.rows && data.rows.length > 0) {
                tagList = data.rows.map(tagData => {
                    return {
                        id: tagData.id,
                        title: tagData.name,
                        background: '#ff4c3b',
                        text: ``
                    }
                })
            }

            dispatch(fetchBillingTags(tagList))
        }).catch( (error) => {
            console.log('Tags fetching error')
        })
    }
}

export const updateCurrentTag = (tagData) => {
    return (dispatch) => {
        dispatch(modifyCurrentTag(tagData))
    }
}

export const createTag = (tagData) => {
    return (dispatch) => {
        return storeTag(tagData).then( (data) => {
            return Promise.resolve(data);
        }).catch( (error) => {
            return Promise.reject(error);
        })
    }
}

export const getTags = (query) => {
    return (dispatch) => {
        fetchTags(query).then( (data) => {
            dispatch(fetchTagList(data))
        }).catch( (error) => {
            console.log('Bills fetching error')
        })
    }
}



export const fetchBillingTags = (tags) => {
    return {
        type: BILLING_TAGS,
        payload: tags
    }
}

export const modifyCurrentTag = (tagData) => {
    return {
        type: UPDATE_CURRENT_TAG,
        payload: tagData
    }
}

export const fetchTagList = (tagList) => {
    return {
        type: TAG_LIST,
        payload: tagList
    }
}
