import React, { useEffect, useState } from 'react'
import LoginComponent from './LoginComponent'
import Pagination from './include/Pagination';
import Loader from './include/Loader';
import Cashier from './include/Cashier'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    getCustomer,
    openCashierPopup
} from '../../redux'
import Select from 'react-select';
import { findBillingCustomer } from '../../service/billingService'; 

function CustomerComponent({
        authData,
        fetchCustomers,
        customerList,
        triggerCashierPopup
    }) {
    const navigate = useNavigate() 


    //Customer Ajax Select
    const[filter, setFilter] = useState({
        selectedOption: {},
        resultCustomer: []
    })
    const[page, setPage]    = useState({
        currentPage: 1,
        perPage: 20
    })

    const onSelectChange = (data) => {
        if (data !== null) {
            setFilter({...filter, selectedOption: {value: data.id, label: data.label}})
        } else {
            setFilter({...filter, selectedOption: {}}) 
        }
    }

    let typingTimer, doneTypingInterval = 800;
    const onSelectSearch = (input) => {
        if(input.length > 0) {
            clearTimeout(typingTimer);
            typingTimer = setTimeout(doneTyping, doneTypingInterval, input);
        }       
    }

    const doneTyping = (val) => {

        findBillingCustomer({q: val}).then(data => {
            let output = data.rows.map( (data) => {
                return {
                    ...data,
                    value: data.id,
                    label: data.name+' ('+data.phone+')',
                }
            });
            setFilter({...filter, resultCustomer: output})
        })

    }
    const doFilter = () => {
        const { resultCustomer, ...filterData } = filter;
        fetchCustomers({page: page.currentPage, limit: page.perPage, filter: filterData})
    }
    //End of Customer Ajax Select

    // Pagination
    const handlePageChange = (pageNumber) => {
        setPage({
            ...page,
            currentPage: pageNumber
        });
        const { resultCustomer, ...filterData } = filter;
        fetchCustomers({page: pageNumber, limit: page.perPage, filter: filterData})
    }
    // End Pagination

    const [mount, setMount] = useState(false)
    useEffect(() => {
        if(!mount) {
            setMount(true);

            fetchCustomers({page: 1, limit: page.perPage})
            if(!authData || !authData.auth || !authData.auth.id) {
                const goToLoginPage = () => navigate('/admin/login');
                goToLoginPage();
            }
        }

    }, [mount, navigate, authData, fetchCustomers, page]);

    if(!authData || !authData.auth || !authData.auth.id) {
        return <LoginComponent></LoginComponent>
    }

    const openCashier = (customerId) => {
        triggerCashierPopup(customerId)
    }

    return (
        <>
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mt-4">
                                <div className="card-header">
                                    <ul className="nav nav-tabs align-items-end card-header-tabs w-100">
                                        <li className="nav-item">
                                            <a className="nav-link active" href="/admin/customers"><i className="fas fa-th-list mx-2"></i>Customers</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="/admin/transactions"><i className="fas fa-th-list mx-2"></i>Customer Transactions</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-body">

                                <div className="row">
                                        <div className="mb-2 col-md-3">
                                            <label className="form-label">Customer</label>
                                            <Select
                                                isSearchable
                                                isClearable={true}
                                                onChange={onSelectChange}
                                                onInputChange={onSelectSearch}
                                                options={filter.resultCustomer}
                                                value={filter.selectedOption}
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label"></label>
                                            <button onClick={doFilter} type="button" className="btn btn-info width-md waves-effect waves-light">Filter</button>
                                        </div>
                                    </div>

                                    <table className="table dataTable no-footer">
                                        <thead>
                                            <tr role="row">
                                                <th>#</th>
                                                <th>Customer</th> 
                                                <th>Phone</th> 
                                                <th>Pending Amount</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            ( customerList && customerList.list && customerList.list.rows && customerList.list.rows.length > 0) ? customerList.list.rows.map((data, id) => {
                                                return <tr key={id}>
                                                    <td>#{id+1}</td>
                                                    <td>{data.name}</td>
                                                    <td>{data.phone}</td>
                                                    <td>Rs {data.totalAmountToPay}</td>
                                                    <td>
                                                        <div className="btn-group mt-2 me-1">
                                                            {
                                                                (data && data.totalAmountToPay && data.totalAmountToPay > 0) ? <button type="button" className="btn btn-success d-print-none" onClick={() => openCashier(data.id)}>Pay Now</button> : <button className="btn btn-lg font-16 btn-info d-print-none">Paid</button>
                                                            }
                                                            <button type="button" className="btn btn-success dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <i className="mdi mdi-chevron-down"></i>
                                                            </button>
                                                            <div className="dropdown-menu">
                                                                <a target="_blank" className="dropdown-item" href={'/admin/customers/'+data.id+'/statement/'}>Statement</a>
                                                                <a className="dropdown-item" href="!#">Another action</a>
                                                                <a className="dropdown-item" href="!#">Something else here</a>
                                                                <div className="dropdown-divider"></div>
                                                                <a className="dropdown-item" href="!#">Separated link</a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            }) : <tr><td className="text-center" colSpan={6}>No Record</td></tr>
                                        }
                                        </tbody>
                                        <Loader colSpan={5}/>
                                    </table>


                                    <div className="row">
                                        <div className="col-sm-12 col-md-5">
                                            <div className="dataTables_info" role="status" aria-live="polite">Showing customers {page.currentPage*page.perPage - (page.perPage - 1)} to { (customerList && customerList.list && customerList.list.count && customerList.list.count < page.currentPage*page.perPage) ? customerList.list.count : page.currentPage*page.perPage } of {customerList && customerList.list && customerList.list.count ? customerList.list.count : 0}</div>
                                        </div>
                                        <div className="col-sm-12 col-md-7">
                                            <div className="dataTables_paginate paging_simple_numbers float-end">
                                                <Pagination
                                                    current={page.currentPage}
                                                    perPageLimit={page.perPage}
                                                    totalRecords={(customerList && customerList.list && customerList.list.count) ? customerList.list.count : 0}
                                                    onPageChange={handlePageChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Cashier />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        authData: state.account,
        customerList: state.customer
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchCustomers: (query) => {
            dispatch( getCustomer(query) )
        },
        triggerCashierPopup: (customerId) => {
            dispatch(openCashierPopup(customerId))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerComponent)