import api from "../network/api" 

export const storeProduct = (product) => {
    let payload = {
        name: product.name,
        hsn: product.hsn,
        barcode: product.barcode,
        localBarcode: product.localBarcode,
        gstPercentage: product.gstPercentage,
        sellingPrice: product.sellingPrice,
        msp: product.msp,
        mrp: product.mrp,
        tags: product.tags.map(t => {return t.id}),
        media: product.media.map(data => {
            return data.id
        })
    };

    return api.post("/product", payload).then(response => {
        if (response) {
            return response.data
        }
    })
}

export const updateProduct = (id, product) => {

    let payload = {
        name: product.name,
        hsn: product.hsn,
        barcode: product.barcode,
        localBarcode: product.localBarcode,
        gstPercentage: product.gstPercentage,
        sellingPrice: product.sellingPrice,
        msp: product.msp,
        mrp: product.mrp,
        tags: product.tags.map(t => {return t.id}),
        media: product.media.map(data => {
            return data.uploadId
        })
    };

    return api.post("/product/"+id, payload).then(response => {
        if (response) {
            return response.data
        }
    })
}

export const updateProductEcom = (id, product) => {

    let payload = {
        ecom1_low: product.ecom1_low,
        ecom2_low: product.ecom2_low,
        ecom3_low: product.ecom3_low,
        ecom4_low: product.ecom4_low,
        ecom5_low: product.ecom5_low
    };

    return api.post("/product/ecom/"+id, payload).then(response => {
        if (response) {
            return response.data
        }
    })
}

export const fetchProductsService = (query) => {
    
    return api.get("/product?active=1&"+query).then(response => {
        if (response) {
            return response.data.data
        }
    })
}
