import React, { useEffect, useState } from 'react'
import LoginComponent from './LoginComponent'
import Pagination from './include/Pagination';
import moment from 'moment';
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    getPurchaseBills,
    lockPurchase
} from '../../redux'

function PurchaseListComponent({
        authData,
        fetchPurchaseBills,
        purchaseBillList,
        updatePurchaseLock
    }) {
    const navigate = useNavigate() 

    // Pagination
    const[page, setPage]    = useState({
        currentPage: 1,
        perPage: 20
    })

    const handlePageChange = (pageNumber) => {
        setPage({
            ...page,
            currentPage: pageNumber
        });
        fetchPurchaseBills({page: pageNumber, limit: page.perPage})
    }

    const [mount, setMount] = useState(false)
    useEffect(() => {
        if(!mount) {
            setMount(true);

            fetchPurchaseBills({page: 1, limit: page.perPage})
            if(!authData || !authData.auth || !authData.auth.id) {
                const goToLoginPage = () => navigate('/admin/login');
                goToLoginPage();
            }
        }
    }, [mount, navigate, authData, fetchPurchaseBills, page]);

    if(!authData || !authData.auth || !authData.auth.id) {
        return <LoginComponent></LoginComponent>
    }

    const lockPurchaseBill = (e) => {
        const id = e.currentTarget.getAttribute('data-purchaseid');
        updatePurchaseLock(id).then(response => {

        }).catch(error => {
            let errorMessage = '';
            if(error && error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
                error.response.data.errors.forEach(error => {
                    errorMessage += error.msg+' '
                });
            }
            if(error && error.response && error.response.data && error.response.data.message && error.response.data.message !== '') {
                errorMessage += error.response.data.message
            }
            alert(errorMessage)
        })
    }

    return (
        <>
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mt-4">
                                <div className="card-header">
                                    <ul className="nav nav-tabs align-items-end card-header-tabs w-100">
                                        <li className="nav-item">
                                            <a className="nav-link" href="/admin/purchase/create"><i className="fas fa-plus-circle mx-2"></i>New Purchase Bill</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link active" href="/admin/purchase"><i className="fas fa-th-list mx-2"></i>Bills</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-body">
                                    <table className="table dataTable no-footer">
                                        <thead>
                                            <tr role="row">
                                                <th>Shop / Brand</th> 
                                                <th>Invoice Number</th> 
                                                <th>Invoice Data</th> 
                                                <th>Invoice Total</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            ( purchaseBillList && purchaseBillList.list && purchaseBillList.list.rows) ? purchaseBillList.list.rows.map((data, id) => {
                                                return <tr key={id}>
                                                    <td>{(data.shopName && data.shopName !== '') ? data.shopName + ((data.brandName && data.brandName !== '') ? (' ('+data.brandName+')') : '') : '-'}</td>
                                                    <td>{(data.invoiceNumber && data.invoiceNumber !== '') ? data.invoiceNumber : '-'}</td>
                                                    <td>{(data.invoiceDate) ? moment(data.invoiceDate).format('MMMM Do YYYY') : '-'}</td>
                                                    <td>{data.total}</td>
                                                    <td>
                                                        {
                                                            (authData && authData.auth && authData.auth.role === 1) ? 
                                                                
                                                                (data && data.billOpen) ? <button type="button" data-purchaseid={data.id} onClick={lockPurchaseBill} className="btn btn-primary waves-effect waves-light me-2"><i className="ri-lock-unlock-fill"></i></button> : <button type="button" className="btn btn-success waves-effect waves-light me-2"><i className="ri-lock-2-fill"></i></button>
                                                            
                                                            : ''
                                                        }
                                                        <a href={'/admin/purchase/'+data.id} className="btn btn-info waves-effect waves-light">Bill Detail</a>
                                                    </td>
                                                </tr>
                                            }) : null
                                        }
                                        </tbody>
                                    </table>

                                    <div className="row">
                                        <div className="col-sm-12 col-md-5">
                                            <div className="dataTables_info" role="status" aria-live="polite">Showing purchases {page.currentPage*page.perPage - (page.perPage - 1)} to { (purchaseBillList && purchaseBillList.list && purchaseBillList.list.count && purchaseBillList.list.count < page.currentPage*page.perPage) ? purchaseBillList.list.count : page.currentPage*page.perPage } of {purchaseBillList && purchaseBillList.list && purchaseBillList.list.count ? purchaseBillList.list.count : 0}</div>
                                        </div>
                                        <div className="col-sm-12 col-md-7">
                                            <div className="dataTables_paginate paging_simple_numbers float-end">
                                                <Pagination
                                                    current={page.currentPage}
                                                    perPageLimit={page.perPage}
                                                    totalRecords={(purchaseBillList && purchaseBillList.list && purchaseBillList.list.count) ? purchaseBillList.list.count : 0}
                                                    onPageChange={handlePageChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        authData: state.account,
        purchaseBillList: state.purchase
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchPurchaseBills: (query) => {
            dispatch( getPurchaseBills(query) )
        },
        updatePurchaseLock: (id) => {
            return dispatch( lockPurchase(id) )
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseListComponent)