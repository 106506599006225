import { TABLE_LOADER } from "./commonTypes";



const initialState = {
    tableLoader: true
}

const commonReducer = (state = initialState, action) => {
    switch(action.type) {

        case TABLE_LOADER:
            return {
                ...state,
                tableLoader: action.payload
            }

        default: return state;
    }
}

export default commonReducer;