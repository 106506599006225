import { 
    AUTH_REQUEST, 
    LOGIN_SUCCESS, 
    AUTH_LOGOUT,
    UPDATE_ACCESS_TOKEN,
    UPDATE_REFRESH_TOKEN
} from "./authTypes";
import { loginUserService, logoutUserService, removeUserService, updateTokenService } from "../../service/authService";

export const loginUser = (loginData) => {
    return (dispatch) => {
        dispatch(authRequest())

        return loginUserService(loginData).then( (data) => {
                dispatch(authSuccess({
                    id: data.id,
                    name: data.name,
                    role: data.role,
                    billDesk: data.billDesk
                }))
            return Promise.resolve();
        }).catch( (error) => {
            //console.log(error.response)
            return Promise.reject();
        })
    }
}

export const updateTokens = (tokenData) => {
    return (dispatch) => {
        updateTokenService(tokenData)
        //dispatch(accessToken(tokenData.accessToken))
        //dispatch(refreshToken(tokenData.refreshToken))
    }
}

export const removeUser = () => {
    return (dispatch) => {
        removeUserService()
        dispatch(authLogout())
    }
}

export const logoutUser = () => {
    return (dispatch) => {
        logoutUserService()
        dispatch(authLogout())
    }
}

export const authRequest = () => {
    return {
        type: AUTH_REQUEST
    }
}

export const authSuccess = (auth) => {
    return {
        type: LOGIN_SUCCESS,
        payload: auth
    }
}

export const accessToken = (accessToken) => {
    return {
        type: UPDATE_ACCESS_TOKEN,
        payload: accessToken
    }
}

export const refreshToken = (refreshToken) => {
    return {
        type: UPDATE_REFRESH_TOKEN,
        payload: refreshToken
    }
}

export const authLogout = () => {
    return {
        type: AUTH_LOGOUT
    }
}