import { CUSTOMER_LIST, CUSTOMER_DETAIL, CHANGE_CUSTOMER_DETAIL } from "./customerTypes"

const initialState = {
    detail: null,
    list: null,
    error: ""
}


const customerReducer = (state = initialState, action) => {
    switch(action.type) {
        
        case CHANGE_CUSTOMER_DETAIL:
            return {
                ...state,
                detail: action.payload
            }
        case CUSTOMER_LIST:
            return {
                ...state,
                list: action.payload,
            }
        case CUSTOMER_DETAIL:
            return {
                ...state,
                detail: action.payload,
            }
            
        default: return state;
    }
}

export default customerReducer;
