import React, { useEffect } from 'react'
import LoginComponent from './LoginComponent'
import Cashier from './include/Cashier'
import Return from './include/Return'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getBillDetail } from '../../redux';
import moment from 'moment';

import { openCashierPopup, openReturnPopup } from '../../redux'

function BillingDetailComponent({
    authData,
    fetchBill,
    bill,
    triggerCashierPopup,
    triggerReturnPopup
}) {

    const params = useParams();
    const navigate = useNavigate() 

    useEffect(() => {
        fetchBill(params.billNumber)

        if(!authData || !authData.auth || !authData.auth.id) {
            const goToLoginPage = () => navigate('/admin/login');
            goToLoginPage();
        }
    }, [navigate, fetchBill, authData, params]);

    const openCashier = () => {
        if(bill && bill.detail && bill.detail.customerId)
            triggerCashierPopup(bill.detail.customerId)
    }

    const openReturn = () => {
        if(bill && bill.detail && bill.detail.billNumber)
            triggerReturnPopup(bill.detail.billNumber)
    }

    if(!authData || !authData.auth || !authData.auth.id) {
        return <LoginComponent></LoginComponent>
    }

    if(!bill | !bill.detail) {
        return (
            <>
            <div>No bill data</div>
            </>
        )
    }

    return (
        <>
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mt-4">
                                <div className="card-body">
                                    <div className="clearfix">
                                        <div className="float-start">
                                            <div className="auth-logo">
                                                <a href="!#" className="logo logo-dark">
                                                    <span className="logo-lg">
                                                        <img alt="logo" src="/assets/images/logo-dark.png" />
                                                    </span>
                                                </a>
                                                <a href="!#" className="logo logo-light">
                                                    <span className="logo-lg">
                                                        <img alt="logo" src="/assets/images/logo-light.png" />
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="float-end">
                                            <h4 className="m-0">Invoice</h4>
                                        </div>
                                        {
                                            (bill && bill.detail && bill.detail.amountToPay && bill.detail.amountToPay > 0) ? <><button className="btn btn-lg font-16 btn-success float-end me-4 d-print-none" onClick={openReturn}>Update</button> <button className="btn btn-lg font-16 btn-success float-end me-4 d-print-none" onClick={openCashier}>Pay Now</button></> : <button type="button" className="btn btn-success float-end waves-effect waves-light me-4 d-print-none">
                                                <span className="btn-label"><i className="fas fa-dollar-sign"></i></span>Paid
                                            </button>
                                        }
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-sm-4">
                                            <h5>Shop</h5>
                                            <div>
                                                A2Z Street Sports & Fitness<br />
                                                Dennision Square<br />
                                                Paloor Junction, 629157<br />
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <h5>Billing Address</h5>
                                            <div>
                                                {bill.detail.customer.name}<br />
                                                {bill.detail.customer.phone}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <h5>Detail</h5>
                                            <div>
                                                <span className="float-start"><strong>Bill:&nbsp;</strong><b>{bill.detail.billNumber}</b></span><br />
                                                <span className="float-start"><strong>Bill Date: </strong> &nbsp;&nbsp;&nbsp;&nbsp; {moment(bill.detail.orderedAt).format('MMMM Do YYYY')}</span><br />
                                                <span className="float-start"><strong>Bill Status: </strong>
                                                {
                                                    (bill && bill.detail && bill.detail.amountToPay && bill.detail.amountToPay > 0) ? <span className="badge bg-danger">Unpaid</span> : <span className="badge bg-success">Paid</span>
                                                }
                                                </span><br />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12">
                                            <div className="table-responsive">
                                                <table className="table mt-4 table-centered">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Item</th>
                                                            <th>Unit Price</th>
                                                            <th>Qty</th>
                                                            <th className="text-end">Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            (bill.detail.order_items && bill.detail.order_items.length > 0) ? bill.detail.order_items.map( (item, id) => {
                                                                return <tr key={id}>
                                                                    <td>#{id+1}</td>
                                                                    <td>
                                                                        <b>{item.product.name}</b>
                                                                    </td>
                                                                    <td>Rs. {item.saleUnitPrice}</td>
                                                                    <td>{item.saleQty}</td>
                                                                    <td className="text-end">Rs. {item.salePrice}</td>
                                                                </tr>
                                                            }) : null
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="clearfix pt-5">

                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="float-end px-2">
                                                <p><b>Sub Total: </b> <span className="float-end">&nbsp;&nbsp;&nbsp;Rs. {bill.detail.subTotal}</span></p>
                                                <p><b>Discount: </b> <span className="float-end"> &nbsp;&nbsp;&nbsp;Rs. {bill.detail.discount}</span></p>
                                                <h3> Rs. {bill.detail.total}</h3>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Cashier />
        <Return />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        authData: state.account,
        bill: state.billing
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchBill: (billNumber) => {
            dispatch(getBillDetail(billNumber))
        },
        triggerCashierPopup: (customerId) => {
            dispatch(openCashierPopup(customerId))
        },
        triggerReturnPopup: (billNumber) => {
            dispatch(openReturnPopup(billNumber))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingDetailComponent)