import React, { useEffect, useState } from 'react'
import LoginComponent from './LoginComponent'
import Pagination from './include/Pagination';
import Loader from './include/Loader';
import moment from 'moment';
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    getSold
} from '../../redux'
import { findProduct } from '../../service/billingService';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { startOfDay, endOfDay } from 'date-fns';

function SoldItemListComponent({
        authData,
        soldItems,
        fetchSoldItems
    }) {
    const navigate = useNavigate() 

    //Product Ajax Select
    const[filter, setFilter] = useState({
        selectedOption: {},
        resultProduct: [],
        billFromDate: startOfDay(new Date()),
        billToDate: endOfDay(new Date())
    })
    const[page, setPage]    = useState({
        currentPage: 1,
        perPage: 20
    })

    const onSelectChange = (data) => {
        console.log(data)
        if (data !== null) {
            setFilter({...filter, selectedOption: {value: data.id, label: data.label}})
        } else {
            setFilter({...filter, selectedOption: {}}) 
        }
    }

    let typingTimer, doneTypingInterval = 800;
    const onSelectSearch = (input) => {
        if(input.length > 0) {
            clearTimeout(typingTimer);
            typingTimer = setTimeout(doneTyping, doneTypingInterval, input);
        }       
    }

    const doneTyping = (val) => {
        findProduct({q: val}).then(data => {
            let output = data.map( (data) => {
                return {
                    ...data,
                    value: data.id,
                    label: data.name
                }
            });
            setFilter({...filter, resultProduct: output})
        })
    }



    const doFilter = () => {
        const { resultProduct, ...filterData } = filter;
        fetchSoldItems({page: page.currentPage, limit: page.perPage, filter: filterData})
    }
    //End of Product Ajax Select


    // Pagination
    const handlePageChange = (pageNumber) => {
        setPage({
            ...page,
            currentPage: pageNumber
        });
        const { resultProduct, ...filterData } = filter;

        fetchSoldItems({page: pageNumber, limit: page.perPage, filter: filterData})
    }
    // End Pagination

    const [mount, setMount] = useState(false)
    useEffect(() => {
        if(!mount) {
            setMount(true);

            fetchSoldItems({page: 1, limit: page.perPage})
            if(!authData || !authData.auth || !authData.auth.id) {
                const goToLoginPage = () => navigate('/admin/login');
                goToLoginPage();
            }
        }

    }, [mount, navigate, authData, fetchSoldItems, page]);

    if(!authData || !authData.auth || !authData.auth.id) {
        return <LoginComponent></LoginComponent>
    }

    return (
        <>
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mt-4">
                                <div className="card-header">
                                    <ul className="nav nav-tabs align-items-end card-header-tabs w-100">
                                        <li className="nav-item">
                                            <a className="nav-link" href="/admin/billing"><i className="fas fa-plus-circle mx-2"></i>New Bill</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="/admin/bills"><i className="fas fa-th-list mx-2"></i>Bills</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link active" href="/admin/sold/items"><i className="fas fa-th-list mx-2"></i>Sold Items</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-body">

                                    <div className="row">
                                        <div className="mb-2 col-md-3">
                                            <label className="form-label">Product</label>
                                            <Select
                                                id="product-select"
                                                isSearchable
                                                isClearable={true}
                                                onChange={onSelectChange}
                                                onInputChange={onSelectSearch}
                                                options={filter.resultProduct}
                                                value={filter.selectedOption}
                                            />
                                        </div>
                                        <div className="mb-2 col-md-3">
                                            <label className="form-label">Bill From</label>
                                            <DatePicker 
                                                className="form-control" 
                                                selected={filter.billFromDate} 
                                                onChange={(date) => setFilter({...filter, billFromDate: startOfDay(date)})} 
                                            />
                                        </div>
                                        <div className="mb-2 col-md-3">
                                            <label className="form-label">Bill To</label>
                                            <DatePicker 
                                                className="form-control" 
                                                selected={filter.billToDate} 
                                                onChange={(date) => setFilter({...filter, billToDate: endOfDay(date)})} 
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label"></label>
                                            <button onClick={doFilter} type="button" className="btn btn-info width-md waves-effect waves-light">Filter</button>
                                        </div>
                                    </div>

                                    <table className="table dataTable no-footer">
                                        <thead>
                                            <tr role="row">
                                                <th>#</th>
                                                <th>Item</th> 
                                                <th>Unit</th> 
                                                <th>Unit Price</th>
                                                <th>Customer</th>
                                                <th>Bill Date</th>
                                                <th>Total</th> 
                                                <th>Bill Number</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            ( soldItems && soldItems.list && soldItems.list.rows && soldItems.list.rows.length > 0) ? soldItems.list.rows.map((data, id) => {
                                                return <tr key={id}>
                                                    <td>#{id+1}</td>
                                                    <td>{(data.product && data.product.name) ? data.product.name : '-'}</td>
                                                    <td>{data.saleQty}</td>
                                                    <td>{data.saleUnitPrice}</td>
                                                    <td>{(data.order && data.order.customer && data.order.customer.name) ? data.order.customer.name : '-'}</td>
                                                    <td>{(data.order && data.order.orderedAt) ? moment(data.order.orderedAt).format('MMMM Do YYYY') : '-'}</td>
                                                    <td>{data.salePrice}</td>
                                                    <td>{(data.order && data.order.billNumber) ? data.order.billNumber : '-'}</td>
                                                </tr>
                                            }) : <tr><td className="text-center" colSpan={8}>No Record</td></tr>
                                        }
                                        </tbody>
                                        <Loader colSpan={8}/>
                                    </table>

                                    <div className="row">
                                        <div className="col-sm-12 col-md-5">
                                            <div className="dataTables_info" role="status" aria-live="polite">Showing customers {page.currentPage*page.perPage - (page.perPage - 1)} to { (soldItems && soldItems.list && soldItems.list.count && soldItems.list.count < page.currentPage*page.perPage) ? soldItems.list.count : page.currentPage*page.perPage } of {soldItems && soldItems.list && soldItems.list.count ? soldItems.list.count : 0}</div>
                                        </div>
                                        <div className="col-sm-12 col-md-7">
                                            <div className="dataTables_paginate paging_simple_numbers float-end">
                                                <Pagination
                                                    current={page.currentPage}
                                                    perPageLimit={page.perPage}
                                                    totalRecords={(soldItems && soldItems.list && soldItems.list.count) ? soldItems.list.count : 0}
                                                    onPageChange={handlePageChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        authData: state.account,
        soldItems: state.soldItems
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchSoldItems: (query) => {
            dispatch( getSold(query) )
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SoldItemListComponent)