import "react-datepicker/dist/react-datepicker.css";
import React, { useState } from 'react'
import DatePicker from "react-datepicker";
import { Modal, Button } from 'react-bootstrap'
import { connect } from 'react-redux'
//import { useNavigate, useParams } from 'react-router-dom'
import { closeCashierPopup, updatePaidCheckbox, updatePaidByCheckbox, updatePaidBy, updateDescriptionBox, updateCheckbox, updatePaying, updateCustomerPay, createTransaction } from '../../../../redux'

const Cashier = ({
    cashier,
    triggerCloseCashierPopup,
    changeCheckbox,
    changePaidCheckbox,
    changePaidBy,
    changeDescriptionBox,
    changePaying,
    changeCustomerPay,
    createBillingTransaction
}) => {
    //const params = useParams();
    //const navigate = useNavigate() 

    const[details, setDetails] = useState({
        flash: 'none',
        message: null,
        paymentDate: new Date()
    })


    const closeModal = () => {
        triggerCloseCashierPopup()
    }
    const changeChecked = (e) => {
        const orderId = e.target.attributes.getNamedItem('data-pendingid').value
        changeCheckbox(orderId)
    }
    const changePaidChecked = (e) => {
        changePaidCheckbox(!cashier.paidCheck)
    }
    const changePaidByUpdated = (e) => {
        changePaidBy(e.target.value)
    }
    const changeDescription = (e) => {
        changeDescriptionBox(e.target.value)
    }
    
    const payingChange = (e) => {
        const orderId = e.target.attributes.getNamedItem('data-pendingid').value
        const paying = e.target.value
        changePaying(orderId, paying)
    }
    const customerPayment = (e) => {
        const paying = e.target.value
        changeCustomerPay(paying)
    }
    const updatePayment = (e) => {
        e.preventDefault()

        const customerPay           = cashier.customerPay
        const changePaid            = cashier.paidCheck
        const balancePaidAmount     = cashier.balanceToPay
        const paidBy                = cashier.paidBy
        const description           = cashier.description

        const payTotal = cashier.pendingList.reduce((currentBal, b) => {
            return ((currentBal*1) + (b.paying*1))
        }, 0)

        if(customerPay < payTotal) {
            alert("Amount received should not be less than PAYING NOW")
            return
        }

        let customer_wallet = cashier.customer.point/100;
        if(paidBy == 'WALLET' && customerPay > customer_wallet) {
            alert("Not enough wallet cash!")
            return
        }

        const pendingBill = cashier.pendingList.map(b => {
            return {
                id: b.id,
                paying: b.paying
            }
        })

        setDetails({
            ...details,
            flash: 'none',
            message: null
        })

        createBillingTransaction({
            changePaid: changePaid,
            paidBy: paidBy,
            balancePaidAmount: balancePaidAmount,
            pendingBills: pendingBill,
            payTotal: payTotal,
            customerPaying: customerPay,
            customerId: cashier.customerId,
            paymentDate: details.paymentDate,
            description: description
        }).then(data => {
            setDetails({
                ...details,
                flash: 'success',
                message: data.message
            })

            window.location.reload(false);
            // const goToBillDetails = () => navigate('/admin/bill/'+redirectId);
            // goToBillDetails();
        }).catch(error => {
            let errorMessage = '';
            if(error && error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
                errorMessage += error.response.data.errors+' '
            }
            if(error && error.response && error.response.data && error.response.data.message && error.response.data.message !== '') {
                errorMessage += error.response.data.message
            }

            setDetails({
                ...details,
                flash: 'error',
                message: errorMessage
            })
        })

        
    }


    return (
        <>
        <Modal dialogClassName="modal-xl" aria-labelledby="fullScreenModalLabel" show={cashier.isOpen} onHide={closeModal}>
            <form className="form-horizontal" onSubmit={updatePayment}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Pending Bills : {cashier.customer ? cashier.customer.name : ''}
                        <div>Wallet Balance: Rs.{cashier.customer && cashier.customer.point > 0 ? (cashier.customer.point/100) : 0}</div>
                    </Modal.Title>
                    
                    <div className="mx-4">
                        {
                            (details.flash === 'error') ? <div className="alert alert-primary alert-dismissible bg-transparent text-primary fade show mb-0" role="alert">
                                {details.message}
                            </div> : null
                        }
                                                {
                            (details.flash === 'success') ? <div className="alert alert-success alert-dismissible bg-transparent text-success fade show mb-0" role="alert">
                                {details.message}
                            </div> : null
                        }
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {
                        (cashier.loading === true) ? <div className="cashier-loader">
                            <div className="spinner-grow avatar-lg text-secondary m-2"></div>
                        </div> : ''
                    }

                    <table className="table mb-0">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Bill</th>
                                <th>Bill Total</th>
                                <th>Pending</th>
                                <th>PAYING NOW</th>
                                <th>Balance after pay</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            (cashier && cashier.pendingList && cashier.pendingList.length > 0) ? cashier.pendingList.map((bill, key) => {
                            return <tr key={key}>
                                <td>
                                    <a target="_blank" href={'/admin/bill/'+bill.billNumber}><i className="fa fa-link"></i></a>
                                </td>
                                <td>
                                    <div className="checkbox-wrapper-mail">
                                        <div className="form-check font-16 mb-0">
                                            <input className="form-check-input dt-checkboxes" type="checkbox" id={'key'+key} checked={bill.checked} onChange={changeChecked} data-pendingid={bill.id}/>
                                            <label className="no-select bill-select form-check-label" htmlFor={'key'+key}>{bill.billNumber}</label>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="font-16 mb-0">
                                        <span>Rs.{bill.total}</span>
                                    </div>
                                </td>
                                <td>
                                    <div className="font-16 mb-0">
                                        <span>Rs.{bill.amountToPay}</span>
                                    </div>
                                </td>
                                <td>
                                    <div className="font-16 mb-0">
                                        <input type="text" className="form-control" value={bill.paying} onChange={payingChange} data-pendingid={bill.id}/>
                                    </div>
                                </td>
                                <td>
                                    Rs. {
                                        ((bill.amountToPay*1) - (bill.paying*1)).toFixed(2)
                                    }
                                </td>
                            </tr>
                        }) : null
                        }
                        <tr>
                            <td colSpan="4"></td>
                            <td>
                                <h2 className="card-pricing-price">
                                Rs. {
                                    (cashier && cashier.pendingList && cashier.pendingList.length > 0) ? cashier.pendingList.reduce((currentBal, bill) => {
                                        return ((currentBal*1) +  (bill.paying*1)).toFixed(2)
                                    }, 0.00) : 0.00
                                }
                                </h2>
                            </td>
                            <td>
                                <h2 className="card-pricing-price">
                                Rs. {
                                    (cashier && cashier.pendingList && cashier.pendingList.length > 0) ? cashier.pendingList.reduce((currentBal, bill) => {
                                        return ((currentBal*1) + ((bill.amountToPay*1) - (bill.paying*1))).toFixed(2)
                                    }, 0.00) : 0.00
                                }
                                </h2>
                            </td>
                        </tr>
                        <tr style={{borderTop:'2px solid #ccc'}}>
                            <td colSpan="3">
                                <DatePicker className="form-control" selected={details.paymentDate} onChange={(date) => setDetails({...details, paymentDate: date})} />
                            </td>
                            <td>Amount Received</td>
                            <td>
                                <input type="text" className="form-control" onChange={customerPayment} value={cashier.customerPay}/>
                            </td>
                            <td>
                                <div className="checkbox-wrapper-mail">
                                    <div className="font-16 mb-0">
                                        <select className="form-control" value={cashier.paidBy} onChange={changePaidByUpdated}>
                                            <option>CASH</option>
                                            <option>BANK</option>
                                            <option>ADJ</option>
                                            <option>WALLET</option>
                                        </select>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="3">
                                <textarea className="form-control" placeholder="Notes if any.." value={cashier.description} onChange={changeDescription}/>
                            </td>
                            <td>To Customer</td>
                            <td>
                                Rs. {cashier.balanceToPay}
                            </td>
                            <td>
                                <div className="checkbox-wrapper-mail">
                                    <div className="form-check font-16 mb-0">
                                        <input className="form-check-input dt-checkboxes" type="checkbox" id="balancePaid" checked={cashier.paidCheck} onChange={changePaidChecked}/>
                                        <label className="no-select bill-select form-check-label" htmlFor="balancePaid">Change Paid</label>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                </Modal.Body>
                <Modal.Footer>
                <Button type="submit" className="btn btn-success">
                    Update Payment
                </Button>
                <Button variant="secondary" onClick={closeModal}>
                    Close
                </Button>
                </Modal.Footer>
            </form>
        </Modal>
        </>   
    )
}
const mapStateToProps = (state) => {
    return {
        cashier: state.cashier
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        triggerCloseCashierPopup: () => {
            dispatch(closeCashierPopup())
        },
        changeCheckbox: (billId) => {
            dispatch(updateCheckbox(billId))
        },
        changePaidCheckbox: (checked) => {
            dispatch(updatePaidCheckbox(checked))
        },
        changePaidByCheckbox: (checked) => {
            dispatch(updatePaidByCheckbox(checked))
        },
        changePaidBy:  (option) => {
            dispatch(updatePaidBy(option))
        },
        changeDescriptionBox: (value) => {
            dispatch(updateDescriptionBox(value))
        },
        changePaying: (billId, paying) => {
            dispatch(updatePaying(billId, paying))
        },
        changeCustomerPay: (paying) => {
            dispatch(updateCustomerPay(paying))
        },
        createBillingTransaction: (data) => {
            return dispatch(createTransaction(data))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Cashier)