import { BILLING_PRODUCT, UPDATE_QTY, UPDATE_DISCOUNT, REMOVE_BILLING_PRODUCT, UPDATE_BILLING_STOCK, BILLING_CUSTOMER, BILL_LIST, BILL_DETAIL, CLEAR_BILLER } from "./billingTypes";

function nextId(products) {
    const maxId = products.reduce((maxId, products) => Math.max(products.idKey, maxId), -1)
    return maxId + 1
}

const billing = JSON.parse(localStorage.getItem("billing"));
const initialState = billing
  ? {   
        products: billing.products.map((data) => { return data }),
        discount: billing.discount, 
        billingStock: billing.billingStock,
        customer: billing.customer,
        list: billing.list,
        detail: billing.detail,
        error: ""
    } : { 
        products: [], 
        discount: 0,
        billingStock: [],
        customer: null,
        list: null,
        detail: null,
        error: "" 
    };

const billingReducer = (state = initialState, action) => {
    switch(action.type) {
        case BILLING_PRODUCT:
            return {
                ...state,
                products: [
                    ...state.products,
                    {
                        ...action.payload,
                        idKey: nextId(state.products),
                        qty: 1
                    }
                ],
            }

        case UPDATE_QTY:
            return {
                ...state,
                products: action.payload
            }

        case UPDATE_DISCOUNT:
            return {
                ...state,
                discount: action.payload
            } 

        case REMOVE_BILLING_PRODUCT: 
            return {
                ...state,
                products: state.products.filter(item => parseInt(item.idKey) !== parseInt(action.payload))
            }

        case UPDATE_BILLING_STOCK: 
            return {
                ...state,
                billingStock: action.payload
            }

        case BILLING_CUSTOMER: 
            return {
                ...state,
                customer: action.payload
            }

        case BILL_LIST: 
            return {
                ...state,
                list: action.payload
            }

        case BILL_DETAIL: 
            return {
                ...state,
                detail: action.payload
            }

        case CLEAR_BILLER: 
            return {
                ...state,
                products: [], 
                discount: 0,
                billingStock: [],
                customer: null,
                list: null,
                error: "" 
            }

        default: return state;
    }
}

export default billingReducer;



