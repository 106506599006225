import api from "../network/api" 
import TokenService from "./tokenService";


export const loginUserService = (loginData) => {
    return api.post("/auth/login", {
        email: loginData.email,
        password: loginData.password
    }).then(response => {
        if (response && 
            response.data && 
            response.data.data && 
            response.data.data.accessToken &&
            response.data.data.refreshToken &&
            response.data.data.id) {

            TokenService.setUser({
                id: response.data.data.id,
                name: response.data.data.name,
                role: response.data.data.role,
                billDesk: response.data.data.billDesk,
                accessToken: response.data.data.accessToken,
                refreshToken: response.data.data.refreshToken
            });

            return response.data.data;
        }
    })
}

export const updateTokenService = (tokens) => {
    TokenService.updateTokenService(tokens);
}

export const logoutUserService = () => {
    //Make logout api call here
    TokenService.removeUser();
}

export const removeUserService = () => {
    TokenService.removeUser();
}