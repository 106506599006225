import React from "react";
import { useNavigate } from "react-router-dom";
import { connect } from 'react-redux'
import { logoutUser } from '../../../redux'

function HeaderComponent({isLoggedin, authData, logout}) {

    const navigate = useNavigate() 
	function triggerLogout(e) {
		e.preventDefault()
		logout();
		navigate("/admin/login");
	}

    function preventClick(e) {
        e.preventDefault()
    }

    let componentContent = ''
    if(isLoggedin) {
        componentContent = <div className="navbar-custom">
            <div className="container-fluid">
                <ul className="list-unstyled topnav-menu float-end mb-0">
                    <li className="dropdown d-none d-lg-inline-block">
                        <a className="nav-link dropdown-toggle arrow-none waves-effect waves-light" data-bs-toggle="fullscreen" href="!#">
                            <i className="fe-maximize noti-icon"></i>
                        </a>
                    </li>
                    <li className="dropdown notification-list topbar-dropdown">
                        <a className="nav-link dropdown-toggle waves-effect waves-light" data-bs-toggle="dropdown" href="!#" role="button" aria-haspopup="false" aria-expanded="false">
                            <i className="fe-bell noti-icon"></i>
                            <span className="badge bg-danger rounded-circle noti-icon-badge">5</span>
                        </a>
                        <div className="dropdown-menu dropdown-menu-end dropdown-lg">
                            <div className="dropdown-item noti-title">
                                <h5 className="m-0">
                                    <span className="float-end">
                                        <a href="!#" className="text-dark">
                                            <small>Clear All</small>
                                        </a>
                                    </span>Notification
                                </h5>
                            </div>
                            <div className="noti-scroll" data-simplebar>
                                <a onClick={preventClick} href="!#" className="dropdown-item notify-item active">
                                    <div className="notify-icon bg-soft-primary text-primary">
                                        <i className="mdi mdi-comment-account-outline"></i>
                                    </div>
                                    <p className="notify-details">Doug Dukes commented on Admin Dashboard
                                        <small className="text-muted">1 min ago</small>
                                    </p>
                                </a>
                            </div>
                            <a href="!#" onClick={preventClick} className="dropdown-item text-center text-primary notify-item notify-all">
                                View all
                                <i className="fe-arrow-right"></i>
                            </a>
                        </div>
                    </li>
                    <li className="dropdown notification-list topbar-dropdown">
                        <a className="nav-link dropdown-toggle nav-user me-0 waves-effect waves-light" data-bs-toggle="dropdown" href="!#" role="button" aria-haspopup="false" aria-expanded="false">
                            <img src="/assets/images/users/avatar-1.jpg" alt="" className="rounded-circle"/>
                            <span className="pro-user-name ms-1">
                                {authData.auth.name} <i className="mdi mdi-chevron-down"></i> 
                            </span>
                        </a>
                        <div className="dropdown-menu dropdown-menu-end profile-dropdown ">
                            <div className="dropdown-header noti-title">
                                <h6 className="text-overflow m-0">Welcome {authData.auth.name}!</h6>
                            </div>
                            <div className="dropdown-divider"></div>

                            <a onClick={triggerLogout} href="!#" className="dropdown-item notify-item">
                                <i className="ri-logout-box-line"></i>
                                <span>Logout</span>
                            </a>
                        </div>
                    </li>
                </ul>
                <div className="logo-box">
                    <a href="!#" className="logo logo-dark text-center">
                        <span className="logo-sm">
                            <img src="../assets/images/logo-sm-dark.png"  alt="" height="24"/>
                        </span>
                        <span className="logo-lg">
                            <img src="../assets/images/logo-dark.png"  alt="" height="20"/>
                        </span>
                    </a>
                    <a href="!#" className="logo logo-light text-center">
                        <span className="logo-sm">
                            <img src="../assets/images/logo-sm.png"  alt="" height="24"/>
                        </span>
                        <span className="logo-lg">
                            <img src="../assets/images/logo-light.png"  alt="" height="20"/>
                        </span>
                    </a>
                </div>
                <ul className="list-unstyled topnav-menu topnav-menu-left m-0">
                    <li>
                        <button className="button-menu-mobile waves-effect waves-light">
                            <i className="fe-menu"></i>
                        </button>
                    </li>
                    <li>
                        <a href="!#" className="navbar-toggle nav-link" data-bs-toggle="collapse" data-bs-target="#topnav-menu-content">
                            <div className="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </a>
                    </li>  
                    <li className="dropdown d-none d-xl-block mt-2">
                        <a href="/admin/billing" className="btn btn-success width-md waves-effect waves-light new-customer-btn">
                            <span className="btn-label"><i className="fas fa-money-bill"></i></span>
                            New Bill
                        </a>
                    </li>
                </ul>
                <div className="clearfix"></div>
            </div>
        </div>
    }

    return componentContent;
}


const mapStateToProps = (state) => {
    return {
        authData: state.account
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        logout : () => {
			dispatch(logoutUser())
		} 
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent)