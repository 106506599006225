import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import App from './App';
import Admin from './Admin';


import LoginComponent from './component/admin/LoginComponent';
import DashboardComponent from './component/admin/DashboardComponent';
import BillingComponent from './component/admin/BillingComponent';
import BillingListComponent from './component/admin/BillingListComponent';
import BillingDetailComponent from './component/admin/BillingDetailComponent';
import PurchaseListComponent from './component/admin/PurchaseListComponent';
import CreatePurchaseComponent from './component/admin/CreatePurchaseComponent';
import TagListComponent from './component/admin/TagListComponent';
import CreateTagComponent from './component/admin/CreateTagComponent';
import PurchaseDetailComponent from './component/admin/PurchaseDetailComponent';
import CustomerTransactionComponent from './component/admin/CustomerTransactionComponent';
import CustomerStatementComponent from './component/admin/CustomerStatementComponent';
import AdminUserComponent from './component/admin/AdminUserComponent';
import CustomerComponent from './component/admin/CustomerComponent';
import SoldItemListComponent from './component/admin/SoldItemListComponent';
import ReportComponent from './component/admin/ReportComponent';
import HomeComponent from './component/HomeComponent';


const Main = () => {

    return (
        <Provider store={store}>
            <Router>
                <Routes>
                    <Route path="/admin" element={<Admin/>}>
                        <Route path="login" element={<LoginComponent/>}/>
                        <Route path="dashboard" element={<DashboardComponent/>}/>
                        <Route path="billing" element={<BillingComponent/>}/>
                        <Route path="bills" element={<BillingListComponent/>}/>
                        <Route path="bill/:billNumber" element={<BillingDetailComponent/>}/>
                        <Route path="purchase" element={<PurchaseListComponent/>}/>
                        <Route path="purchase/:billId" element={<PurchaseDetailComponent/>}/>
                        <Route path="purchase/create" element={<CreatePurchaseComponent/>}/>
                        <Route path="tags" element={<TagListComponent/>}/>
                        <Route path="tags/create" element={<CreateTagComponent/>}/>
                        <Route path="transactions" element={<CustomerTransactionComponent/>}/>
                        <Route path="user" element={<AdminUserComponent/>}/>
                        <Route path="customers" element={<CustomerComponent/>}/>
                        <Route path="customers/:customerId/statement" element={<CustomerStatementComponent/>}/>
                        <Route path="sold/items" element={<SoldItemListComponent/>}/>
                        <Route path="sold/report" element={<ReportComponent/>}/>
                    </Route>

                    <Route path="/" element={<App/>}>
                        <Route path="" element={<HomeComponent/>}/>
                    </Route>

                </Routes>
            </Router>
        </Provider>
    )
}

ReactDOM.render(
    <Main />,
    document.getElementById("root")
);