import { 
    ADD_PAGINATE_PRODUCTS,
    ADD_CURRENT_PRODUCT, 
    UPDATE_CURRENT_PRODUCT, 
    ADD_CURRENT_PRODUCT_MEDIA,
    CLEAR_CURRENT_PRODUCT,
    FETCH_PRODUCT,
    ADD_TO_PRODUCTS
} from "./productTypes";

const initialState = {
    products: [],
    currentProduct: {
        id: 0,
        name: "",
        hsn: "",
        barcode: "",
        localBarcode: "",
        gstPercentage: 0.00,
        description: "",
        sellingPrice: 0.00,
        msp: 0.00,
        mrp: 0.00,
        media: [],
        tags: [],
        ecom1_low: null,
        ecom2_low: null,
        ecom3_low: null,
        ecom4_low: null,
        ecom5_low: null
    }, 
    error: "" 
}

const productReducer = (state = initialState, action) => {
    switch(action.type) {
        
        case FETCH_PRODUCT:
            return {
                ...state,
                products: action.payload,
            }

        case ADD_PAGINATE_PRODUCTS: {

            let newArr;
            if (Array.isArray(action.payload)) {
                newArr = [...state.products, ...action.payload];
            } else {
                newArr = [...state.products, action.payload];
            }

            return {
                ...state,
                products: newArr
            }
        }

        case ADD_TO_PRODUCTS: {
            
            let found = false
            let newArr = state.products.map(p => {
                if(p.id === action.payload.id) {
                    found = true
                    return action.payload
                } else {
                    return p
                }
            })

            if(!found) {
                newArr.unshift(action.payload)
            }

            return {
                ...state,
                products: newArr
            }
        }
     

        case ADD_CURRENT_PRODUCT:
            return {
                ...state,
                currentProduct: action.payload,
            }
    
        case UPDATE_CURRENT_PRODUCT:
            return {
                ...state,
                currentProduct: action.payload,
            }

        case ADD_CURRENT_PRODUCT_MEDIA:
            return {
                ...state,
                currentProduct: {
                    ...state.currentProduct,
                    media: action.payload
                },
            }

        case CLEAR_CURRENT_PRODUCT:
            return {
                ...state,
                currentProduct: {
                    id: 0,
                    name: "",
                    hsn: "",
                    barcode: "",
                    localBarcode: "",
                    gstPercentage: 0.00,
                    description: "",
                    sellingPrice: 0.00,
                    msp: 0.00,
                    mrp: 0.00,
                    media: [],
                    tags: [],
                    ecom1_low: null,
                    ecom2_low: null,
                    ecom3_low: null,
                    ecom4_low: null,
                    ecom5_low: null
                },
            }

        default: return state;
    }
}

export default productReducer;
