import { 
    FETCH_DASHBOARD
} from "./dashboardTypes";
import { fetchSaleTotal } from "../../service/dashboardService";

export const getDashboardData = (query) => {
    return (dispatch) => {
        return fetchSaleTotal(query).then( (responseData) => {
            dispatch(fetchDashboard(responseData.data))
        }).catch( (error) => {
            console.log('Products fetching error')
        })
    }
}


export const fetchDashboard = (data) => {
    return {
        type: FETCH_DASHBOARD,
        payload: data
    }
}